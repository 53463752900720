import * as React from 'react';
function SvgTasksLg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M17.697 2.977H8.305c-3.273 0-5.325 2.317-5.325 5.596v8.849c0 3.279 2.043 5.596 5.325 5.596h9.39c3.284 0 5.327-2.317 5.327-5.596V8.573c0-3.28-2.043-5.596-5.325-5.596z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.145 13l2.571 2.571 5.142-5.141"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgTasksLg;
