import { useEffect, useCallback } from 'react';
import {
  $createParagraphNode,
  $createRangeSelection,
  $insertNodes,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_EDITOR,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
  $getNodeByKey,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils';
import { AttachmentKind } from 'core/graphql/interfaces/graphql.interfaces';
import {
  ICancelAttachmentUploadInput,
  IUploadAttachmentInput,
  useCancelAttachmentUploadCommand,
  useUploadFileAttachmentCommand,
} from 'core/commands/attachment';
import { MultipartUploader, SinglepartUploader, UploadStage } from 'core/uploader';
import { fileReaderWorker } from 'core/workers';
import { useFilesStore } from 'core/store/files';
import { loadImage } from 'shared/helpers/files';

import { $createImageNode, ImageNode, IImagePayload, $isImageNode } from '../../nodes/image-node';

import {
  $getImageSizes,
  canDropImage,
  getDragSelection,
  getDragImageData,
  $getImageNodeInSelection,
  $getImageNodeByAttachmentId,
} from './helpers';
import {
  IUploadImagePayload,
  IRemoveImagePayload,
  INSERT_IMAGE_COMMAND,
  REMOVE_IMAGE_COMMAND,
  UPLOAD_IMAGE_COMMAND,
} from './commands';
import { IImagesPluginProps, IFileWorkerMessage } from './interface';

const transportImg = document.createElement('img');

transportImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const ImagesPlugin = (props: IImagesPluginProps) => {
  const { refId, refType } = props;

  const [editor] = useLexicalComposerContext();

  const uploadFileAttachment = useUploadFileAttachmentCommand();
  const cancelAttachmentUpload = useCancelAttachmentUploadCommand();

  const _setImageNodeSizes = useCallback(
    async (event: MessageEvent<IFileWorkerMessage>) => {
      const imageURL = event.data.data;

      if (!imageURL) {
        return;
      }

      const image = await loadImage(imageURL);

      editor.update(() => {
        const imageNode = $getImageNodeByAttachmentId(event.data.id, editor);

        if (imageNode) {
          const { width, height } = $getImageSizes(image, editor);

          imageNode.setWidthAndHeight(width, height);
        }
      });
    },
    [editor]
  );

  const _uploaderCompleteListener = useCallback(
    (uploader: SinglepartUploader | MultipartUploader) => {
      const attachmentId = uploader.mutationData.id;
      const attachmentUrl = uploader.fileURL;

      editor.update(() => {
        const node = $getImageNodeByAttachmentId(attachmentId, editor);

        if (!(node && attachmentUrl)) {
          node?.remove();

          return;
        }

        node.setSrc(attachmentUrl);
      });
    },
    [editor]
  );

  const _uploaderStageChangeListener = useCallback(
    (_, uploader: SinglepartUploader | MultipartUploader) => {
      if (uploader.stage !== UploadStage.UPLOAD_CREATED) {
        return;
      }

      const attachmentId = uploader.mutationData.id;
      const attachmentUrl = uploader.fileURL;

      editor.update(() => {
        const node = $getImageNodeByAttachmentId(attachmentId, editor);

        if (!(node && attachmentUrl)) {
          return;
        }

        node.setSrc(attachmentUrl);
      });
    },
    [editor]
  );

  const _insertImageNode = useCallback(
    (payload: IImagePayload) => {
      const node = $createImageNode(payload);

      editor.update(() => {
        $insertNodes([node]);

        if ($isRootOrShadowRoot(node.getParentOrThrow())) {
          $wrapNodeInElement(node, $createParagraphNode).selectEnd();
        }
      });

      return node;
    },
    [editor]
  );

  const _dropCommand = useCallback(
    (event: DragEvent) => {
      const node = $getImageNodeInSelection();

      if (!node) {
        return false;
      }

      const data = getDragImageData(event);

      if (!data) {
        return false;
      }

      event.preventDefault();

      if (canDropImage(event)) {
        const range = getDragSelection(event);
        node.remove();
        const rangeSelection = $createRangeSelection();

        if (range !== null && range !== undefined) {
          rangeSelection.applyDOMRange(range);
        }

        $setSelection(rangeSelection);

        editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
      }

      return true;
    },
    [editor]
  );

  const _dragOverCommand = useCallback((event: DragEvent) => {
    const node = $getImageNodeInSelection();

    if (!node) {
      return false;
    }

    if (!canDropImage(event)) {
      event.preventDefault();
    }

    return true;
  }, []);

  const _dragStartCommand = useCallback((event: DragEvent) => {
    const node = $getImageNodeInSelection();

    if (!node) {
      return false;
    }

    const dataTransfer = event.dataTransfer;

    if (!dataTransfer) {
      return false;
    }

    dataTransfer.setData('text/plain', '_');
    dataTransfer.setData(
      'application/x-lexical-drag',
      JSON.stringify({
        type: 'image',
        data: {
          src: node.getSrc(),
          key: node.getKey(),
          width: node.__width,
          height: node.__height,
          altText: node.getAltText(),
          attachmentId: node.getAttachmentId(),
        },
      })
    );

    dataTransfer.setDragImage(transportImg, 0, 0);

    return true;
  }, []);

  const _insertImageCommand = useCallback((payload: IImagePayload) => {
    _insertImageNode(payload);

    return true;
  }, []);

  const _removeImageCommand = useCallback(
    (payload: IRemoveImagePayload) => {
      const node = $getNodeByKey(payload.nodeKey);

      if (!$isImageNode(node)) {
        return false;
      }

      const filesState = useFilesStore.getState();
      const attachmentId = node.getAttachmentId();

      if (attachmentId && attachmentId in filesState) {
        const input: ICancelAttachmentUploadInput = {
          id: attachmentId,
        };

        cancelAttachmentUpload({ input });
      }

      node.remove();

      return false;
    },
    [cancelAttachmentUpload]
  );

  const _uploadImageCommand = useCallback(
    (payload: IUploadImagePayload) => {
      const { file } = payload;

      const input: IUploadAttachmentInput = {
        refId,
        source: file,
        refType,
        filename: file.name,
        contentType: file.type,
        attachmentKind: AttachmentKind.TextAttachment,
      };

      const uploader = uploadFileAttachment({ input, removeAfterComplete: true });

      const attachmentId = uploader.mutationData.id;

      _insertImageNode({
        src: '',
        altText: file.name,
        attachmentId,
      });

      uploader.addEventListener('complete', _uploaderCompleteListener);
      uploader.addEventListener('stageChange', _uploaderStageChangeListener);

      fileReaderWorker?.postMessage({ id: attachmentId, file });

      return true;
    },
    [refId, refType, _insertImageNode, _uploaderCompleteListener, _uploaderStageChangeListener]
  );

  useEffect(() => {
    fileReaderWorker?.addEventListener('message', _setImageNodeSizes);

    return () => {
      fileReaderWorker?.removeEventListener('message', _setImageNodeSizes);
    };
  }, [_setImageNodeSizes]);

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    const unregister = mergeRegister(
      editor.registerCommand(DROP_COMMAND, _dropCommand, COMMAND_PRIORITY_HIGH),
      editor.registerCommand(DRAGOVER_COMMAND, _dragOverCommand, COMMAND_PRIORITY_LOW),
      editor.registerCommand(DRAGSTART_COMMAND, _dragStartCommand, COMMAND_PRIORITY_HIGH),
      editor.registerCommand(INSERT_IMAGE_COMMAND, _insertImageCommand, COMMAND_PRIORITY_EDITOR),
      editor.registerCommand(UPLOAD_IMAGE_COMMAND, _uploadImageCommand, COMMAND_PRIORITY_EDITOR),
      editor.registerCommand(REMOVE_IMAGE_COMMAND, _removeImageCommand, COMMAND_PRIORITY_EDITOR)
    );

    return unregister;
  }, [
    editor,
    _dropCommand,
    _dragOverCommand,
    _dragStartCommand,
    _insertImageCommand,
    _uploadImageCommand,
    _removeImageCommand,
  ]);

  return null;
};
