import { UploadStatus } from 'core/uploader';

import { useFilesStore } from '../stores';

import { IAddFileDataActionPayload, IUpdateFileDataActionPayload } from './interfaces';

export const addFileData = (payload: IAddFileDataActionPayload) => {
  const { fileId, data } = payload;

  useFilesStore.setState({
    [fileId]: {
      ...data,
      sent: data.sent ?? 0,
      total: data.uploader.totalSize,
      status: data.status ?? UploadStatus.PENDING,
      progress: data.progress ?? 0,
    },
  });
};

export const updateFileData = (payload: IUpdateFileDataActionPayload) =>
  useFilesStore.setState((state) => {
    const newState = { ...state };

    newState[payload.fileId] = {
      ...newState[payload.fileId],
      ...payload.data,
    };

    return newState;
  });

export const removeFileData = (payload: string) => {
  useFilesStore.setState((state) => {
    if (payload in state) {
      const newState = { ...state };

      delete newState[payload];

      return newState;
    }

    return state;
  }, true);
};
