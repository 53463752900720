export const formatFileSize = (size: number) => {
  const suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let i = 0;

  while (size >= 1024 && i < suffixes.length - 1) {
    size /= 1024;
    i++;
  }

  return `${size === 0 ? 0 : size.toFixed(1)}${suffixes[i]}`;
};
