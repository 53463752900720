import React, { useRef, useEffect } from 'react';
import {
  NodeKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  DRAGSTART_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_BACKSPACE_COMMAND,
  COMMAND_PRIORITY_LOW,
  BLUR_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import styled from 'styled-components';
import { selectFileData, useFilesStore } from 'core/store/files';

import { useEditorFocus } from '../../hooks';
import { REMOVE_IMAGE_COMMAND } from '../../plugins/images-plugin';

import { ImageView } from './image-view';
import { ImagePlaceholder } from './image-placeholder';

const Container = styled.div`
  display: flex;
  position: relative;
`;

interface IProps {
  src: string;
  width: 'inherit' | number;
  height: 'inherit' | number;
  nodeKey: NodeKey;
  altText: string;
  attachmentId?: string;
}

export const ImageComponent = (props: IProps) => {
  const { nodeKey, attachmentId } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);

  const fileData = useFilesStore(selectFileData(attachmentId));

  const hasFocus = useEditorFocus();

  const isLoading = !!fileData;

  const _handleCancel = () => {
    editor.dispatchCommand(REMOVE_IMAGE_COMMAND, { nodeKey });
  };

  const _handleContentClick = () => {
    setSelected(hasFocus && !isSelected);
  };

  useEffect(() => {
    const _blurCommand = () => {
      clearSelection();

      return false;
    };

    const _clickCommand = (event: MouseEvent) => {
      const isCurrentNode = Boolean(event.target instanceof Node && containerRef.current?.contains(event.target));

      return isCurrentNode;
    };

    const _deleteCommand = (event: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        event.preventDefault();

        editor.dispatchCommand(REMOVE_IMAGE_COMMAND, { nodeKey });
      }

      return false;
    };

    const _dragStartCommand = (event: DragEvent) => {
      const isCurrentNode = Boolean(event.target instanceof Node && containerRef.current?.contains(event.target));

      if (isCurrentNode) {
        // TODO This is just a temporary workaround for FF to behave like other browsers.
        // Ideally, this handles drag & drop too (and all browsers).
        event.preventDefault();

        return true;
      }

      return false;
    };

    const unregister = mergeRegister(
      editor.registerCommand(BLUR_COMMAND, _blurCommand, COMMAND_PRIORITY_LOW),
      editor.registerCommand(CLICK_COMMAND, _clickCommand, COMMAND_PRIORITY_LOW),
      editor.registerCommand(DRAGSTART_COMMAND, _dragStartCommand, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_DELETE_COMMAND, _deleteCommand, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, _deleteCommand, COMMAND_PRIORITY_LOW)
    );

    return unregister;
  }, [editor, nodeKey, isSelected, clearSelection]);

  return (
    <Container ref={containerRef} role="presentation" draggable={isSelected} onClick={_handleContentClick}>
      {isLoading ? (
        <ImagePlaceholder fileData={fileData} onCancel={_handleCancel} />
      ) : (
        <ImageView editor={editor} isSelected={isSelected} {...props} />
      )}
    </Container>
  );
};
