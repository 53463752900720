import React, { useRef, RefObject } from 'react';
import type { LexicalEditor } from 'lexical';
import styled from 'styled-components';
import { getBlue300Color } from 'core/theme/helpers';

const Side = {
  east: 1 << 0,
  north: 1 << 3,
  south: 1 << 1,
  west: 1 << 2,
};

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

const ControlWrapper = styled.div`
  .wrapper--resizing {
    touch-action: none;
  }
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background-color: ${getBlue300Color};

  &::before {
    content: '';
    width: 40%;
    height: 40%;
    border-radius: inherit;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &.side-n {
    top: -5px;
    left: 50%;
    cursor: n-resize;
  }

  &.side-ne {
    top: -5px;
    right: -5px;
    cursor: ne-resize;
  }

  &.side-e {
    right: -5px;
    bottom: 50%;
    cursor: e-resize;
  }

  &.side-se {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
  }

  &.side-s {
    left: 50%;
    bottom: -5px;
    cursor: s-resize;
  }

  &.side-sw {
    left: -5px;
    bottom: -5px;
    cursor: sw-resize;
  }

  &.side-w {
    left: -5px;
    bottom: 50%;
    cursor: w-resize;
  }

  &.side-nw {
    top: -5px;
    left: -5px;
    cursor: nw-resize;
  }
`;

interface IPositioning {
  currentHeight: 'inherit' | number;
  currentWidth: 'inherit' | number;
  direction: number;
  isResizing: boolean;
  ratio: number;
  startHeight: number;
  startWidth: number;
  startX: number;
  startY: number;
}

interface IProps {
  editor: LexicalEditor;
  itemRef: RefObject<HTMLElement>;
  onResizeEnd: (width: 'inherit' | number, height: 'inherit' | number) => void;
  onResizeStart: () => void;
}

export const Resizer = (props: IProps) => {
  const { editor, itemRef, onResizeEnd, onResizeStart } = props;

  const userSelect = useRef({
    value: 'default',
    priority: '',
  });

  const controlWrapperRef = useRef<HTMLDivElement>(null);

  const positioningRef = useRef<IPositioning>({
    currentHeight: 0,
    currentWidth: 0,
    direction: 0,
    isResizing: false,
    ratio: 0,
    startHeight: 0,
    startWidth: 0,
    startX: 0,
    startY: 0,
  });

  const editorRootElement = editor.getRootElement();
  // Find max width, accounting for editor padding.
  const maxWidthContainer = editorRootElement !== null ? editorRootElement.getBoundingClientRect().width : 500;

  const maxHeightContainer = editorRootElement !== null ? editorRootElement.getBoundingClientRect().height : 100;

  const minWidth = 100;
  const minHeight = 100;

  const setStartCursor = (direction: number) => {
    const ew = direction === Side.east || direction === Side.west;
    const ns = direction === Side.north || direction === Side.south;
    const nwse = (direction & Side.north && direction & Side.west) || (direction & Side.south && direction & Side.east);

    const cursorDir = ew ? 'ew' : ns ? 'ns' : nwse ? 'nwse' : 'nesw';

    if (editorRootElement !== null) {
      editorRootElement.style.setProperty('cursor', `${cursorDir}-resize`, 'important');
    }
    if (document.body !== null) {
      document.body.style.setProperty('cursor', `${cursorDir}-resize`, 'important');
      userSelect.current.value = document.body.style.getPropertyValue('-webkit-user-select');
      userSelect.current.priority = document.body.style.getPropertyPriority('-webkit-user-select');
      document.body.style.setProperty('-webkit-user-select', `none`, 'important');
    }
  };

  const setEndCursor = () => {
    if (editorRootElement !== null) {
      editorRootElement.style.setProperty('cursor', 'text');
    }
    if (document.body !== null) {
      document.body.style.setProperty('cursor', 'default');
      document.body.style.setProperty('-webkit-user-select', userSelect.current.value, userSelect.current.priority);
    }
  };

  const handlePointerDown = (direction: number) => (event: React.PointerEvent<HTMLDivElement>) => {
    if (!editor.isEditable()) {
      return;
    }

    const element = itemRef.current;
    const controlWrapper = controlWrapperRef.current;

    if (element !== null && controlWrapper !== null) {
      event.preventDefault();

      const { width, height } = element.getBoundingClientRect();
      const positioning = positioningRef.current;

      positioning.startWidth = width;
      positioning.startHeight = height;
      positioning.ratio = width / height;
      positioning.currentWidth = width;
      positioning.currentHeight = height;
      positioning.startX = event.clientX;
      positioning.startY = event.clientY;
      positioning.isResizing = true;
      positioning.direction = direction;

      setStartCursor(direction);
      onResizeStart();

      controlWrapper.classList.add('wrapper--resizing');
      element.style.height = `${height}px`;
      element.style.width = `${width}px`;

      document.addEventListener('pointermove', handlePointerMove);
      document.addEventListener('pointerup', handlePointerUp);
    }
  };

  const handlePointerMove = (event: PointerEvent) => {
    const element = itemRef.current;
    const positioning = positioningRef.current;

    const isHorizontal = positioning.direction & (Side.east | Side.west);
    const isVertical = positioning.direction & (Side.south | Side.north);

    if (element !== null && positioning.isResizing) {
      // Corner cursor
      if (isHorizontal && isVertical) {
        let diff = Math.floor(positioning.startX - event.clientX);
        diff = positioning.direction & Side.east ? -diff : diff;

        const width = clamp(positioning.startWidth + diff, minWidth, maxWidthContainer);

        const height = width / positioning.ratio;
        element.style.width = `${width}px`;
        element.style.height = `${height}px`;
        positioning.currentHeight = height;
        positioning.currentWidth = width;
      } else if (isVertical) {
        let diff = Math.floor(positioning.startY - event.clientY);
        diff = positioning.direction & Side.south ? -diff : diff;

        const height = clamp(positioning.startHeight + diff, minHeight, maxHeightContainer);

        element.style.height = `${height}px`;
        positioning.currentHeight = height;
      } else {
        let diff = Math.floor(positioning.startX - event.clientX);
        diff = positioning.direction & Side.east ? -diff : diff;

        const width = clamp(positioning.startWidth + diff, minWidth, maxWidthContainer);

        element.style.width = `${width}px`;
        positioning.currentWidth = width;
      }
    }
  };

  const handlePointerUp = () => {
    const element = itemRef.current;
    const positioning = positioningRef.current;
    const controlWrapper = controlWrapperRef.current;

    if (element !== null && controlWrapper !== null && positioning.isResizing) {
      const width = positioning.currentWidth;
      const height = positioning.currentHeight;
      positioning.startWidth = 0;
      positioning.startHeight = 0;
      positioning.ratio = 0;
      positioning.startX = 0;
      positioning.startY = 0;
      positioning.currentWidth = 0;
      positioning.currentHeight = 0;
      positioning.isResizing = false;

      controlWrapper.classList.remove('wrapper--resizing');

      setEndCursor();
      onResizeEnd(width, height);

      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
    }
  };

  return (
    <ControlWrapper ref={controlWrapperRef}>
      <Point className="side-n" onPointerDown={handlePointerDown(Side.north)} />
      <Point className="side-ne" onPointerDown={handlePointerDown(Side.north | Side.east)} />
      <Point className="side-e" onPointerDown={handlePointerDown(Side.east)} />
      <Point className="side-se" onPointerDown={handlePointerDown(Side.south | Side.east)} />
      <Point className="side-s" onPointerDown={handlePointerDown(Side.south)} />
      <Point className="side-sw" onPointerDown={handlePointerDown(Side.south | Side.west)} />
      <Point className="side-w" onPointerDown={handlePointerDown(Side.west)} />
      <Point className="side-nw" onPointerDown={handlePointerDown(Side.north | Side.west)} />
    </ControlWrapper>
  );
};
