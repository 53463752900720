import styled from 'styled-components';

import { IStyledButtonProps } from '../../model';
import { commonButtonStyles, getButtonVariantStyles, getButtonBorderRadiusStyles } from '../styled';

export const StyledButton = styled.button<IStyledButtonProps>`
  ${commonButtonStyles}
  ${getButtonBorderRadiusStyles}
  ${getButtonVariantStyles}
`;
