import { isMacOs } from 'react-device-detect';
import { InitialConfigType } from '@lexical/react/LexicalComposer';
import { IColorOption } from 'features/color-dropdown';

export const THEME_CONFIG: InitialConfigType['theme'] = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  quote: 'editor-quote',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
    listitem: 'editor-listitem',
    listitemChecked: 'editor-listitem-checked',
    listitemUnchecked: 'editor-listitem-unchecked',
  },
  link: 'editor-link',
  image: 'editor-image',
  text: {
    code: 'editor-text-code',
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    underline: 'editor-text-underline',
    strikethrough: 'editor-text-strikethrough',
    underlineStrikethrough: 'editor-text-underlineStrikethrough',
  },
  code: 'editor-code',
  codeHighlight: {
    atrule: 'editor-tokenAttr',
    attr: 'editor-tokenAttr',
    boolean: 'editor-tokenProperty',
    builtin: 'editor-tokenSelector',
    cdata: 'editor-tokenComment',
    char: 'editor-tokenSelector',
    class: 'editor-tokenFunction',
    'class-name': 'editor-tokenFunction',
    comment: 'editor-tokenComment',
    constant: 'editor-tokenProperty',
    deleted: 'editor-tokenProperty',
    doctype: 'editor-tokenComment',
    entity: 'editor-tokenOperator',
    function: 'editor-tokenFunction',
    important: 'editor-tokenVariable',
    inserted: 'editor-tokenSelector',
    keyword: 'editor-tokenAttr',
    namespace: 'editor-tokenVariable',
    number: 'editor-tokenProperty',
    operator: 'editor-tokenOperator',
    prolog: 'editor-tokenComment',
    property: 'editor-tokenProperty',
    punctuation: 'editor-tokenPunctuation',
    regex: 'editor-tokenVariable',
    selector: 'editor-tokenSelector',
    string: 'editor-tokenSelector',
    symbol: 'editor-tokenProperty',
    tag: 'editor-tokenProperty',
    url: 'editor-tokenOperator',
    variable: 'editor-tokenVariable',
  },
  mention: 'editor-mention',
};

export const P_BLOCK_TYPE = 'paragraph';
export const H1_BLOCK_TYPE = 'h1';
export const H2_BLOCK_TYPE = 'h2';
export const CODE_BLOCK_TYPE = 'code';
export const QUOTE_BLOCK_TYPE = 'quote';
export const IMAGE_BLOCK_TYPE = 'image';
export const MENTION_BLOCK_TYPE = 'mention';
export const CHECK_LIST_BLOCK_TYPE = 'check';
export const NUMBER_LIST_BLOCK_TYPE = 'number';
export const BULLET_LIST_BLOCK_TYPE = 'bullet';

export const SUPPORTED_BLOCK_TYPES = new Set([
  P_BLOCK_TYPE,
  H1_BLOCK_TYPE,
  H2_BLOCK_TYPE,
  CODE_BLOCK_TYPE,
  QUOTE_BLOCK_TYPE,
  CHECK_LIST_BLOCK_TYPE,
  NUMBER_LIST_BLOCK_TYPE,
  BULLET_LIST_BLOCK_TYPE,
]);

export const SUPPORTED_URL_PROTOCOLS = new Set(['http:', 'https:', 'mailto:', 'sms:', 'tel:']);

export const TEXT_STYLE_OPTIONS = [
  {
    label: 'Normal text',
    value: P_BLOCK_TYPE,
  },
  {
    label: 'Heading 1',
    value: H1_BLOCK_TYPE,
  },
  {
    label: 'Heading 2',
    value: H2_BLOCK_TYPE,
  },
  {
    label: 'Quote',
    value: QUOTE_BLOCK_TYPE,
  },
];

export const TEXT_COLOR_OPTIONS: IColorOption[] = [
  {
    value: 'rgb(34, 34, 34)',
    color: 'rgb(34, 34, 34)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(2, 70, 139)',
    color: 'rgb(2, 70, 139)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(62, 1, 125)',
    color: 'rgb(62, 1, 125)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(146, 1, 1)',
    color: 'rgb(146, 1, 1)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(12, 111, 0)',
    color: 'rgb(12, 111, 0)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(137, 87, 0)',
    color: 'rgb(137, 87, 0)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(68, 68, 68)',
    color: 'rgb(68, 68, 68)',
    iconColor: '#FFFFFF',
  },

  {
    value: 'rgb(0, 148, 255)',
    color: 'rgb(0, 148, 255)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(140, 28, 255)',
    color: 'rgb(140, 28, 255)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(255, 3, 3)',
    color: 'rgb(255, 3, 3)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(22, 205, 0)',
    color: 'rgb(22, 205, 0)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(255, 168, 15)',
    color: 'rgb(255, 168, 15)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(140, 147, 159)',
    color: 'rgb(140, 147, 159)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(145, 210, 255)',
    color: 'rgb(145, 210, 255)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(211, 168, 255)',
    color: 'rgb(211, 168, 255)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(255, 141, 141)',
    color: 'rgb(255, 141, 141)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(160, 239, 150)',
    color: 'rgb(160, 239, 150)',
    iconColor: '#FFFFFF',
  },
  {
    value: 'rgb(245, 217, 168)',
    color: 'rgb(245, 217, 168)',
    iconColor: '#FFFFFF',
  },
];

export const COMMAND_KEY = isMacOs ? '\u2318' : 'Ctrl';

export const EMPTY_HTML_STRING = '<p class="editor-paragraph"><br></p>';

export const EDITOR_URL_REGEX =
  /((https?:\/\/(www\.)?)|(www\.))?(?!www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9()[\]@:%_+.~#?!&/=]*)/;

export const MENTION_MARKDOWN_REGEX = /([@])\[([^\]]+)\]\((\{[^)]+\})\)/;
