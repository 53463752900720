import * as React from 'react';
function SvgSwitchOffSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9 8V1" stroke="#8C939F" strokeWidth={1.2} strokeLinecap="round" />
      <path
        d="M14.233 3.767a7.4 7.4 0 11-10.466 0"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgSwitchOffSm;
