import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { EAppearanceType } from 'providers/notification';
import { getPrimaryShadow, getWhiteColor } from 'core/theme/helpers';
import { Typography, Button } from 'shared/components';
import { CrossLg } from 'shared/components/icons';

const Container = styled(motion.div)<IStyledWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding: 13px 24px;
  min-height: 72px;
  min-width: 312px;
  max-width: 600px;
  box-sizing: border-box;
  background: ${getWhiteColor};
  box-shadow: ${getPrimaryShadow};
  border-radius: 4px 8px 8px 4px;

  &::before {
    content: '';
    position: absolute;
    inset: 0 auto 0 0;
    width: 6px;
    background: ${({ theme, $appearance }) =>
      $appearance === EAppearanceType.success ? theme.colors.primary_green : theme.colors.red400};
    border-radius: 4px 0 0 4px;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  padding: 0;
  top: -11px;
  right: -11px;
  min-height: auto;
  border-radius: 50%;
`;

interface IStyledWrapperProps {
  $appearance: EAppearanceType;
}

interface IProps {
  title: string;
  content?: React.ReactNode;
  appearance: any;
  onDismiss: () => void;
}

export const Notification = (props: IProps) => {
  const { title, content, appearance, onDismiss } = props;

  return (
    <Container
      exit="hidden"
      layout="position"
      initial="hidden"
      animate="visible"
      variants={{
        // TODO: x prop doesn't work
        hidden: { marginLeft: '-100%' },
        visible: { marginLeft: 0 },
      }}
      transition={{ duration: 0.5 }}
      $appearance={appearance}
    >
      <Typography component="h1" fontWeight="medium" data-testid="notification-title">
        {title}
      </Typography>

      {content && (
        <Typography color="secondary" component="p">
          {content}
        </Typography>
      )}

      <CloseButton
        variant="primary-gray"
        leftIcon={<CrossLg width={22} height={22} />}
        iconColor="inherit"
        onClick={onDismiss}
      />
    </Container>
  );
};
