import * as React from 'react';
function SvgEditorMarkList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={4.744} cy={4.709} r={1.323} fill="#8C939F" />
      <circle cx={4.744} cy={9.999} fill="#8C939F" r={1.323} />
      <circle cx={4.744} cy={15.291} fill="#8C939F" r={1.323} />
      <path
        d="M8.712 4.71h8.598m-8.598 5.292h8.598m-8.598 5.29h8.598"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
      />
    </svg>
  );
}
export default SvgEditorMarkList;
