import { IEventMetadata } from '../interfaces';

import { useObservable } from './use-observable.hook';

export const useEventEmitter = () => {
  const observable = useObservable();

  return <T>(value: T, metadata: IEventMetadata) => {
    observable.next({
      value,
      __metadata: metadata,
    });
  };
};
