import { DateTime } from 'luxon';

export const dateToZeroUTC = (date: DateTime | string): DateTime => {
  const preparedDate = DateTime.isDateTime(date) ? date : DateTime.fromISO(date);

  return DateTime.fromObject(
    {
      day: preparedDate.get('day'),
      year: preparedDate.get('year'),
      month: preparedDate.get('month'),
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    },
    { zone: 'utc' }
  );
};

export const dateJSToZeroUTC = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
};

export const dateTimeToLocalWithSameDay = (date: DateTime) => {
  if (!date || date.invalidReason) return;

  const local = DateTime.fromObject({
    year: date.get('year'),
    month: date.get('month'),
    day: date.get('day'),
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return local;
};
