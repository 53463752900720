import React, { useRef, useState } from 'react';
import { LexicalEditor, NodeKey, $getNodeByKey } from 'lexical';
import styled from 'styled-components';
import { Tooltip, Button } from 'shared/components';
import { DeleteSm } from 'shared/components/icons';

import { $isImageNode } from '../../../nodes/image-node';
import { REMOVE_IMAGE_COMMAND } from '../../../plugins/images-plugin';
import { Resizer } from '../../resizer';

const ButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const DeleteButton = styled(Button)`
  padding: 4px;
  min-height: auto;
`;

interface IProps {
  src: string;
  width: 'inherit' | number;
  height: 'inherit' | number;
  editor: LexicalEditor;
  nodeKey: NodeKey;
  altText: string;
  isSelected: boolean;
}

export const ImageView = (props: IProps) => {
  const { src, width, height, editor, altText, nodeKey, isSelected } = props;

  const imageRef = useRef<HTMLImageElement | null>(null);

  const [isResizing, setIsResizing] = useState(false);

  const isEditMode = isSelected || isResizing;

  const _handleDelete = () => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);

      node?.selectPrevious();
    });
    //TODO: focus is not set without delay
    setTimeout(() => {
      editor.dispatchCommand(REMOVE_IMAGE_COMMAND, { nodeKey });
    });
  };

  const _handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const _handleResizeStart = () => {
    setIsResizing(true);
  };

  const _handleResizeEnd = (nextWidth: 'inherit' | number, nextHeight: 'inherit' | number) => {
    setIsResizing(false);

    editor.update(() => {
      const node = $getNodeByKey(nodeKey);

      if ($isImageNode(node)) {
        node.setWidthAndHeight(nextWidth, nextHeight);
      }
    });
  };

  return (
    <>
      <img
        ref={imageRef}
        src={src}
        alt={altText}
        style={{ width, height }}
        className={isEditMode ? 'focused draggable' : ''}
      />

      {isEditMode && (
        <>
          <ButtonContainer>
            <Tooltip content="Delete image">
              <DeleteButton
                variant="primary-gray"
                leftIcon={<DeleteSm width={20} height={20} />}
                iconColor="inherit"
                onClick={_handleDelete}
                onMouseDown={_handleMouseDown}
              />
            </Tooltip>
          </ButtonContainer>

          <Resizer
            editor={editor}
            itemRef={imageRef}
            onResizeEnd={_handleResizeEnd}
            onResizeStart={_handleResizeStart}
          />
        </>
      )}
    </>
  );
};
