import * as React from 'react';
function SvgDuplicateSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.333 10.75v3.5c0 2.916-1.166 4.083-4.083 4.083h-3.5c-2.917 0-4.083-1.167-4.083-4.084v-3.5c0-2.916 1.166-4.083 4.083-4.083h3.5c2.917 0 4.083 1.167 4.083 4.083z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.333 5.75v3.5c0 2.916-1.166 4.083-4.083 4.083h-.917v-2.584c0-2.916-1.166-4.083-4.083-4.083H6.667v-.917c0-2.916 1.166-4.083 4.083-4.083h3.5c2.917 0 4.083 1.167 4.083 4.083z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgDuplicateSm;
