import * as React from 'react';
function SvgCheckWithoutBorder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 9.832 7.382" {...props}>
      <path
        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 966"
        d="M3.592 7.382a.613.613 0 01-.447-.2L.166 4.017a.614.614 0 11.895-.84l2.525 2.691L8.741.229a.613.613 0 11.907.821l-5.6 6.13a.613.613 0 01-.447.2z"
        fill="#0094ff"
      />
    </svg>
  );
}
export default SvgCheckWithoutBorder;
