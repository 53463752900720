import * as React from 'react';
function SvgUserInviteSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.875 14.625h-3M12.375 16.125v-3M9.122 8.152a1.363 1.363 0 00-.248 0A3.315 3.315 0 015.672 4.83 3.32 3.32 0 018.994 1.5a3.332 3.332 0 013.33 3.33 3.32 3.32 0 01-3.202 3.322zM8.994 16.355c-1.365 0-2.723-.345-3.758-1.035-1.815-1.215-1.815-3.195 0-4.402 2.063-1.38 5.445-1.38 7.508 0"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgUserInviteSm;
