import { css } from 'styled-components';
import {
  getBlue300Color,
  getLGFontSize,
  getGray800Color,
  getBlackColor,
  getXLFontSize,
  getWhiteColor,
  getPrimaryShadow,
  getXSBorderRadius,
} from 'core/theme/helpers';

export const editorStyles = css`
  & .ltr {
    text-align: left;
  }

  & .rtl {
    text-align: right;
  }

  & .editor-text-bold {
    font-weight: bold;
  }

  & .editor-text-italic {
    font-style: italic;
  }

  & .editor-text-underline {
    text-decoration: underline;
  }

  & .editor-text-strikethrough {
    text-decoration: line-through;
  }

  & .editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
  }

  & .editor-text-code {
    padding: 1px 0& 0.25rem;
    font-size: 94%;
    background-color: rgb(240, 242, 245);
  }

  & .editor-link {
    color: ${getBlue300Color};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & .editor-code {
    display: block;
    padding: 8px 8px 8px 52px;
    font-size: 12px;
    line-height: 1.5;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
    background-color: rgb(240, 242, 245);

    &::after {
      content: attr(data-highlight-language);
      position: absolute;
      top: 0;
      color: rgba(0, 0, 0, 0& 0.5);
      right: 3px;
      padding: 3px;
      font-size: 10px;
      text-transform: uppercase;
    }

    &::before {
      content: attr(data-gutter);
      position: absolute;
      top: 0;
      left: 0;
      color: #777;
      padding: 8px;
      min-width: 25px;
      text-align: right;
      white-space: pre-wrap;
      border-right: 1px solid #ccc;
      background-color: #eee;
    }
  }

  & .editor-tokenComment {
    color: slategray;
  }

  & .editor-tokenPunctuation {
    color: ${getGray800Color};
  }

  & .editor-tokenProperty {
    color: #905;
  }

  & .editor-tokenSelector {
    color: #690;
  }

  & .editor-tokenOperator {
    color: #9a6e3a;
  }

  & .editor-tokenAttr {
    color: #07a;
  }

  & .editor-tokenVariable {
    color: #e90;
  }

  & .editor-tokenFunction {
    color: #dd4a68;
  }

  & .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
  }

  & .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  & .editor-heading-h1 {
    color: ${getBlackColor};
    font-size: ${getXLFontSize};
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  & .editor-heading-h2 {
    color: ${getBlackColor};
    font-size: ${getLGFontSize};
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  & .editor-quote {
    color: rgb(101, 103, 107);
    margin: 0;
    margin-left: 20px;
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }

  & .editor-list-ol {
    margin: 0;
    padding: 0;
    margin-left: 16px;
  }

  & .editor-list-ul {
    margin: 0;
    padding: 0;
    margin-left: 16px;
  }

  & .editor-listitem {
    margin: 8px 32px 8px 32px;
  }

  & .editor-listitem-checked {
    & > span {
      text-decoration: line-through;
      text-decoration-color: currentColor;
    }
  }

  & .editor-listitem-checked,
  & .editor-listitem-unchecked {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 24px;
    padding-right: 24px;
    list-style-type: none;
    outline: none;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      cursor: pointer;
      display: block;
      position: absolute;
      border: 1px solid ${getBlue300Color};
      border-radius: 4px;
      background-size: cover;
    }

    &[dir='rtl']::before {
      left: auto;
      right: 0;
    }

    &:focus::before {
      box-shadow: 0 0 0 2px #a6cdfe;
    }
  }

  & .editor-listitem-checked::before {
    background-color: ${getBlue300Color};
    background-repeat: no-repeat;
  }

  & .editor-listitem-checked::after {
    content: '';
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    display: block;
    top: 4px;
    width: 3px;
    left: 7px;
    right: 7px;
    height: 6px;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
  }

  & .editor-nested-listitem {
    list-style-type: none;
  }

  & span.editor-image {
    display: inline-block;
    cursor: pointer;
    position: relative;
    user-select: none;
  }

  & .editor-image img {
    max-width: 100%;
  }

  & .editor-image img.focused {
    outline: 1px solid ${getGray800Color};
    user-select: none;
  }

  & .editor-image img.focused.draggable {
    cursor: grab;
  }

  & .editor-image img.focused.draggable:active {
    cursor: grabbing;
  }

  & .editor-mention {
    color: ${getBlue300Color};
  }

  & .editor-typeahead-popover {
    position: absolute;
    top: calc(-100% - 8px);
    z-index: 8;
    transform: translateY(-100%);
    background: ${getWhiteColor};
    box-shadow: ${getPrimaryShadow};
    border-radius: ${getXSBorderRadius};
  }
`;
