import React, { useMemo } from 'react';

import { SwitcherContext } from './context';

interface IProps<T> {
  name: string;
  value: T;
  children: React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: T) => void;
}

export const SwitcherProvider = <T,>(props: IProps<T>) => {
  const { name, value, children, onChange } = props;

  const values = useMemo(
    () => ({
      name,
      value,
      onChange,
    }),
    [name, value, onChange]
  );

  return <SwitcherContext.Provider value={values}>{children}</SwitcherContext.Provider>;
};
