import * as React from 'react';
function SvgCardSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.5 6.379h15M4.5 12.379H6M7.875 12.379h3"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.83 2.629h8.332c2.67 0 3.338.66 3.338 3.292v6.158c0 2.632-.668 3.292-3.33 3.292H4.83c-2.663.008-3.33-.652-3.33-3.285V5.921c0-2.632.667-3.292 3.33-3.292z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgCardSm;
