import * as React from 'react';
function SvgFolderColoredSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.5 8.25v4.5c0 3-.75 3.75-3.75 3.75h-7.5c-3 0-3.75-.75-3.75-3.75v-7.5c0-3 .75-3.75 3.75-3.75h1.125c1.125 0 1.372.33 1.8.9L9.3 3.9c.285.375.45.6 1.2.6h2.25c3 0 3.75.75 3.75 3.75z"
        fill="#0094FF"
      />
    </svg>
  );
}
export default SvgFolderColoredSm;
