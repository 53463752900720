import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';

import { DIALOG_PADDING_VALUE } from '../model';

export const DialogSeparator = styled.hr`
  margin: 30px -${DIALOG_PADDING_VALUE}px;
  border: none;
  border-bottom: 1px solid ${getGray400Color};
`;
