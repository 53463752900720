import * as React from 'react';
function SvgFileXls(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_xls_svg__clip0_4576_26645)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#50AC25"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.329 2.329 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#418123"
        />
        <path
          d="M9.87 24.525l1.667 2.785 1.687-2.785h1.526L12.26 28.3l2.567 3.874h-1.504l-1.758-2.87-1.758 2.87H8.295l2.56-3.874-2.482-3.775H9.87zM17.116 21.315v10.86H15.81v-10.86h1.307zM23.202 30.145c0-.188-.042-.363-.126-.523-.08-.165-.246-.313-.5-.445-.248-.137-.623-.255-1.124-.354a8.877 8.877 0 01-1.147-.318 3.47 3.47 0 01-.864-.445 1.865 1.865 0 01-.542-.615 1.722 1.722 0 01-.19-.828c0-.301.066-.586.197-.855.136-.268.326-.506.57-.714.248-.207.546-.37.893-.488a3.593 3.593 0 011.16-.176c.61 0 1.13.108 1.56.325.432.217.763.506.992.87.23.357.345.756.345 1.194h-1.3c0-.212-.064-.417-.19-.615a1.404 1.404 0 00-.542-.502c-.235-.132-.523-.198-.865-.198-.361 0-.654.057-.879.17a1.13 1.13 0 00-.485.417 1.055 1.055 0 00-.077.919c.051.108.14.21.267.304.126.09.304.174.534.254.23.08.523.16.879.24.623.142 1.137.312 1.54.51s.703.44.9.728c.197.287.295.636.295 1.046a2.035 2.035 0 01-.809 1.64 2.963 2.963 0 01-.927.467 4.126 4.126 0 01-1.203.163c-.67 0-1.237-.12-1.701-.361-.465-.24-.816-.551-1.055-.933a2.241 2.241 0 01-.359-1.21h1.308c.019.36.122.644.31.856.187.208.417.356.689.446.271.084.54.127.808.127.356 0 .654-.047.893-.141.244-.095.429-.224.556-.39a.904.904 0 00.19-.565z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_xls_svg__clip0_4576_26645">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileXls;
