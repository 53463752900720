import * as React from 'react';
function SvgClipLg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.234 14.877l-5.558 5.557C10.182 25.928.99 16.736 6.484 11.242l6.102-6.103c4.073-4.073 10.468 2.322 6.395 6.395l-5.714 5.714c-2.036 2.037-5.434-1.36-3.397-3.397l5.169-5.17"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}
export default SvgClipLg;
