import * as React from 'react';
function SvgTagSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.696 2.223h-7.4c-1.635 0-2.963 1.353-2.963 3V16.18c0 1.4.99 1.99 2.203 1.314l3.746-2.107c.4-.225 1.044-.225 1.436 0l3.746 2.107c1.212.684 2.203.094 2.203-1.314V5.224c-.008-1.648-1.336-3.001-2.971-3.001z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgTagSm;
