import * as React from 'react';
function SvgStarSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.442 2.926l1.466 2.934c.2.408.734.8 1.184.875l2.658.441c1.7.284 2.1 1.517.875 2.734l-2.066 2.066c-.35.35-.542 1.025-.434 1.508l.592 2.559c.466 2.025-.609 2.808-2.4 1.75l-2.492-1.475c-.45-.267-1.191-.267-1.65 0l-2.492 1.475c-1.783 1.058-2.866.267-2.4-1.75l.592-2.558c.109-.484-.083-1.159-.433-1.509L2.375 9.91C1.16 8.693 1.55 7.46 3.25 7.176l2.659-.441c.441-.075.974-.467 1.175-.875L8.55 2.926c.8-1.591 2.1-1.591 2.892 0z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgStarSm;
