export const trackCapterraEvent = () => {
  const capterra_vkey = 'd0dd7bb679d0e103477272dddbb02f63';
  const capterra_vid = '2304613';

  const ct = document.createElement('img');

  ct.src = 'https://ct.capterra.com/capterra_tracker.gif?vid=' + capterra_vid + '&vkey=' + capterra_vkey;

  document.body.appendChild(ct);
};
