import { DeleteAttachmentInput } from '../../../graphql/interfaces/graphql.interfaces';
import { IDeletedAttachment } from '../../../graphql/subscriptions/attachment';
import { getAttachmentFragment } from '../../../graphql/fragments/getters/attachment';
import { IDeleteAttachmentInput } from '../interfaces';

export const transformDeleteAttachmentInput = (input: IDeleteAttachmentInput) => {
  const attachmentFragment = getAttachmentFragment(input.id, true);

  let deletingData: IDeletedAttachment | null = null;

  if (attachmentFragment) {
    deletingData = {
      ...attachmentFragment,
    };
  }

  const mutationInput: DeleteAttachmentInput = {
    id: input.id,
  };

  return { deletingData, mutationInput };
};
