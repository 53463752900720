import styled from 'styled-components';

export const Dimmer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(9, 24, 51, 0.5);
`;
