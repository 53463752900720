import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react/headless';
import { getBlue700Color, getWhiteColor, getXSBorderRadius, getXXSFontSize } from 'core/theme/helpers';

import { TooltipPosition, ITooltipProps, TooltipContainerWidthType } from '../model';

const Arrow = styled.div`
  width: 8px;
  height: 8px;
  visibility: hidden;
  background-color: inherit;
`;

const TooltipContainer = styled.div`
  color: ${getWhiteColor};
  width: fit-content;
  z-index: 999;
  padding: 10px;
  max-width: 210px;
  font-size: ${getXXSFontSize};
  background: ${getBlue700Color};
  text-align: center;
  border-radius: ${getXSBorderRadius};

  &[data-placement^='top'] > ${Arrow} {
    bottom: -4px;
    visibility: visible;
    clip-path: polygon(50% 100%, 0 50%, 100% 50%);
  }

  &[data-placement^='bottom'] > ${Arrow} {
    top: -4px;
    visibility: visible;
    clip-path: polygon(50% 0, 0 50%, 100% 50%);
  }

  &[data-placement^='left'] > ${Arrow} {
    right: -4px;
    visibility: visible;
    clip-path: polygon(50% 0, 100% 50%, 52% 100%);
  }

  &[data-placement^='right'] > ${Arrow} {
    left: -4px;
    visibility: visible;
    clip-path: polygon(50% 0, 0 50%, 50% 100%);
  }
`;

const Content = styled.div<IContentProps>`
  display: grid;
  width: ${({ $containerWidth }) => ($containerWidth === 'full' ? '100%' : 'max-content')};
`;

interface IContentProps {
  $containerWidth: TooltipContainerWidthType;
}

// This implementation uses Tippy
// If you need to add some additional behavior options the docs might help -
// https://github.com/atomiks/tippyjs-react

export const Tooltip = (props: ITooltipProps) => {
  const {
    width,
    delay = 300,
    content,
    children,
    position = TooltipPosition.TOP,
    className,
    containerWidth = 'content',
    ...otherProps
  } = props;

  return (
    <Tippy
      {...otherProps}
      arrow
      delay={delay}
      placement={position}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      }}
      render={(attrs) => (
        <TooltipContainer style={{ width }} className={className} {...attrs}>
          {content}
          <Arrow data-popper-arrow="" />
        </TooltipContainer>
      )}
    >
      <Content className="tooltip-content-container" $containerWidth={containerWidth}>
        {children}
      </Content>
    </Tippy>
  );
};
