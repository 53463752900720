import * as React from 'react';
function SvgBellSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.015 2.184a4.504 4.504 0 00-4.5 4.5V8.85c0 .458-.195 1.155-.428 1.545l-.862 1.433c-.533.885-.165 1.867.81 2.197a15.69 15.69 0 009.952 0 1.502 1.502 0 00.81-2.197l-.862-1.433c-.225-.39-.42-1.087-.42-1.545V6.684c0-2.475-2.025-4.5-4.5-4.5z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M10.402 2.398a5.065 5.065 0 00-2.775 0 1.489 1.489 0 011.388-.945c.63 0 1.17.39 1.387.945z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.265 14.297a2.257 2.257 0 01-2.25 2.25 2.258 2.258 0 01-1.59-.66 2.258 2.258 0 01-.66-1.59"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgBellSm;
