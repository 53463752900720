import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { ThemeProvider, GlobalStyle } from 'core/theme';
import { apolloClient } from 'core/apollo';
import { AnalyticsModule } from 'core/analytics';

import { AppRouting } from './app-routing';

export const App = () => (
  //@ts-ignore
  <Sentry.ErrorBoundary>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
      <BrowserRouter>
        <AnalyticsModule />

        <ApolloProvider client={apolloClient}>
          <ThemeProvider>
            <GlobalStyle />

            <AppRouting />
          </ThemeProvider>
        </ApolloProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </Sentry.ErrorBoundary>
);
