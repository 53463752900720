import { DateTime } from 'luxon';

export const saveFile = async (url: string, suggestedName: string) => {
  const fileName = suggestedName || `file-${DateTime.now().toISO()}`;

  const result = await fetch(url, { cache: 'no-cache' });

  const fileBlob = await result.blob();

  const link = document.createElement('a');

  link.href = URL.createObjectURL(fileBlob);
  link.download = fileName;

  link.click();

  URL.revokeObjectURL(link.href);
};
