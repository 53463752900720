import { useCallback } from 'react';
import { useEventEmitter, EventType } from 'providers/event-subscription';
import config from 'shared/config/config';

import { useFilesStore, selectFileData, removeFileData } from '../../../store/files';
import { releaseTransaction } from '../../../graphql/transaction';
import { IDeletedAttachment } from '../../../graphql/subscriptions/attachment';
import { ICancelAttachmentUploadInput } from '../interfaces';
import { transformDeleteAttachmentInput } from '../transformers';

interface IUseCancelAttachmentUpload {
  input: ICancelAttachmentUploadInput;
}

export const useCancelAttachmentUploadCommand = () => {
  const emitEvent = useEventEmitter();

  const cancelAttachmentUploadCommand = useCallback((params: IUseCancelAttachmentUpload) => {
    const { input } = params;

    const fileData = selectFileData(input.id)(useFilesStore.getState());

    if (!fileData) {
      return;
    }

    const { deletingData } = transformDeleteAttachmentInput(input);

    if (config.events.local) {
      if (fileData.transaction) {
        releaseTransaction(fileData.transaction);
      }

      if (deletingData) {
        emitEvent<IDeletedAttachment>(deletingData, {
          type: EventType.ATTACHMENT_DELETED,
        });
      }
    }

    fileData.uploader.abort();

    removeFileData(input.id);
  }, []);

  return cancelAttachmentUploadCommand;
};
