import React from 'react';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { ITheme } from 'core/theme/theme.interface';
import { getGray100Color, getSMBorderRadius, getSMFontSize } from 'core/theme/helpers';

import { IDropzoneProps } from './interfaces';

const getContentStyles = (params: ThemedStyledProps<IStyledDropperProps, ITheme>) => {
  const { theme, $variant } = params;

  switch ($variant) {
    case 'error':
      return css`
        color: ${theme.colors.red400};
        border: 1px dashed ${theme.colors.red400};
      `;
    case 'accepted':
      return css`
        color: ${theme.colors.blue300};
        border: 1px dashed ${theme.colors.blue300};
      `;
    default:
      return css`
        color: ${theme.colors.gray800};
        border: 1px dashed ${theme.colors.gray800};
      `;
  }
};

const Dropper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 2;
  padding: 20px;
  box-sizing: border-box;
  background: ${getGray100Color};
`;

const DropperContent = styled.div<IStyledDropperProps>`
  ${getContentStyles}

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  transition: all 300ms ease-out;
  border-radius: ${getSMBorderRadius};

  & svg {
    & path[fill]:not(path[fill='none']) {
      fill: currentColor;
    }

    & path[stroke]:not(path[stroke='none']) {
      stroke: currentColor;
    }
  }
`;

const Text = styled.span`
  color: inherit;
  font-size: ${getSMFontSize};
  font-weight: 500;
`;

interface IStyledDropperProps {
  $variant: IDropzoneProps['variant'];
}

export const Dropzone = (props: IDropzoneProps) => {
  const { icon, content, variant = 'default', className, dataTestId } = props;

  const classes = `${className} ${variant}`.trim();

  return (
    <Dropper className={classes} data-testid={dataTestId}>
      <DropperContent className="dropper-content" $variant={variant}>
        {icon}

        <Text>{content}</Text>
      </DropperContent>
    </Dropper>
  );
};
