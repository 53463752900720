import * as React from 'react';
function SvgVideoPlaySm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.333 12.5v-5c0-4.167-1.666-5.834-5.833-5.834h-5c-4.167 0-5.833 1.667-5.833 5.833v5c0 4.167 1.666 5.834 5.833 5.834h5c4.167 0 5.833-1.667 5.833-5.834zM2.1 5.926h15.8M7.1 1.758v4.05M12.9 1.758v3.675"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 12.042v-1c0-1.283.908-1.808 2.017-1.167l.866.5.867.5c1.108.642 1.108 1.692 0 2.334l-.867.5-.866.5c-1.109.641-2.017.116-2.017-1.167v-1 0z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgVideoPlaySm;
