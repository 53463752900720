import styled from 'styled-components';
import { getGray500Color, getGray800Color, getXSFontSize, getXXLBorderRadius } from 'core/theme/helpers';

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${getGray800Color};
  padding: 2px 5px;
  cursor: default;
  font-size: ${getXSFontSize};
  min-width: 20px;
  min-height: 20px;
  font-weight: 500;
  background: ${getGray500Color};
  box-sizing: border-box;
  border-radius: ${getXXLBorderRadius};
`;
