import React from 'react';
import styled, { css } from 'styled-components';
import { getSMFontSize, getBlackColor } from 'core/theme/helpers';

import { ITabItem } from './interfaces';

const StyledButton = styled.button<IStyledButtonProps>`
  position: relative;
  color: ${getBlackColor};
  font-size: ${getSMFontSize};
  text-align: center;

  padding: 20px 0;

  &:focus {
    outline: none;
  }

  ${({ theme, $isActive }) =>
    $isActive &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        border-radius: 3px 3px 0px 0px;
        background-color: ${theme.colors.blue300};
      }
    `}
`;

interface IStyledButtonProps {
  $isActive: boolean;
}

interface IProps {
  item: ITabItem;
  isActive: boolean;
  onChange: (item: ITabItem) => void;
}

export const TabItem = (props: IProps) => {
  const { item, isActive, onChange } = props;

  const _handleClick = () => {
    onChange(item);
  };

  return (
    <StyledButton type="button" $isActive={isActive} onClick={_handleClick}>
      {item.tabName}
    </StyledButton>
  );
};
