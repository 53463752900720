import { GA4_ID } from '../constants';

export const getGAClientId = (callback: (clientId?: string | Gtag.CustomParams) => void) => {
  let attempt = 0;

  const getClientId = () => {
    if (attempt > 5) {
      return;
    }

    if (!window.gtag) {
      // gtag not yet available; wait a bit and try again.
      attempt += 1;
      setTimeout(getClientId, 200);
    }

    window.gtag('get', GA4_ID, 'client_id', callback);
  };

  getClientId();
};
