import * as React from 'react';
function SvgIndicatorAttention(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#indicator_attention_svg__clip0_5011_855)">
        <circle cx={12} cy={12} r={12} fill="#F94747" />
        <path d="M12 8v4M12 16h.01" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="indicator_attention_svg__clip0_5011_855">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgIndicatorAttention;
