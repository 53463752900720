import { User } from 'core/graphql/fragments/impl/user';

export const getUserShortName = (userData?: Pick<User, 'firstName' | 'lastName'> | null) => {
  if (!userData) {
    return '';
  }

  const firstName = userData.firstName ?? '';

  if (!userData.lastName) {
    return firstName;
  }

  return `${firstName} ${userData.lastName[0]}.`;
};
