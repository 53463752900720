import { gql } from '@apollo/client';

export const CREATE_MULTIPART_UPLOAD_MUTATION = gql`
  mutation CreateMultipartUpload($input: CreateMultipartUploadInput!, $transaction: String) {
    createMultipartUpload(transaction: $transaction, input: $input) {
      fileId
      fileKey
      attachmentUrl
    }
  }
`;
