import React from 'react';
import styled, { css } from 'styled-components';
import { getBlackColor, getXLFontSize } from 'core/theme/helpers';

import { DIALOG_PADDING_VALUE, IDialogHeaderProps } from '../../model';

const HeaderContainer = styled.div<IHeaderContainerProps>`
  display: flex;
  align-items: center;
  padding: ${DIALOG_PADDING_VALUE}px;
  box-sizing: border-box;
  color: ${getBlackColor};
  font-size: ${getXLFontSize};
  font-weight: 600;
  overflow-wrap: anywhere;

  ${({ theme, $bordered }) =>
    $bordered &&
    css`
      border-bottom: 1px solid ${theme.colors.gray400};
    `};
`;

interface IHeaderContainerProps {
  $bordered?: boolean;
}

export const DialogHeader = (props: IDialogHeaderProps) => {
  const { children, bordered, className } = props;

  return (
    <HeaderContainer $bordered={bordered} className={className}>
      {children}
    </HeaderContainer>
  );
};
