import styled, { ThemedStyledProps, css } from 'styled-components';
import { ITheme } from 'core/theme';

import { IStyledIconButtonProps } from '../../model';

const getVariantStyles = (props: ThemedStyledProps<IStyledIconButtonProps, ITheme>) => {
  const { theme, $variant } = props;

  switch ($variant) {
    case 'ghost-white':
      return css`
        color: ${theme.colors.white};
        background: none;
        border-radius: 0;

        &:hover,
        &:focus-visible {
          color: ${theme.colors.blue300};
        }
      `;
    default:
      return css`
        color: ${theme.colors.gray800};
        background: none;
        border-radius: 0;

        &:hover,
        &:focus-visible {
          color: ${theme.colors.blue300};
        }
      `;
  }
};

export const StyledButton = styled.button<IStyledIconButtonProps>`
  ${getVariantStyles}

  display: flex;
  transition: all 300ms ease-out;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: ${({ $noPadding }) => ($noPadding ? 0 : '4px')};

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  & svg {
    & *[fill]:not(*[fill='none']) {
      fill: currentColor;
    }

    & *[stroke]:not(*[stroke='none']) {
      stroke: currentColor;
    }
  }
`;
