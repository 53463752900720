import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { isString } from 'lodash';

import { setAnalyticsData } from '../store/app';

import { GA4_ID } from './constants';
import { getGAClientId, getGASessionData } from './helpers';

ReactPixel.init('427595875734875');

export const AnalyticsModule = () => {
  const location = useLocation();

  useEffect(() => {
    getGAClientId((clientId) => {
      if (!isString(clientId)) {
        return;
      }

      setAnalyticsData({ clientId });
    });

    getGASessionData((data) => {
      setAnalyticsData(data);
    });
  }, []);

  useEffect(() => {
    const path = location.pathname + location.search + location.hash;

    window.gtag('event', 'page_view', {
      send_to: GA4_ID,
      page_path: path,
      page_hash: location.hash,
      page_search: location.search,
    });
  }, [location]);

  return null;
};
