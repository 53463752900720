import { isNull } from 'lodash';
import { prepareCounter } from 'shared/helpers/number';

export const setDocumentCounterTitle = (counter: number) => {
  const title = window.document.title;

  const matches = title.match(/^\(\d+\+?\)\s(.+)/);
  const isEmptyMatches = isNull(matches);

  if (counter > 0) {
    const preparedCounter = prepareCounter(counter);

    window.document.title = `(${preparedCounter}) ${isEmptyMatches ? title : matches[1]}`;
  } else {
    if (isEmptyMatches) {
      return;
    }

    window.document.title = matches[1];
  }
};
