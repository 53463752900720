import * as React from 'react';
function SvgIndicatorConfirm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={6} cy={6.5} r={6} fill="#1AC932" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.069 4.312a.439.439 0 01.032.652L5.473 8.656a.515.515 0 01-.367.15.515.515 0 01-.367-.15l-1.84-1.873a.439.439 0 01.032-.652.524.524 0 01.703.03l1.472 1.5 3.26-3.32a.524.524 0 01.703-.029z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgIndicatorConfirm;
