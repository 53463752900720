import MarkdownIt from 'markdown-it';
import { MENTION_MARKDOWN_REGEX } from 'features/editor/model';

const PLUGIN_MARKDOWN_REGEX = new RegExp(`^${MENTION_MARKDOWN_REGEX.source}`);

export const mentionMarkdownItPlugin: MarkdownIt.PluginSimple = (md) => {
  md.inline.ruler.push('mention', (state, silent) => {
    if (silent) {
      return false;
    }

    const match = PLUGIN_MARKDOWN_REGEX.exec(state.src.slice(state.pos));

    if (!match) {
      return false;
    }

    const [fullString, trigger, value, data] = match;

    const token = state.push('mention', '', 0);

    const tokenAttrs: [string, string][] | null = [['data-mention', 'true']];

    if (data) {
      tokenAttrs.push(['data-mention-data', data]);
    }

    token.type = 'mention';
    token.attrs = tokenAttrs;
    token.content = `${trigger}${value}`;

    state.pos = state.pos + fullString.length;

    return true;
  });

  md.renderer.rules.mention = (tokens, idx) => {
    if (tokens.length <= idx) {
      return '';
    }

    const token = tokens[idx];

    const attrs = token.attrs ? token.attrs.map((attr) => `${attr[0]}='${attr[1]}'`).join(' ') : '';

    return `<span ${attrs}>${md.utils.escapeHtml(token.content)}</span>`;
  };
};
