import { IFuseOptions } from 'fuse.js';

import { useFuseSearch } from './use-fuse-search';
import { useSearchState } from './use-search-state';

export const useSearch = <T>(list: ReadonlyArray<T>, options?: IFuseOptions<T>) => {
  const { searchValue, debouncedValue, search, resetSearch } = useSearchState();

  const result = useFuseSearch(list, debouncedValue, options);

  return { result, searchValue, search, resetSearch };
};
