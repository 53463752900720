import { ReactElement } from 'react';
import { TippyProps } from '@tippyjs/react';

export enum TooltipPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export type TooltipContainerWidthType = 'content' | 'full';

export interface ITooltipProps extends Pick<TippyProps, 'disabled' | 'reference' | 'children' | 'hideOnClick'> {
  width?: number;
  delay?: number;
  content?: string | ReactElement;
  position?: TooltipPosition;
  className?: string;
  containerWidth?: TooltipContainerWidthType;
}
