import { omit, replace } from 'lodash';
import {
  CompleteMultipartUploadInput,
  CreateMultipartUploadUrlsInput,
} from 'core/graphql/interfaces/graphql.interfaces';

import { apolloClient } from '../apollo';
import {
  CreateMultipartUpload,
  CreateMultipartUploadVariables,
  CompleteMultipartUpload,
  CompleteMultipartUploadVariables,
  CreateMultipartUploadUrls,
  CreateMultipartUploadUrlsVariables,
  CREATE_MULTIPART_UPLOAD_MUTATION,
  COMPLETE_MULTIPART_UPLOAD_MUTATION,
  CREATE_MULTIPART_UPLOAD_URLS_MUTATION,
} from '../graphql/mutations/attachment';

import { Uploader } from './Uploader';
import {
  UploadStage,
  UploadStatus,
  UploaderSourceType,
  IUploaderMutationData,
  IMultipartUploaderOptions,
  IAWSPart,
  IUploadedPart,
  IAWSFileData,
} from './interfaces';

export class MultipartUploader extends Uploader {
  private uploadedParts: IUploadedPart[];

  private progressCache: Map<number, number>;

  private activeConnections: Map<number, XMLHttpRequest>;

  private awsFileData: IAWSFileData | null;

  private awsFileParts: IAWSPart[];

  public readonly options: IMultipartUploaderOptions;

  constructor(
    source: UploaderSourceType,
    mutationData: IUploaderMutationData,
    options?: Partial<IMultipartUploaderOptions>
  ) {
    super(source, mutationData);

    this.options = {
      chunkSize: options?.chunkSize || 1024 * 1024 * 5,
      threadsQuantity: options?.threadsQuantity || 5,
    };

    this.awsFileData = null;
    this.awsFileParts = [];
    this.uploadedParts = [];
    this.progressCache = new Map();
    this.activeConnections = new Map();
  }

  // starting the multipart upload request
  start() {
    this.stage = UploadStage.STARTED;

    this.callStageChangeListeners?.(this.stage, this);
    this.initialize();
  }

  abort() {
    if (this.status === UploadStatus.ABORTED) {
      return;
    }

    this.status = UploadStatus.ABORTED;

    this.activeConnections.forEach((connection) => {
      connection.abort();
    });

    this.callStatusChangeListeners?.(this.status, this);
  }

  retry() {
    if (this.status !== UploadStatus.STOPPED) {
      return;
    }

    this.status = UploadStatus.PENDING;

    this.callStatusChangeListeners?.(this.status, this);
    this.initialize();
  }

  private async initialize() {
    try {
      if (this.stage === UploadStage.STARTED) {
        await this.createMultipartUpload();
      }

      if (this.stage === UploadStage.UPLOAD_CREATED) {
        await this.createMultipartUploadUrls();
      }

      if (this.stage === UploadStage.UPLOAD_URLS_CREATED) {
        this.sendNext();
      }
    } catch (error) {
      this.status = UploadStatus.STOPPED;

      this.callStatusChangeListeners?.(this.status, this);
      this.callErrorListeners?.(error as Error, this);
    }
  }

  private sendNext() {
    if (this.activeConnections.size >= this.options.threadsQuantity) {
      return;
    }

    if (!(this.awsFileParts.length || this.activeConnections.size)) {
      this.completeMultipartUpload();

      return;
    }

    const part = this.awsFileParts.pop();

    if (!part) {
      return;
    }

    const sentSize = (part.partNumber - 1) * this.options.chunkSize;

    const chunk = this.source.slice(sentSize, sentSize + this.options.chunkSize);

    this.uploadChunk(chunk, part)
      .then(() => {
        this.sendNext();
      })
      .catch((error) => {
        this.awsFileParts.push(part);

        if (this.status === UploadStatus.ABORTED) {
          return;
        }

        if (!this.activeConnections.size) {
          this.status = UploadStatus.STOPPED;

          this.callStatusChangeListeners?.(this.status, this);
        }

        this.callErrorListeners?.(error, this);
      });

    this.sendNext();
  }

  // uploading a part through its pre-signed URL
  private uploadChunk(requestBody: XMLHttpRequestBodyInit, part: IAWSPart) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      this.activeConnections.set(part.partNumber, xhr);

      xhr.open('PUT', part.signedUrl);

      xhr.onabort = () => {
        this.activeConnections.delete(part.partNumber);

        reject(new Error('Upload canceled by user'));
      };

      xhr.onerror = () => {
        this.activeConnections.delete(part.partNumber);

        reject(new Error('Error uploading file'));
      };

      xhr.onloadend = () => {
        if (xhr.status !== 200) {
          reject(new Error(xhr.statusText));

          return;
        }

        const eTag = xhr.getResponseHeader('ETag');

        if (!eTag) {
          reject(new Error('Failed to get eTag'));

          return;
        }

        this.uploadedSize += this.progressCache.get(part.partNumber) ?? 0;

        this.uploadedParts.push({
          eTag: replace(eTag, /"/g, ''),
          partNumber: part.partNumber,
        });

        this.progressCache.delete(part.partNumber);
        this.activeConnections.delete(part.partNumber);

        resolve(xhr.status);
      };

      xhr.upload.addEventListener('progress', this.handleProgress.bind(this, part.partNumber));

      xhr.send(requestBody);
    });
  }

  private async createMultipartUpload() {
    const createMultipartUploadResponse = await apolloClient.mutate<
      CreateMultipartUpload,
      CreateMultipartUploadVariables
    >({
      mutation: CREATE_MULTIPART_UPLOAD_MUTATION,
      variables: {
        input: omit(this.mutationData, 'transaction'),
        transaction: this.mutationData.transaction,
      },
    });

    const awsFileData = createMultipartUploadResponse.data?.createMultipartUpload;

    if (!awsFileData) {
      throw new Error('Failed to upload file');
    }

    this.fileURL = awsFileData.attachmentUrl;

    this.awsFileData = {
      awsFileId: awsFileData.fileId,
      awsFileKey: awsFileData.fileKey,
    };

    this.stage = UploadStage.UPLOAD_CREATED;

    this.callStageChangeListeners?.(this.stage, this);
  }

  private async createMultipartUploadUrls() {
    if (!this.awsFileData) {
      return;
    }

    // retrieving the pre-signed URLs
    const numberOfparts = Math.ceil(this.totalSize / this.options.chunkSize);

    const createMultipartUploadUrlsInput: CreateMultipartUploadUrlsInput = {
      parts: numberOfparts,
      fileId: this.awsFileData.awsFileId,
      fileKey: this.awsFileData.awsFileKey,
    };

    const createMultipartUploadUrlsResponse = await apolloClient.mutate<
      CreateMultipartUploadUrls,
      CreateMultipartUploadUrlsVariables
    >({
      mutation: CREATE_MULTIPART_UPLOAD_URLS_MUTATION,
      variables: {
        input: createMultipartUploadUrlsInput,
        transaction: this.mutationData.transaction,
      },
    });

    const awsFileParts = createMultipartUploadUrlsResponse.data?.createMultipartUploadUrls;

    if (!awsFileParts) {
      throw new Error('Failed to upload file');
    }

    this.awsFileParts = awsFileParts.parts.map((item) => ({
      signedUrl: item.signedUrl,
      partNumber: item.partNumber,
    }));

    this.stage = UploadStage.UPLOAD_URLS_CREATED;

    this.callStageChangeListeners?.(this.stage, this);
  }

  // finalizing the multipart upload request on success by calling the finalization API
  private async completeMultipartUpload() {
    try {
      if (!this.awsFileData) {
        throw new Error('Failed to get file id and key');
      }

      const completeMultipartUploadInput: CompleteMultipartUploadInput = {
        id: this.mutationData.id,
        parts: this.uploadedParts,
        fileId: this.awsFileData.awsFileId,
        fileKey: this.awsFileData.awsFileKey,
      };

      const completeMultipartUploadResponse = await apolloClient.mutate<
        CompleteMultipartUpload,
        CompleteMultipartUploadVariables
      >({
        mutation: COMPLETE_MULTIPART_UPLOAD_MUTATION,
        variables: {
          input: completeMultipartUploadInput,
          transaction: this.mutationData.transaction,
        },
      });

      const isSuccess = completeMultipartUploadResponse.data?.completeMultipartUpload;

      if (!isSuccess) {
        throw new Error('Failed to upload file');
      }

      this.stage = UploadStage.FINISHED;
      this.status = UploadStatus.COMPLETED;

      this.callStageChangeListeners?.(this.stage, this);
      this.callStatusChangeListeners?.(this.status, this);
      this.callCompleteListeners?.(this);
    } catch (error) {
      this.status = UploadStatus.STOPPED;

      this.callStatusChangeListeners?.(this.status, this);
      this.callErrorListeners?.(error as Error, this);
    }
  }

  // calculating the current progress of the multipart upload request
  private handleProgress(partNumber: number, event: ProgressEvent<XMLHttpRequestEventTarget>) {
    let tempUploadedSize = this.uploadedSize;

    this.progressCache.set(partNumber, event.loaded);

    this.progressCache.forEach((cache) => {
      tempUploadedSize += cache;
    });

    const progress = Math.round((tempUploadedSize / this.totalSize) * 100);

    this.callProgressListeners?.(
      {
        sent: tempUploadedSize,
        total: this.totalSize,
        status: this.status,
        progress,
      },
      this
    );
  }
}
