import * as React from 'react';
function SvgDropFileLg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28 12.5v6.25c0 6.25-2.5 8.75-8.75 8.75h-7.5C5.5 27.5 3 25 3 18.75v-7.5C3 5 5.5 2.5 11.75 2.5H18"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 12.5h-5c-3.75 0-5-1.25-5-5v-5l10 10z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgDropFileLg;
