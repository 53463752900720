import * as React from 'react';
function SvgGlobalSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 2.25h.75a21.318 21.318 0 000 13.5H6M11.25 2.25a21.318 21.318 0 010 13.5"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 12v-.75a21.318 21.318 0 0013.5 0V12M2.25 6.75a21.318 21.318 0 0113.5 0"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgGlobalSm;
