import React, { forwardRef } from 'react';

import { IListRowProps } from '../model';

import { ListRowContainer } from './styled';

export const ListRow = forwardRef<HTMLLIElement, IListRowProps>((props, ref) => {
  const {
    active = false,
    hovered = false,
    children,
    hoverBackground,
    defaultBackground,
    withExitAnimation = false,
    ...otherProps
  } = props;

  return (
    // @ts-ignore
    <ListRowContainer
      ref={ref}
      $active={active}
      $hovered={hovered}
      $hoverBackground={hoverBackground}
      $defaultBackground={defaultBackground}
      $withExitAnimation={withExitAnimation}
      {...otherProps}
    >
      {children}
    </ListRowContainer>
  );
});

ListRow.displayName = 'ListRow';
