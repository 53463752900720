import * as React from 'react';
function SvgFileWav(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_wav_svg__clip0_4578_26731)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#15DDB6"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.328 2.328 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#17B594"
        />
        <path
          d="M6.588 29.208l1.955-6.292H9.4l-.169 1.251-1.99 6.398h-.837l.183-1.357zm-1.315-6.292l1.667 6.362.12 1.287h-.88l-2.207-7.65h1.3zm5.998 6.313l1.59-6.313h1.293l-2.208 7.649h-.872l.197-1.336zm-1.68-6.313l1.912 6.186.218 1.463h-.83l-2.046-6.412-.168-1.237h.914zM19.544 29.257V25.32c0-.301-.061-.563-.183-.784a1.199 1.199 0 00-.534-.523c-.24-.123-.535-.184-.886-.184-.328 0-.617.056-.865.17a1.507 1.507 0 00-.577.445.98.98 0 00-.204.594h-1.3c0-.274.07-.545.21-.813.141-.269.343-.512.605-.729.267-.221.586-.395.956-.523a3.75 3.75 0 011.252-.198c.553 0 1.04.095 1.462.283.427.189.76.474.999.856.244.377.366.85.366 1.42v3.564c0 .254.02.525.063.813.047.287.115.534.204.742v.113h-1.357a2.457 2.457 0 01-.155-.6 4.873 4.873 0 01-.056-.708zm.225-3.33l.014.92h-1.315c-.37 0-.7.03-.991.091-.291.057-.535.144-.732.262a1.24 1.24 0 00-.45.445c-.103.175-.154.38-.154.615 0 .24.054.46.161.658.108.198.27.356.486.473.22.114.49.17.808.17.398 0 .75-.085 1.055-.254.305-.17.546-.377.724-.622.183-.246.281-.484.295-.714l.556.629a1.905 1.905 0 01-.267.657 3.137 3.137 0 01-1.442 1.237 2.781 2.781 0 01-1.11.212c-.516 0-.969-.101-1.358-.304a2.325 2.325 0 01-.9-.813 2.161 2.161 0 01-.316-1.152c0-.41.08-.77.239-1.082.16-.315.389-.577.689-.784.3-.212.66-.373 1.083-.481a5.682 5.682 0 011.413-.163h1.512zM24.66 29.384l2.08-6.468h1.33l-2.736 7.649h-.872l.197-1.18zm-1.737-6.468l2.144 6.504.148 1.145h-.872l-2.756-7.65h1.336z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_wav_svg__clip0_4578_26731">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileWav;
