import { IFileData, IFilesStore } from '../interfaces';

export const selectFileData =
  (fileId?: string) =>
  (store: IFilesStore): IFileData | null => {
    if (!fileId) {
      return null;
    }

    const file: IFileData | undefined = store[fileId];

    return file ?? null;
  };
