interface IPreview {
  url: string;
  resolution: string;
}

export const getImageResponsiveAttributes = (originalURL: string, previews?: ReadonlyArray<IPreview>) => {
  let sizes: string | undefined;
  let srcSet: string | undefined;

  if (previews?.length) {
    sizes = '';
    srcSet = '';

    previews.forEach((preview, index) => {
      const { url, resolution } = preview;

      const resolutionWidth = resolution.split('x')[0];

      const postfix = index === previews.length - 1 ? '' : ', ';

      sizes += `(max-width: ${resolutionWidth}px) ${resolutionWidth}px${postfix}`;
      srcSet += `${url} ${resolutionWidth}w${postfix}`;
    });
  }

  return {
    src: originalURL,
    sizes,
    srcSet,
  };
};
