import { TextNode } from 'lexical';
import { CodeNode } from '@lexical/code';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { ListNode, ListItemNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';

import { ImageNode } from '../nodes/image-node';
import { ExtentedTextNode } from '../nodes/extended-text-node';

export const NODES = [
  CodeNode,
  LinkNode,
  ListNode,
  QuoteNode,
  ImageNode,
  HeadingNode,
  ListItemNode,
  AutoLinkNode,
  ExtentedTextNode,
  HorizontalRuleNode,
  { replace: TextNode, with: (node: TextNode) => new ExtentedTextNode(node.__text, node.__key) },
];
