import { Accept } from 'react-dropzone';

export const MAX_FILE_SIZE = 500 * 1024 * 1024; // 500 MB
export const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10 MB

export const IMAGE_PNG_FORMAT = 'image/png';
export const IMAGE_JPG_FORMAT = 'image/jpg';
export const IMAGE_JPEG_FORMAT = 'image/jpeg';
export const IMAGE_WEBP_FORMAT = 'image/webp';

export const VIDEO_XMS_FORMAT = 'video/x-msvideo';
export const VIDEO_MP4_FORMAT = 'video/mp4';
export const VIDEO_OGG_FORMAT = 'video/ogg';
export const VIDEO_WEBM_FORMAT = 'video/webm';
export const VIDEO_QUICKTIME_FORMAT = 'video/quicktime';

export const PDF_FILE_FORMAT = 'application/pdf';

export const ACCEPTED_IMAGE_FORMATS = new Set([
  IMAGE_PNG_FORMAT,
  IMAGE_JPG_FORMAT,
  IMAGE_WEBP_FORMAT,
  IMAGE_JPEG_FORMAT,
]);

export const DROPZONE_ACCEPTED_IMAGE_FORMATS: Accept = {
  [IMAGE_PNG_FORMAT]: ['.png'],
  [IMAGE_JPG_FORMAT]: ['.jpg'],
  [IMAGE_JPEG_FORMAT]: ['.jpeg'],
  [IMAGE_WEBP_FORMAT]: ['.webp'],
};

export const ACCEPTED_VIDEO_FORMATS = new Set([
  VIDEO_MP4_FORMAT,
  VIDEO_OGG_FORMAT,
  VIDEO_XMS_FORMAT,
  VIDEO_WEBM_FORMAT,
  VIDEO_QUICKTIME_FORMAT,
]);
