import * as React from 'react';
function SvgSandglassSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.7 2.167H7.3c-3.133 0-3.375 2.816-1.683 4.35l8.766 7.966c1.692 1.534 1.45 4.35-1.683 4.35H7.3c-3.133 0-3.375-2.816-1.683-4.35l8.766-7.966c1.692-1.534 1.45-4.35-1.683-4.35z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgSandglassSm;
