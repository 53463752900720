import React from 'react';
import { DateTime } from 'luxon';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { ITheme } from 'core/theme/theme.interface';
import { getGray500Color, getSMFontSize } from 'core/theme/helpers';

import { getWeekdayPosition } from '../../../helpers';

const getButtonStyles = (props: ThemedStyledProps<IDayButtonProps, ITheme>) => {
  const { theme, $isToday, $isActive, $isWeekend, $markedAsRecurring } = props;

  if ($isActive) {
    return css`
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.blue300};
      background: ${theme.colors.blue300};
    `;
  }

  if ($markedAsRecurring) {
    return css`
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.blue250};
      background: ${theme.colors.blue250};
    `;
  }

  return css`
    color: ${$isWeekend ? theme.colors.gray800 : theme.colors.black};
    border: 1px solid ${$isToday ? theme.colors.gray800 : 'transparent'};

    &:hover {
      border: 1px solid ${theme.colors.gray200};
      background: ${theme.colors.gray200};
    }
  `;
};

const DayButton = styled.button<IDayButtonProps>`
  ${getButtonStyles}

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  font-size: ${getSMFontSize};
  border-radius: 50%;

  &:disabled {
    color: ${getGray500Color};
    cursor: not-allowed;
    pointer-events: none;
  }
`;

interface IDayButtonProps {
  $isToday?: boolean;
  $isActive?: boolean;
  $isWeekend?: boolean;
  $markedAsRecurring?: boolean;
}

interface IProps {
  date: DateTime;
  isToday?: boolean;
  isActive?: boolean;
  isWeekend?: boolean;
  isDisabled?: boolean;
  firstDayOfWeek: number;
  markedAsRecurring?: boolean;
  onClick?: (date: DateTime) => void;
}

export const DayItem = (props: IProps) => {
  const { date, isActive, isToday, isWeekend, isDisabled, firstDayOfWeek, markedAsRecurring, onClick } = props;

  const _handleClick = () => {
    if (isDisabled) {
      return;
    }

    onClick?.(date);
  };

  const column = getWeekdayPosition(date, firstDayOfWeek);

  return (
    <DayButton
      style={{ gridColumn: column }}
      disabled={isDisabled}
      $isToday={isToday}
      $isActive={isActive}
      $isWeekend={isWeekend}
      $markedAsRecurring={markedAsRecurring}
      onClick={_handleClick}
    >
      {date.day}
    </DayButton>
  );
};
