export const DEVMODE = !!localStorage.getItem('BORDIO_DEVMODE');

export const DEFAULT_MIN_TEXT_LENGTH = 1;
export const DEFAULT_MAX_TEXT_LENGTH = 155;

export const MAX_TEAM_NAME_LENGTH = 30;

export const MAX_PROJECT_NAME_LENGTH = 55;

export const MIN_ORGANIZATION_NAME_LENGTH = 3;
export const MAX_ORGANIZATION_NAME_LENGTH = 30;

export const MAX_FOLDER_NAME_LENGTH = 48;

export const MAX_PROFILE_NAME_LENGTH = 48;

export const MAX_CARD_TITLE_LENGTH = 200;

export const MAX_EVENT_GUESTS = 20;

export const MAX_PROJECT_GUESTS = 5;

export const FIRST_DATE_FORMAT = 'd MMM yyyy';
export const SECOND_DATE_FORMAT = 'MMM d, yyyy';

export const FIRST_DATE_SHORT_FORMAT = 'd MMM';
export const SECOND_DATE_SHORT_FORMAT = 'MMM d';

export const FIRST_DAY_OF_WEEK_MONDAY = 1;
export const FIRST_DAY_OF_WEEK_SUNDAY = 0;

export const TWELVE_HOUR_TIME_FORMAT = 'H';
export const TWENTY_FOUR_HOUR_TIME_FORMAT = 'HH';

export const TABS_WEEK = [
  { name: 'Monday', value: FIRST_DAY_OF_WEEK_MONDAY },
  { name: 'Sunday', value: FIRST_DAY_OF_WEEK_SUNDAY },
];

export const TABS_DATEFORMAT = [
  { name: '31 December 2022', value: FIRST_DATE_FORMAT },
  { name: 'December 31, 2022', value: SECOND_DATE_FORMAT },
];

export const TABS_TIMEFORMAT = [
  { name: '12 hours: 9.00 PM', value: TWELVE_HOUR_TIME_FORMAT },
  { name: '24 hours: 21:00', value: TWENTY_FOUR_HOUR_TIME_FORMAT },
];
