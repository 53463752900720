import * as React from 'react';
function SvgDeclineSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 16.5c4.125 0 7.5-3.375 7.5-7.5S13.125 1.5 9 1.5 1.5 4.875 1.5 9s3.375 7.5 7.5 7.5zM6 12l6-6M12 12L6 6"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgDeclineSm;
