import * as React from 'react';
function SvgCrownSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.944 15.555H6.111c-.35 0-.742-.275-.858-.608L1.775 5.558C1.283 4.175 1.858 3.75 3.04 4.6l3.25 2.325c.542.375 1.159.183 1.392-.425L9.15 2.59c.466-1.25 1.241-1.25 1.708 0L12.325 6.5c.233.608.85.8 1.383.425l3.05-2.175c1.3-.934 1.925-.459 1.392 1.05l-3.34 9.163c-.124.317-.516.592-.866.592zM5.417 18.334h9.166"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgCrownSm;
