interface IParams {
  newWidth: number;
  originalWidth: number;
  originalHeight: number;
}

export const calculateProportionalHeight = (params: IParams) => {
  const { newWidth, originalWidth, originalHeight } = params;

  return originalHeight ? (originalHeight * newWidth) / originalWidth : 0;
};
