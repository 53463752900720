import React from 'react';
import { isFunction, isNumber } from 'lodash';
import { SimpleBar } from 'shared/components/simplebar/SimpleBar';

import { IDropdownProps } from '../../model';
import { SearchBar } from '../search-bar';
import { DropdownContentContainer } from '../styled';

interface IProps
  extends Pick<IDropdownProps, 'children' | 'maxHeight' | 'searchValue' | 'searchPlaceholder' | 'onSearch'> {
  scrollableNodeRef: React.MutableRefObject<HTMLDivElement | null>;
  onClose: () => void;
}

export const DropdownContent = (props: IProps) => {
  const { children, maxHeight, searchValue, scrollableNodeRef, searchPlaceholder, onClose, onSearch } = props;

  return (
    <>
      {isFunction(onSearch) && (
        <SearchBar searchValue={searchValue} searchPlaceholder={searchPlaceholder} onSearch={onSearch} />
      )}

      {isNumber(maxHeight) ? (
        // @ts-ignore
        <SimpleBar style={{ maxHeight, overflowX: 'hidden' }} scrollableNodeProps={{ ref: scrollableNodeRef }}>
          <DropdownContentContainer className="dropdown-content">{children?.(onClose)}</DropdownContentContainer>
        </SimpleBar>
      ) : (
        <DropdownContentContainer className="dropdown-content">{children?.(onClose)}</DropdownContentContainer>
      )}
    </>
  );
};
