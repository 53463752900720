import * as React from 'react';
function SvgActivateSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.168 3.81A7.514 7.514 0 009 3.488 6.5 6.5 0 002.498 9.99a6.502 6.502 0 1011.91-3.607M12.098 3.99L9.93 1.5M12.098 3.99L9.57 5.835"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgActivateSm;
