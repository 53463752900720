import React, { FC } from 'react';
import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';

const Placeholder = styled.div<{
  $round: boolean;
}>`
  height: 22px;
  background: ${getGray400Color};
  border-radius: ${({ theme, $round }) => ($round ? '50%' : theme.border.radius.md)};
`;

interface IProps {
  round?: boolean;
  width?: number | string;
  style?: React.CSSProperties;
  height?: number | string;
  margin?: string;
  loading?: boolean;
  className?: string;
}

export const Skeleton: FC<IProps> = (props) => {
  const { loading, height, width, margin, round = false, children, className, style } = props;

  if (loading)
    return (
      <Placeholder
        style={{
          ...style,
          width,
          height,
          margin,
        }}
        $round={round}
        className={className}
      />
    );

  return <>{children}</>;
};
