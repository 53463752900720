import * as React from 'react';
function SvgDocumentSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.787 12.168H6.372M11.787 9.027H6.372M8.438 5.895H6.372"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M11.931 2.063l-5.766.002c-2.07.013-3.352 1.375-3.352 3.453v6.897c0 2.088 1.291 3.455 3.38 3.455l5.766-.002c2.07-.013 3.353-1.376 3.353-3.453V5.518c0-2.088-1.293-3.455-3.38-3.455z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgDocumentSm;
