import * as React from 'react';
function SvgCrown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M47.439 43.85c0-.39-.078-.775-.23-1.135a2.956 2.956 0 00-.655-.962 3.029 3.029 0 00-.983-.642 3.08 3.08 0 00-1.158-.224H9.763a3.086 3.086 0 00-1.19.194c-.38.142-.727.358-1.02.635a2.962 2.962 0 00-.683.974 2.91 2.91 0 000 2.317c.159.366.391.697.684.974.292.276.639.492 1.018.634.38.143.785.209 1.19.195h34.65c.803 0 1.572-.312 2.14-.867a2.933 2.933 0 00.887-2.093zM3.687 19.878h.142l4.797 16.997h36.93l4.797-16.997h.142a3.65 3.65 0 001.998-.593 3.54 3.54 0 001.324-1.58 3.453 3.453 0 00.205-2.035 3.499 3.499 0 00-.984-1.803 3.62 3.62 0 00-1.841-.964 3.668 3.668 0 00-2.078.2 3.581 3.581 0 00-1.614 1.297 3.47 3.47 0 00-.606 1.957c0 .367.06.733.177 1.082l-8.678 4.965-9.32-15.134a3.534 3.534 0 001.396-1.739 3.452 3.452 0 00.062-2.212 3.523 3.523 0 00-1.298-1.811 3.647 3.647 0 00-2.145-.696c-.773 0-1.525.244-2.145.696a3.523 3.523 0 00-1.299 1.811c-.222.725-.2 1.5.062 2.212a3.534 3.534 0 001.397 1.74l-9.32 15.133-8.675-4.965c.116-.35.175-.715.177-1.082.001-.697-.21-1.38-.604-1.96a3.584 3.584 0 00-1.614-1.3 3.67 3.67 0 00-2.08-.202c-.698.135-1.34.47-1.844.963a3.503 3.503 0 00-.987 1.804c-.14.683-.07 1.392.202 2.037a3.542 3.542 0 001.324 1.583 3.654 3.654 0 002 .596z"
        fill="#0094FF"
      />
    </svg>
  );
}
export default SvgCrown;
