import { TextNode } from 'lexical';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { LINK, TEXT_FORMAT_TRANSFORMERS } from '@lexical/markdown';
import {
  FollowerKind,
  MentionReferenceType,
  ChatMessageReferenceKind,
} from 'core/graphql/interfaces/graphql.interfaces';
import { LINE_BREAK_TRANSFORMER, MENTION_MARKDOWN_TRANSFORMER } from 'features/editor/transformers';
import { MentionNode, ExtentedTextNode } from 'features/editor/nodes';
import { IOption } from 'shared/components/basic-selector';

export const CHAT_HEADER_HEIGHT = 78;

export const CHAT_MESSAGE_MAX_WIDTH_RATIO = 0.72;

export const CHAT_MESSAGES_LIMIT = 50;

export const FOLLOWERS_POPOVER_NAME = 'followers-popover';

export const CHAT_EDITOR_NODES = [
  LinkNode,
  MentionNode,
  AutoLinkNode,
  ExtentedTextNode,
  ExtentedTextNode,
  HorizontalRuleNode,
  { replace: TextNode, with: (node: TextNode) => new ExtentedTextNode(node.__text, node.__key) },
];

export const CHAT_EDITOR_TRANSFORMERS = [
  LINE_BREAK_TRANSFORMER,
  MENTION_MARKDOWN_TRANSFORMER,
  LINK,
  ...TEXT_FORMAT_TRANSFORMERS,
];

export const USER_FOLLOWING_OPTIONS: IOption<FollowerKind>[] = [
  {
    value: FollowerKind.All,
    label: 'Follow all updates',
  },
  {
    value: FollowerKind.UserComments,
    label: 'Follow only comments',
  },
  {
    value: FollowerKind.None,
    label: 'Don’t follow',
  },
];

export const FOLLOWER_FOLLOWING_OPTIONS: IOption<FollowerKind>[] = [
  {
    value: FollowerKind.All,
    label: 'Follow all updates',
    displayedLabel: 'all updates',
  },
  {
    value: FollowerKind.UserComments,
    label: 'Follow only comments',
    displayedLabel: 'only comments',
  },
  {
    value: FollowerKind.None,
    label: 'Remove follower',
  },
];

export const MENTION_REFERENCE_TYPE = {
  [ChatMessageReferenceKind.Task]: MentionReferenceType.Task,
  [ChatMessageReferenceKind.ScheduledEvent]: MentionReferenceType.ScheduledEvent,
} as Record<ChatMessageReferenceKind, MentionReferenceType>;
