export enum SystemColor {
  BLUE = 'blue',
  SKY = 'sky',
  SEA = 'sea',
  SWAMP = 'swamp',
  GREEN = 'green',
  GRASS = 'grass',
  ORANGE = 'orange',
  BROWN = 'brown',
  ROSE = 'rose',
  PURPLE = 'purple',
}
