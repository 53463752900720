import { isNumber } from 'lodash';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { ITheme } from 'core/theme/theme.interface';

import { IStyledTypographyProps } from './interfaces';

const getTextStyles = (props: ThemedStyledProps<IStyledTypographyProps, ITheme>) => {
  const { $noWrap, $textAlign, $fontStyle, $overflowWrap } = props;

  return css`
    text-align: ${$textAlign};
    font-style: ${$fontStyle};
    white-space: ${$noWrap ? 'nowrap' : 'normal'};
    overflow-wrap: ${$overflowWrap};
  `;
};

const getMaxLinesStyles = (props: ThemedStyledProps<IStyledTypographyProps, ITheme>) => {
  const { $maxLines } = props;

  if (isNumber($maxLines)) {
    if ($maxLines === 1) {
      return css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `;
    }

    return css`
      display: -webkit-box;
      -webkit-line-clamp: ${$maxLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }
};

const getFontSizeStyles = (props: ThemedStyledProps<IStyledTypographyProps, ITheme>) => {
  const { $size, theme } = props;

  if (isNumber($size)) {
    return `${$size}px`;
  }

  const themeFontSize = theme.font.size[$size];

  return themeFontSize ?? $size;
};

const getFontColorStyles = (props: ThemedStyledProps<IStyledTypographyProps, ITheme>) => {
  const { theme, $color } = props;

  switch ($color) {
    case 'text':
      return theme.colors.black;
    case 'primary':
      return theme.colors.blue300;
    case 'secondary':
      return theme.colors.gray800;
    case 'danger':
      return theme.colors.red400;
    case 'inherit':
      return 'inherit';
    default:
      return theme.colors[$color];
  }
};

const getFontWeightStyles = (props: ThemedStyledProps<IStyledTypographyProps, ITheme>) => {
  const { $fontWeight } = props;

  switch ($fontWeight) {
    case 'normal':
      return 400;
    case 'medium':
      return 500;
    case 'medium-bold':
      return 600;
    case 'bold':
      return 700;
    default:
      return 400;
  }
};

export const StyledTypography = styled.span<IStyledTypographyProps>`
  ${getTextStyles}
  ${getMaxLinesStyles}

  color: ${getFontColorStyles};
  font-size: ${getFontSizeStyles};
  font-weight: ${getFontWeightStyles};
`;
