import React from 'react';

import { ISwitcherItemProps } from '../model';
import { useSwitcher } from '../providers';

import { Label, HiddenInput } from './styled';

export const SwitcherItem = <T extends string | number>(props: ISwitcherItemProps<T>) => {
  const { icon, value, children, disabled, className } = props;

  const { name, value: selectedValue, onChange } = useSwitcher();

  const isChecked = value === selectedValue;

  const classes = isChecked ? `${className} active` : className;

  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    onChange(event, newValue);
  };

  return (
    <Label className={classes} $isDisabled={disabled}>
      {icon}

      {children}

      <HiddenInput
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        checked={isChecked}
        onChange={_handleChange}
      />
    </Label>
  );
};
