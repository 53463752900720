import { gql } from '@apollo/client';

export const CREATE_MULTIPART_UPLOAD_URLS_MUTATION = gql`
  mutation CreateMultipartUploadUrls($input: CreateMultipartUploadUrlsInput!, $transaction: String) {
    createMultipartUploadUrls(transaction: $transaction, input: $input) {
      parts {
        signedUrl
        partNumber
      }
    }
  }
`;
