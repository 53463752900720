import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { isString } from 'lodash';

import {
  CreateAttachmentUploadURL,
  CreateAttachmentUploadURLVariables,
  CREATE_ATTACHMENT_UPLOAD_URL_MUTATION,
} from '../../../graphql/mutations/attachment';
import { IUploadAttachmentInput } from '../interfaces';

interface IUseUploadAttachment {
  input: IUploadAttachmentInput;
  transaction?: string;
}

export const useUploadAttachmentCommand = () => {
  const [createUploadUrlMutation] = useMutation<CreateAttachmentUploadURL, CreateAttachmentUploadURLVariables>(
    CREATE_ATTACHMENT_UPLOAD_URL_MUTATION
  );

  const uploadAttachment = useCallback(async (params: IUseUploadAttachment): Promise<string> => {
    const { input, transaction } = params;

    const contentType = input.contentType || 'application/octet-stream';

    const { data } = await createUploadUrlMutation({
      variables: {
        input: {
          id: input.id,
          refId: input.refId,
          refType: input.refType,
          filename: input.filename,
          contentType,
          attachmentKind: input.attachmentKind,
        },
        transaction,
      },
    });

    if (!data) {
      throw new Error('Failed to upload file');
    }

    const source = isString(input.source) ? await (await fetch(input.source)).blob() : input.source;

    const result = await new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('PUT', data.createAttachmentUploadUrl.url);

      xhr.setRequestHeader('Content-Type', contentType);

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.responseURL);

          return;
        }

        reject(new Error(xhr.statusText));
      };

      xhr.onerror = () => {
        reject(new Error(xhr.statusText));
      };

      xhr.send(source);
    });

    const attachmentURL = result.split('?')[0];

    return attachmentURL;
  }, []);

  return uploadAttachment;
};
