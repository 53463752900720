import React from 'react';
import styled from 'styled-components';

import { DIALOG_PADDING_VALUE, IDialogBodyProps } from '../../model';

const BodyContainer = styled.div`
  padding: 0 ${DIALOG_PADDING_VALUE}px ${DIALOG_PADDING_VALUE}px ${DIALOG_PADDING_VALUE}px;
  box-sizing: border-box;
`;

export const DialogBody = (props: IDialogBodyProps) => {
  const { children, ...otherProps } = props;

  return <BodyContainer {...otherProps}>{children}</BodyContainer>;
};
