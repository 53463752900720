import React, { FC, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { getMDBorderRadius, getWhiteColor } from 'core/theme/helpers';
import { ESCAPE_KEY } from 'shared/constants/key-constants';
import { Dimmer } from 'shared/components/dimmer';
import { getContainer } from 'shared/helpers/dom';

import { IDialogProps } from '../model';

import { DialogHeader } from './dialog-header';
import { DialogBody } from './dialog-body';
import { DialogFooter } from './dialog-footer';

const Overlay = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: 8;
`;

const DialogBox = styled.dialog`
  display: flex;
  flex-direction: column;
  width: 480px;
  margin: 40px auto;
  border: none;
  outline: none;
  position: relative;
  background: ${getWhiteColor};
  border-radius: ${getMDBorderRadius};
  z-index: 2;
`;

interface IChildrenComponents {
  Header: typeof DialogHeader;
  Body: typeof DialogBody;
  Footer: typeof DialogFooter;
}

export const Dialog: FC<IDialogProps> & IChildrenComponents = (props) => {
  const {
    size,
    open,
    className,
    children,
    dataTestId,
    disableEscapeKeyDown = false,
    stopEventPropagation = false,
    onClose,
  } = props;

  const _handleClick = (event: React.MouseEvent<HTMLDialogElement>) => {
    if (stopEventPropagation) {
      event.stopPropagation();
    }
  };

  // Handle scape button to close dialog
  const _handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === ESCAPE_KEY) {
        onClose?.();
      }
    },
    [onClose]
  );

  // Handle dimmer click event to close dialog
  const _handleDimmerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    onClose?.();
  };

  useEffect(() => {
    if (!open || disableEscapeKeyDown) {
      return;
    }

    window.addEventListener('keydown', _handleKeyDown);

    return () => {
      window.removeEventListener('keydown', _handleKeyDown);
    };
  }, [open, disableEscapeKeyDown, _handleKeyDown]);

  // Do not render component if it not set as being shown
  if (!open) {
    return null;
  }

  return createPortal(
    <Overlay
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      data-testid="overlay"
    >
      <Dimmer onMouseDown={_handleDimmerClick} />

      <DialogBox style={{ width: size }} className={className} data-testid={dataTestId} onClick={_handleClick}>
        {children}
      </DialogBox>
    </Overlay>,
    getContainer('dialog-root')
  );
};

Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;
