import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 21px 20px 21px;
  box-sizing: border-box;
  border-top: 1px solid ${getGray400Color};
`;
