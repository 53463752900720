import React from 'react';
import { render } from 'react-dom';
import { Logger } from 'core/logger/logger';
import { App } from 'app';
import config from 'shared/config';

import './sentry.config';
import { register as registerServiceWorker } from './serviceWorkerRegistration';

Logger.subject('APP_VERSION').info(config.appVersion);

render(<App />, document.getElementById('root'));

registerServiceWorker();
