import React, { forwardRef } from 'react';
import { getUserInitials } from 'shared/helpers/user';
import { getImageResponsiveAttributes } from 'shared/helpers/aspect';

import { NoAvatar } from '../icons';

import { IAvatarProps } from './interfaces';
import { AvatarContainer, Image } from './styled';

export const Avatar = forwardRef<HTMLDivElement, IAvatarProps>((props, ref) => {
  const { src, name = 'Full Name', size = 'sm', previews, anonymous = false, ...otherProps } = props;

  const _renderContent = () => {
    if (anonymous) {
      return <NoAvatar />;
    }

    if (src) {
      const attributes = getImageResponsiveAttributes(src, previews);

      return <Image {...attributes} />;
    }

    return getUserInitials(name);
  };

  return (
    <AvatarContainer ref={ref} role="presentation" $size={size} {...otherProps}>
      {_renderContent()}
    </AvatarContainer>
  );
});

Avatar.displayName = 'Avatar';
