import React from 'react';
import { $applyNodeReplacement, DecoratorNode } from 'lexical';
import type {
  Spread,
  NodeKey,
  LexicalNode,
  EditorConfig,
  DOMExportOutput,
  DOMConversionMap,
  DOMConversionOutput,
  SerializedLexicalNode,
} from 'lexical';

import { IMAGE_BLOCK_TYPE } from '../../model/constants';
import { ImageComponent } from '../../ui/image-component';

export interface IImagePayload {
  src: string;
  key?: NodeKey;
  width?: number;
  height?: number;
  altText: string;
  attachmentId?: string;
}

export interface IImageNodeConstructorOptions {
  src: string;
  key?: NodeKey;
  width?: number | 'inherit';
  height?: number | 'inherit';
  altText: string;
  attachmentId?: string;
}

export type SerializedImageNode = Spread<
  {
    src: string;
    width?: number;
    height?: number;
    altText: string;
    attachmentId?: string;
  },
  SerializedLexicalNode
>;

function convertImageElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const attachmentId = domNode.getAttribute('data-lexical-image-attachment-id');

    const node = $createImageNode({
      src: domNode.src,
      width: domNode.width,
      height: domNode.height,
      altText: domNode.alt,
      attachmentId: attachmentId ?? undefined,
    });

    return { node };
  }

  return null;
}

export function $createImageNode(payload: IImagePayload): ImageNode {
  const imageNode = new ImageNode(payload);

  return $applyNodeReplacement(imageNode);
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string;

  __width: number | 'inherit';

  __height: number | 'inherit';

  __altText: string;

  __attachmentId?: string;

  constructor(params: IImageNodeConstructorOptions) {
    const { key, src, width, height, altText, attachmentId } = params;

    super(key);

    this.__src = src;
    this.__width = width || 'inherit';
    this.__height = height || 'inherit';
    this.__altText = altText;
    this.__attachmentId = attachmentId;
  }

  public getSrc() {
    return this.__src;
  }

  static getType(): string {
    return IMAGE_BLOCK_TYPE;
  }

  public getAltText() {
    return this.__altText;
  }

  public getAttachmentId() {
    return this.__attachmentId;
  }

  public setSrc(src: string): void {
    const writable = this.getWritable();

    writable.__src = src;
  }

  public setWidthAndHeight(width: 'inherit' | number, height: 'inherit' | number): void {
    const writable = this.getWritable();

    writable.__width = width;
    writable.__height = height;
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode({
      key: node.__key,
      src: node.__src,
      width: node.__width,
      height: node.__height,
      altText: node.__altText,
      attachmentId: node.__attachmentId,
    });
  }

  public decorate(): JSX.Element {
    return (
      <ImageComponent
        src={this.__src}
        width={this.__width}
        height={this.__height}
        altText={this.__altText}
        nodeKey={this.getKey()}
        attachmentId={this.__attachmentId}
      />
    );
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        priority: 0,
        conversion: convertImageElement,
      }),
    };
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const node = $createImageNode(serializedNode);

    return node;
  }

  public exportDOM(): DOMExportOutput {
    const element = document.createElement('img');

    element.setAttribute('src', this.__src);
    element.setAttribute('alt', this.__altText);
    element.setAttribute('width', this.__width.toString());
    element.setAttribute('height', this.__height.toString());

    if (this.__attachmentId) {
      element.setAttribute('data-lexical-image-attachment-id', this.__attachmentId);
    }

    return { element };
  }

  public exportJSON(): SerializedImageNode {
    return {
      src: this.__src,
      type: IMAGE_BLOCK_TYPE,
      width: this.__width === 'inherit' ? 0 : this.__width,
      height: this.__height === 'inherit' ? 0 : this.__height,
      version: 1,
      altText: this.__altText,
      attachmentId: this.__attachmentId,
    };
  }

  public createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('span');

    if (config.theme.image) {
      element.className = config.theme.image;
    }

    return element;
  }

  public updateDOM(): false {
    return false;
  }
}
