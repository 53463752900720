import { useMemo } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';

export const useFuseSearch = <T>(list: T[] | ReadonlyArray<T>, searchValue: string, options?: IFuseOptions<T>) => {
  const fuseInstance = useMemo(() => new Fuse(list, options), [list, options]);

  const result = useMemo(() => {
    if (!searchValue) {
      return list;
    }

    return fuseInstance.search(searchValue).map(({ item }) => item);
  }, [list, searchValue]);

  return result;
};
