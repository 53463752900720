/**
 * Create and return existing element with specified ID
 *
 * @param id - Element ID
 */
export const getContainer = (id: string) => {
  let modalRoot = document.getElementById(id);

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', id);
    document.body.appendChild(modalRoot);
  }

  return modalRoot;
};
