import { useMemo } from 'react';
import { RRule } from 'rrule';
import { DateTime, Interval } from 'luxon';
import { isWeekendsDate } from 'shared/helpers/time/is-weekends-date';

import { getRecurringDates, getWeekdayPosition } from '../helpers';

/**
 * Calculate height of a given month to later use it in the calendar
 * This is necessary for react-window and simplebar to work
 * @param month - DateTime of a given month
 * @param firstDayOfWeek - 1 Monday, 0 - Sunday
 */
const getMonthHeight = (month: DateTime, firstDayOfWeek: number) => {
  const startingDay = getWeekdayPosition(month, firstDayOfWeek);
  const daysInMonth = month.daysInMonth ?? 30;

  const rowsCount = Math.ceil((startingDay + daysInMonth - 1) / 7);

  switch (rowsCount) {
    case 4:
      return 202;
    case 5:
      return 238;
    case 6:
      return 274;
    default:
      return 238;
  }
};

interface IParams {
  rrule?: RRule;
  interval: {
    endDate: DateTime;
    startDate: DateTime;
  };
  selectedDate?: DateTime | null;
  firstDayOfWeek: number;
  disabledAfterDate?: DateTime;
  disabledBeforeDate?: DateTime;
}

export const useCalendarGenerator = (params: IParams) => {
  const { rrule, interval, selectedDate, firstDayOfWeek, disabledAfterDate, disabledBeforeDate } = params;

  const months = useMemo(() => {
    const { endDate, startDate } = interval;

    const monthsArray = Interval.fromDateTimes(startDate, endDate)
      .splitBy({ month: 1 })
      .reduce((acc, current) => {
        if (DateTime.isDateTime(current.start)) {
          acc.push(current.start);
        }

        return acc;
      }, [] as DateTime[]);

    const recurringDates = rrule
      ? getRecurringDates({
          rrule,
          endDate,
          startDate: selectedDate ?? startDate,
        })
      : null;

    return monthsArray.map((month) => {
      const height = getMonthHeight(month, firstDayOfWeek);

      const days = Array(month.daysInMonth)
        .fill(null)
        .map((_, index) => {
          const datetime = DateTime.local(month.year, month.month, index + 1);
          const markAsRecurring = recurringDates ? recurringDates.has(datetime.toFormat('ddMMyyyy')) : false;

          return {
            label: index + 1,
            value: datetime,
            isToday: datetime.hasSame(DateTime.now(), 'day'),
            isActive: selectedDate ? datetime.hasSame(selectedDate, 'day') : false,
            isWeekend: isWeekendsDate(datetime),
            markAsRecurring,
            isDisabled:
              (disabledAfterDate && disabledAfterDate.startOf('day') <= datetime.startOf('day')) ||
              (disabledBeforeDate && disabledBeforeDate.startOf('day') >= datetime.startOf('day')),
          };
        });

      return {
        date: month,
        days,
        height,
      };
    });
  }, [rrule, interval, selectedDate, firstDayOfWeek, disabledAfterDate, disabledBeforeDate]);

  return { months };
};
