import * as React from 'react';
function SvgFileZip(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_zip_svg__clip0_4578_26751)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#CC2AA2"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.328 2.328 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#801366"
        />
        <path
          d="M13.581 29.49v1.075H8.047v-1.074h5.534zm-.204-5.648l-5.034 6.723h-.795v-.961l5-6.688h.83v.926zm-.577-.926v1.082H7.604v-1.082H12.8zM16.183 22.916v7.65h-1.308v-7.65h1.308zm-1.406-2.029c0-.212.063-.39.19-.537.13-.146.323-.22.576-.22.248 0 .438.074.57.22a.76.76 0 01.203.537.742.742 0 01-.203.523c-.132.142-.322.212-.57.212-.253 0-.445-.07-.577-.212a.772.772 0 01-.19-.523zM19.284 24.387v9.12h-1.308v-10.59h1.195l.113 1.47zm5.126 2.29v.149a5.81 5.81 0 01-.197 1.548 3.816 3.816 0 01-.577 1.23c-.248.349-.555.62-.92.813-.367.193-.786.29-1.26.29-.482 0-.909-.08-1.28-.24a2.524 2.524 0 01-.941-.7 3.578 3.578 0 01-.62-1.103 6.416 6.416 0 01-.308-1.45v-.791a6.685 6.685 0 01.316-1.52c.155-.448.358-.83.612-1.145.258-.321.57-.564.935-.729.365-.17.787-.254 1.265-.254.479 0 .903.094 1.273.283.37.183.682.447.935.791.253.344.443.757.57 1.238.131.476.197 1.006.197 1.59zm-1.308.149v-.149c0-.382-.04-.74-.12-1.074a2.724 2.724 0 00-.372-.891 1.78 1.78 0 00-.633-.608 1.793 1.793 0 00-.921-.226 2.02 2.02 0 00-.858.17 1.857 1.857 0 00-.612.459 2.441 2.441 0 00-.415.65c-.103.24-.18.49-.232.75v1.83c.094.33.225.642.394.934.169.287.394.52.675.7.281.174.635.261 1.062.261.351 0 .654-.073.907-.219.258-.15.469-.356.633-.615.168-.26.293-.556.372-.89.08-.34.12-.7.12-1.082z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_zip_svg__clip0_4578_26751">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileZip;
