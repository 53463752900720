import { ElementTransformer } from '@lexical/markdown';
import { $createParagraphNode, ParagraphNode } from 'lexical';

export const LINE_BREAK_TRANSFORMER: ElementTransformer = {
  type: 'element',
  regExp: /^$/,
  dependencies: [ParagraphNode],
  export: () => {
    return null;
  },
  replace: (textNode, nodes, _, isImport) => {
    if (isImport && nodes.length === 1) {
      nodes[0].replace($createParagraphNode());
    }
  },
};
