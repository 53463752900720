import * as React from 'react';
function SvgExit2Sm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.262 5.542v-.7A2.764 2.764 0 008.498 2.08H4.842a2.764 2.764 0 00-2.763 2.763v8.348a2.764 2.764 0 002.763 2.764h3.664a2.756 2.756 0 002.756-2.756v-.707M16.357 9.016h-9.03M14.16 6.83l2.197 2.186-2.196 2.187"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgExit2Sm;
