import { useEffect, useMemo } from 'react';
import { getRandomNumber } from 'shared/helpers/number';

import { EventType, HandlerType, EventContext } from '../interfaces';

import { useEventSubscription } from './use-event-subscription';

interface IOptions {
  skip?: boolean;
  eventType: EventType | EventType[];
  priority?: number;
}

export const useEvent = <T>(options: IOptions, handler: HandlerType<T>, context?: EventContext) => {
  const { skip = false, eventType, priority = 1 } = options;

  const subscriptionId = useMemo(() => {
    return `subscription-${getRandomNumber(1, 10000000)}`;
  }, []);

  const { subscribe, unsubscribe } = useEventSubscription();

  useEffect(() => {
    if (skip) {
      return;
    }

    subscribe<T>(subscriptionId, {
      context,
      priority,
      eventType,
      handler,
    });

    return () => {
      unsubscribe(subscriptionId);
    };
  }, [skip, context, handler]);
};
