import React, { useEffect, useRef, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { differenceInMonths } from 'shared/helpers/time/datetime-helpers';
import { SimpleBar } from 'shared/components/simplebar/SimpleBar';

import { useDatePicker } from '../../providers';
import { useCalendarGenerator } from '../../hooks';

import { MonthItem } from './month-item';

const StyledSimpleBar = styled(SimpleBar)`
  height: 404px;
`;

const StyledList = styled(List)`
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

export const InfiniteCalendar = () => {
  const { value, rrule, firstDayOfWeek, disabledAfterDate, disabledBeforeDate, onChange } = useDatePicker();

  const listRef = useRef<List>(null);

  const interval = useMemo(() => {
    const nowDate = DateTime.now();

    return {
      endDate: nowDate.set({ day: 1 }).plus({ year: 5 }).toLocal(),
      startDate: nowDate.set({ day: 1 }).minus({ year: 1 }).toLocal(),
    };
  }, []);

  const { months } = useCalendarGenerator({
    rrule,
    interval,
    selectedDate: value,
    firstDayOfWeek,
    disabledAfterDate,
    disabledBeforeDate,
  });

  const _getItemHeightForIndex = (index: number) => {
    return months[index]?.height;
  };

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    const scrollToIndex = differenceInMonths(interval.startDate, value ?? DateTime.now(), true);

    listRef.current.scrollToItem(scrollToIndex, 'start');
  }, []);

  return (
    <StyledSimpleBar>
      {({ scrollableNodeRef, contentNodeRef }: any) => (
        <StyledList
          ref={listRef}
          width="100%"
          height={404}
          outerRef={scrollableNodeRef}
          innerRef={contentNodeRef}
          itemSize={_getItemHeightForIndex}
          itemData={{
            items: months,
            selectedDate: value,
            firstDayOfWeek,
            disabledAfterDate,
            disabledBeforeDate,
            onChange,
          }}
          itemCount={months.length}
        >
          {/* @ts-ignore */}
          {MonthItem}
        </StyledList>
      )}
    </StyledSimpleBar>
  );
};
