interface ISessionData {
  gaSessionId: string;
  gaSessionNumber: string;
}

export const getGASessionData = (callback: (data: ISessionData) => void) => {
  let attempt = 0;

  const findSessionData = () => {
    if (attempt > 5) {
      return;
    }

    const match = document.cookie.match(/_ga_P3BHPEQ8SY=GS\d\.\d\.(.+?)(?:;|$)/);
    const parts = match?.[1].split('.');

    if (!parts?.length) {
      // Cookie not yet available; wait a bit and try again.
      attempt += 1;
      setTimeout(findSessionData, 200);

      return;
    }

    callback({
      gaSessionId: parts[0],
      gaSessionNumber: parts[1],
    });
  };

  findSessionData();
};
