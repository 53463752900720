import * as React from 'react';
function SvgRepeatSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.983 4.299h11.534c1.383 0 2.5 1.116 2.5 2.5v2.766"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.617 1.666L2.983 4.299l2.634 2.634M17.017 15.698H5.483a2.497 2.497 0 01-2.5-2.5v-2.766"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.383 18.333l2.634-2.633-2.634-2.634"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgRepeatSm;
