import React from 'react';
import { Redirect, Route, RouteProps, RouteComponentProps, generatePath } from 'react-router-dom';
import { isAuthenticated } from 'shared/helpers/auth/is-authenticated';

/**
 * Allow user to see content of the route only if he is authenticated otherways redirect
 * user to login page.
 */

export interface IPrivateRouteProps extends RouteProps {
  checkAccess?: () => boolean;
  redirectPattern?: string;
}

export const PrivateRoute = (props: IPrivateRouteProps) => {
  const { children, component: Component, redirectPattern = '/', checkAccess, ...otherProps } = props;

  const renderIfAllowed = (routeProps: RouteComponentProps): React.ReactNode => {
    if (!isAuthenticated()) {
      return (
        <Redirect
          to={{
            state: { from: routeProps.location },
            pathname: '/auth/start',
          }}
        />
      );
    }

    if (checkAccess && !checkAccess()) {
      return (
        <Redirect
          to={{
            state: { from: routeProps.location },
            pathname: generatePath(redirectPattern, routeProps.match.params),
          }}
        />
      );
    }

    if (Component) {
      return <Component {...routeProps} />;
    } else {
      return children;
    }
  };

  // @ts-ignore
  return <Route {...otherProps} render={renderIfAllowed} />;
};
