import { useCallback, useState } from 'react';

/**
 * Manage visibility state of component
 */
export const useVisibilityState = (visibility: boolean, disabled = false) => {
  const [isVisible, setIsVisible] = useState<boolean>(disabled ? false : visibility);

  const showComponent = useCallback(() => {
    if (disabled) return;

    setIsVisible(true);
  }, [disabled]);

  const hideComponent = useCallback(() => {
    if (disabled) return;

    setIsVisible(false);
  }, [disabled]);

  const toggleVisible = useCallback(() => {
    if (disabled) return;

    setIsVisible((prevState) => !prevState);
  }, [disabled]);

  return { isVisible, setIsVisible, showComponent, hideComponent, toggleVisible };
};
