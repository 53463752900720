import * as React from 'react';
function SvgEditorLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#editor_link_svg__clip0_1520_22949)"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.786 5.832h2.5a4.167 4.167 0 110 8.333h-2.5m-5 0h-2.5a4.167 4.167 0 010-8.333h2.5M6.952 10h6.667" />
      </g>
      <defs>
        <clipPath id="editor_link_svg__clip0_1520_22949">
          <path fill="#fff" transform="translate(.286)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgEditorLink;
