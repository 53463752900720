import MarkdownIt from 'markdown-it';
// eslint-disable-next-line import/no-unresolved
import Renderer from 'markdown-it/lib/renderer';

export const linkMarkdownItPlugin: MarkdownIt.PluginSimple = (md) => {
  // Remember the old renderer if overridden, or proxy to the default renderer.
  const defaultRender: Renderer.RenderRule =
    md.renderer.rules.link_open ||
    function (tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    };

  md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    // Add a new `target` attribute, or replace the value of the existing one.
    tokens[idx].attrSet('target', '_blank');

    // Pass the token to the default renderer.
    return defaultRender(tokens, idx, options, env, self);
  } as Renderer.RenderRule;
};
