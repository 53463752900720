import * as React from 'react';
function SvgUpdatesSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.642 6.2L10 10.46l7.308-4.234M10 18.009V10.45"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.275 2.067l-4.45 2.466c-1.008.559-1.833 1.959-1.833 3.109v4.708c0 1.15.825 2.55 1.833 3.108l4.45 2.475c.95.525 2.508.525 3.458 0l4.45-2.475c1.009-.558 1.834-1.958 1.834-3.108V7.642c0-1.15-.825-2.55-1.834-3.109l-4.45-2.475c-.958-.525-2.508-.525-3.458.009z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgUpdatesSm;
