import React, { FC, useRef, useMemo, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { isNumber, isNull, findLastIndex } from 'lodash';
import { filter, tap } from 'rxjs/operators';
import { Logger } from 'core/logger/logger';

import { useObservable } from './hooks/use-observable.hook';
import { EventSubscriptionContext } from './context';
import { IEvent, ISubscription, ISubscriptionData } from './interfaces';

export const EventSubscriptionProvider: FC = (props) => {
  const { children } = props;

  const client = useApolloClient();
  const observable = useObservable();

  const subscriptionsRef = useRef<ISubscription<any>[]>([]);

  const createSubscription = useCallback(<T,>(data: ISubscriptionData<T>) => {
    const { eventType, context, handler } = data;

    const subscription = observable
      .pipe(
        filter((event: IEvent<T>) => {
          const currentEventType = event.__metadata.type;

          if (Array.isArray(eventType)) {
            return eventType.some((type) => type === currentEventType);
          }

          return eventType === currentEventType;
        }),
        tap(
          (event: IEvent<T>) =>
            event.__metadata.optimisticTransaction &&
            Logger.subject('EVENTS').info(
              `LOCAL EVENT:`,
              event.__metadata.type,
              `| optimisticTransaction: ${event.__metadata.optimisticTransaction}`,
              event
            )
        )
      )
      .subscribe({
        next: (event: IEvent<T>) => {
          handler(event, { client, ...context });
        },
      });

    return subscription;
  }, []);

  const subscribe = useCallback(<T,>(id: string, data: ISubscriptionData<T>) => {
    const { priority } = data;
    const subscriptions = subscriptionsRef.current;

    const lastIndex = subscriptions.length ? subscriptions.length - 1 : null;
    const lastPriority = isNumber(lastIndex) ? subscriptions[lastIndex].data.priority : null;

    if (isNull(lastPriority) || priority >= lastPriority) {
      const subscriptionIndex = findLastIndex(subscriptions, (subscription) => subscription.id === id);

      if (subscriptionIndex !== -1) {
        const currentSubscription = subscriptions[subscriptionIndex];

        currentSubscription.subscription.unsubscribe();

        subscriptions.splice(subscriptionIndex, 1);
      }

      subscriptions.push({
        id,
        data,
        subscription: createSubscription(data),
      });

      return;
    }

    const lastSubscriptionWithSamePriorityIndex = findLastIndex(
      subscriptions,
      (subscription) => subscription.data.priority === priority
    );

    const nextIndex = lastSubscriptionWithSamePriorityIndex + 1;

    const newSubscriptions = subscriptions.slice(0, nextIndex);

    newSubscriptions.push({
      id,
      data,
      subscription: createSubscription<T>(data),
    });

    for (let i = nextIndex; i < subscriptions.length; i++) {
      const currentItem = subscriptions[i];

      currentItem.subscription.unsubscribe();

      newSubscriptions.push({
        id: currentItem.id,
        data: currentItem.data,
        subscription: createSubscription<T>(currentItem.data),
      });
    }

    subscriptionsRef.current = newSubscriptions;
  }, []);

  const unsubscribe = useCallback((id: string) => {
    const subscriptions = subscriptionsRef.current;
    const subscriptionIndex = findLastIndex(subscriptions, (subscription) => subscription.id === id);

    if (subscriptionIndex === -1) {
      return;
    }

    const currentSubscription = subscriptions[subscriptionIndex];

    currentSubscription.subscription.unsubscribe();

    subscriptionsRef.current = subscriptions.filter((subscription) => subscription.id !== id);
  }, []);

  const values = useMemo(
    () => ({
      subscribe,
      unsubscribe,
    }),
    [subscribe, unsubscribe]
  );

  return <EventSubscriptionContext.Provider value={values}>{children}</EventSubscriptionContext.Provider>;
};
