import * as React from 'react';
function SvgFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.332 14V2.6S3.915 2 5.665 2c1.75 0 2.917 1.2 4.667 1.2s2.333-.6 2.333-.6v7.2s-.583.6-2.333.6c-1.75 0-2.917-1.2-4.667-1.2s-2.333.6-2.333.6"
        stroke="#222"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgFlag;
