import * as React from 'react';
function SvgImageSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M13.586 2.293h-7.21c-2.51 0-4.085 1.778-4.085 4.295v6.788c0 2.517 1.568 4.295 4.085 4.295h7.205c2.521 0 4.088-1.778 4.088-4.295V6.588c.003-2.517-1.564-4.295-4.083-4.295z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M8.92 7.323a1.538 1.538 0 11-3.076.002 1.538 1.538 0 013.075-.002z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.672 12.461c-.769-.792-2.248-2.39-3.856-2.39-1.61 0-2.537 3.527-4.084 3.527-1.548 0-2.954-1.595-4.194-.573-1.24 1.022-2.413 3.111-2.413 3.111"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgImageSm;
