import * as React from 'react';
function SvgFileAvi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_avi_svg__clip0_4578_26736)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#FE3277"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.328 2.328 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#AE1C4D"
        />
        <path
          d="M12.01 29.258V25.32c0-.302-.06-.563-.182-.785a1.2 1.2 0 00-.534-.523c-.24-.122-.535-.184-.886-.184-.328 0-.617.057-.865.17a1.507 1.507 0 00-.577.445.98.98 0 00-.204.594h-1.3c0-.273.07-.544.21-.813.141-.269.343-.511.605-.728.268-.222.586-.396.957-.523a3.75 3.75 0 011.251-.198c.553 0 1.04.094 1.463.283.426.188.759.473.998.855.244.377.366.85.366 1.421v3.563c0 .255.02.526.063.813.047.287.115.535.204.742v.113h-1.357a2.455 2.455 0 01-.155-.6 4.873 4.873 0 01-.056-.707zm.226-3.33l.014.919h-1.315c-.37 0-.7.03-.991.092-.29.056-.535.143-.732.261a1.24 1.24 0 00-.45.446c-.103.174-.154.38-.154.615 0 .24.054.46.162.657.107.198.269.356.485.474.22.113.49.17.808.17.399 0 .75-.085 1.055-.255.305-.17.546-.377.724-.622.183-.245.281-.483.295-.714l.556.63a1.904 1.904 0 01-.267.657 3.142 3.142 0 01-1.442 1.237 2.78 2.78 0 01-1.11.212c-.516 0-.969-.102-1.358-.304a2.325 2.325 0 01-.9-.813 2.16 2.16 0 01-.316-1.152c0-.41.08-.771.239-1.082.16-.316.39-.578.69-.785.3-.212.66-.372 1.082-.48a5.683 5.683 0 011.413-.163h1.512zM17.127 29.385l2.08-6.469h1.33l-2.735 7.65h-.872l.197-1.181zm-1.737-6.469l2.144 6.504.148 1.145h-.872l-2.756-7.649h1.336zM22.77 22.916v7.65h-1.308v-7.65h1.308zm-1.407-2.029c0-.212.064-.39.19-.537.131-.146.324-.22.577-.22.248 0 .438.074.57.22a.76.76 0 01.203.537.742.742 0 01-.204.523c-.131.142-.32.212-.57.212-.252 0-.445-.07-.576-.212a.772.772 0 01-.19-.523z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_avi_svg__clip0_4578_26736">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileAvi;
