import * as React from 'react';
function SvgFileRtf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_rtf_svg__clip0_4576_26655)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#52CAE3"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.329 2.329 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#258092"
        />
        <path
          d="M10.292 24.117v6.448h-1.3v-7.65h1.265l.035 1.202zm2.377-1.244l-.007 1.216a1.874 1.874 0 00-.31-.042 3.278 3.278 0 00-.323-.014c-.3 0-.565.047-.795.141-.23.094-.424.226-.583.396-.16.17-.286.372-.38.608-.089.23-.148.485-.176.763l-.365.212c0-.461.044-.895.133-1.3a3.55 3.55 0 01.43-1.075c.191-.316.435-.56.73-.735.3-.18.657-.269 1.07-.269.093 0 .2.012.323.036.121.018.206.04.253.063zM16.976 22.916v1.003h-4.113v-1.003h4.113zm-2.721-1.86h1.3v7.614c0 .26.04.455.12.587.08.132.183.22.31.261.126.043.262.064.407.064.108 0 .22-.01.338-.028.122-.024.213-.043.274-.057l.007 1.068c-.103.033-.24.063-.408.092-.164.033-.363.05-.598.05-.318 0-.611-.065-.878-.192a1.417 1.417 0 01-.64-.636c-.155-.302-.232-.707-.232-1.216v-7.607zM20.01 30.565h-1.3V22.11c0-.552.098-1.016.295-1.393.201-.382.49-.67.865-.862.375-.198.82-.297 1.335-.297a3.596 3.596 0 01.9.113l-.07 1.067a2.158 2.158 0 00-.337-.05 4.752 4.752 0 00-.366-.013c-.277 0-.515.056-.717.17a1.063 1.063 0 00-.45.48c-.103.212-.155.474-.155.785v8.455zm1.617-7.65v1.005h-4.12v-1.004h4.12z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_rtf_svg__clip0_4576_26655">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileRtf;
