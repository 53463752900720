import { createGlobalStyle } from 'styled-components';
import { FOLLOWERS_POPOVER_NAME } from 'widgets/chat/model';
import { APP_NOTIFICATIONS_POPOVER_NAME } from 'widgets/headerbar/model';
import { editorStyles } from 'features/editor/ui/editor.styles';

export const GlobalStyle = createGlobalStyle`
  ${editorStyles}

  * {
    margin: 0;
    padding: 0;
  }

  html {
    height: 100%;
    text-size-adjust: none;
  }
  
  html, body {
    font-family: 'Inter', sans-serif;
  }
  
  body {
    color: #17191C;
    height: 100%;
    min-height: 100vh;
    min-height: fill-available;
  }

  button {
    border: none;
    cursor: pointer;
    outline: none;
    background: none;
  }
  
  input, button, textarea, select {
    font-family: inherit;
  }


  #root  {
    height: 1px;
    min-height: 100%;
  } 

  
  //couldn’t redefine the z-index in the component itself
  & [data-popover-name=${FOLLOWERS_POPOVER_NAME}],
  & [data-popover-name=${APP_NOTIFICATIONS_POPOVER_NAME}] {
    z-index: 6 !important;
  }
`;
