import * as React from 'react';
function SvgCheckMarkInscribedSquareSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M13.612 2.292H6.388c-2.518 0-4.096 1.782-4.096 4.305v6.806c0 2.523 1.57 4.305 4.096 4.305h7.223c2.526 0 4.098-1.782 4.098-4.305V6.597c0-2.523-1.572-4.305-4.097-4.305z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.975l2.476 2.475 4.95-4.95"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgCheckMarkInscribedSquareSm;
