import React, { forwardRef } from 'react';

import { IIconProps } from '../model';

import { IconContainer } from './styled';

export const Icon = forwardRef<HTMLElement, IIconProps>((props, ref) => {
  const { size = 'md', color = 'gray800', component: IconComponent, containerClassName, ...otherProps } = props;

  return (
    <IconContainer ref={ref} className={containerClassName} $iconSize={size} $iconColor={color}>
      <IconComponent {...otherProps} />
    </IconContainer>
  );
});

Icon.displayName = 'Icon';
