import React from 'react';
import styled from 'styled-components';
import { getBlue300Color } from 'core/theme/helpers';
import { IconButton } from 'shared/components/button';
import { DotsMd } from 'shared/components/icons';

import { IVerticalDotsTriggerProps } from './interfaces';

const StyledIconButton = styled(IconButton)`
  padding: 0;

  &.active {
    color: ${getBlue300Color};
  }
`;

export const VerticalDotsTrigger = (props: IVerticalDotsTriggerProps) => {
  const { isOpen, disabled, className, getRef, onToggle } = props;

  const classes = isOpen ? `active ${className}` : className;

  return (
    <StyledIconButton
      ref={getRef}
      type="button"
      variant="ghost"
      disabled={disabled}
      className={classes}
      onClick={onToggle}
    >
      <DotsMd width={22} height={22} />
    </StyledIconButton>
  );
};
