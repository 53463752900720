import { Subject } from 'rxjs';

import { IEvent } from '../interfaces';
// maybe replace it
const observable = new Subject<IEvent>();

//  Provides stream of events using rxjs that received from server
export const useObservable = () => {
  return observable;
};
