import React, { forwardRef } from 'react';
import { AngleDownSm, AngleDownXs } from 'shared/components/icons';

import { IArrowButtonProps } from '../../model';

import { IconContainer, StyledButton } from './styled';

export const ArrowButton = forwardRef<HTMLButtonElement, IArrowButtonProps>((props, ref) => {
  const {
    open,
    size = 'sm',
    variant = 'primary',
    initialRotate = 0,
    expandedRotate = -180,
    rotateDuration = 300,
    ...otherProps
  } = props;

  const transform = open ? `rotate(${expandedRotate}deg)` : `rotate(${initialRotate}deg)`;

  const _renderIcon = () => {
    switch (size) {
      case 'xs':
        return <AngleDownXs width={16} height={16} />;
      case 'sm':
        return <AngleDownSm width={18} height={18} />;
      case 'md':
        return <AngleDownSm width={22} height={22} />;
      default:
        return <AngleDownSm width={18} height={18} />;
    }
  };

  return (
    <StyledButton ref={ref} $variant={variant} {...otherProps}>
      <IconContainer style={{ transform }} $transformDuration={rotateDuration}>
        {_renderIcon()}
      </IconContainer>
    </StyledButton>
  );
});

ArrowButton.displayName = 'ArrowButton';
