import React from 'react';
import { useField } from 'formik';
import { isUndefined } from 'lodash';
import { FormField as BaseFormField } from 'evergreen-ui';
import styled, { css } from 'styled-components';
import { getGray800Color, getSMFontSize } from 'core/theme/helpers';

import { IFormFieldProps } from './FormField.interfaces';

//@ts-ignore
export const StyledFormFiled = styled(BaseFormField)<IStyledFieldProps>`
  & label {
    color: ${getGray800Color};
    font-size: ${getSMFontSize};
    font-family: inherit;
    line-height: normal;
    font-weight: normal;
    margin-bottom: 5px;
    letter-spacing: 0;
  }

  ${({ $hideLabel }) =>
    $hideLabel &&
    css`
      & label {
        display: none;
      }
    `}
`;

interface IStyledFieldProps {
  $hideLabel: boolean;
}

export const FormField = <T extends Record<string, any>>({
  name,
  hint,
  label,
  width,
  getRef,
  isRequired,
  marginRight,
  description,
  marginBottom = 18,
  component: Component,
  ...otherProps
}: IFormFieldProps & T) => {
  const [field, meta] = useField(name);

  const hideLabel = isUndefined(label);
  const isInvalid = meta.touched && !!meta.error;
  const validationMessage = isInvalid ? meta.error : undefined;

  return (
    <StyledFormFiled
      hint={hint}
      width={width}
      label={label}
      isRequired={isRequired}
      description={description}
      marginRight={marginRight}
      marginBottom={marginBottom}
      validationMessage={validationMessage}
      data-testid={name}
      $hideLabel={hideLabel}
    >
      {/* @ts-ignore */}
      <Component {...field} {...otherProps} width={width} ref={getRef} />
    </StyledFormFiled>
  );
};
