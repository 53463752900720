import React from 'react';
import styled from 'styled-components';
import { TextInput } from 'shared/components/text-input';
import { SearchSm } from 'shared/components/icons';

import { SearchBarContainer } from '../styled';

const StyledTextInput = styled(TextInput)`
  background: transparent;

  & .input-field {
    padding: 10px 10px 10px 36px;
  }

  & .icon-wrapper {
    left: 10px;
  }
`;

interface IProps {
  className?: string;
  searchValue?: string;
  searchPlaceholder?: string;
  onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const SearchBar = (props: IProps) => {
  const { className, searchValue, searchPlaceholder = 'Search...', onSearch, onKeyDown } = props;

  return (
    <SearchBarContainer className={className}>
      <StyledTextInput
        icon={<SearchSm width={18} height={18} />}
        value={searchValue}
        variant="filled"
        autoFocus
        placeholder={searchPlaceholder}
        iconPosition="left"
        onChange={onSearch}
        onKeyDown={onKeyDown}
      />
    </SearchBarContainer>
  );
};
