import React, { forwardRef, memo, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { getSMFontSize, getBlackColor, getSMBorderRadius } from 'core/theme/helpers';

import { IDropdownItemProps } from '../../model';

const ItemContainer = styled.div<IItemContainerProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 7px 10px;
  min-height: 40px;
  background: ${({ theme, $active }) => ($active ? theme.colors.blue100 : 'transparent')};
  box-sizing: border-box;
  transition: all 300ms ease-out;
  border-radius: ${getSMBorderRadius};

  color: ${getBlackColor};
  font-size: ${getSMFontSize};

  &:hover,
  &:focus-visible {
    background: ${({ theme, $active }) => ($active ? theme.colors.blue100 : theme.colors.gray200)};
  }

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      color: ${theme.colors.gray800};
      pointer-events: none;
    `}
`;

const IconContainer = styled.i`
  display: inline-flex;
  font-style: normal;

  &.icon-right {
    margin-left: auto;
  }
`;

const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface IItemContainerProps {
  $active?: boolean;
  $disabled?: boolean;
}

export const DropdownItem = memo(
  forwardRef<HTMLDivElement, IDropdownItemProps<any>>((props, ref) => {
    const { icon, label, value, active, disabled, rightIcon, children, onClick, ...otherProps } = props;

    const _handleClick = (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }

      onClick?.(event, value);
    };

    return (
      <ItemContainer
        ref={ref}
        tabIndex={0}
        // for dropdown scroll logic
        data-active={active}
        $active={active}
        $disabled={disabled}
        onClick={_handleClick}
        {...otherProps}
      >
        {icon && <IconContainer className="icon-left">{icon}</IconContainer>}

        {children || <Label>{label}</Label>}

        {rightIcon && <IconContainer className="icon-right">{rightIcon}</IconContainer>}
      </ItemContainer>
    );
  })
);

DropdownItem.displayName = 'DropdownItem';
