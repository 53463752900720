import React, { useState, useMemo, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { isPlainObject, isString, isUndefined } from 'lodash';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Notification } from 'features/notification';
import { getContainer } from 'shared/helpers/dom';
import { getRandomNumber } from 'shared/helpers/number';

import { NotificationContext } from './context';
import { EAppearanceType, INotificationData, IAddNotificationFunction, IAddNotificationOptions } from './interfaces';

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  left: 8px;
  bottom: 36px;
  z-index: 25;
  max-height: calc(100vh - 36px);
`;

interface IProps {
  children?: React.ReactNode;
}

export const NotificationProvider = (props: IProps) => {
  const { children } = props;

  const timersRef = useRef<Map<string, NodeJS.Timeout | number>>(new Map());

  const [notifications, setNotifications] = useState<INotificationData[]>([]);

  const container = getContainer('notifications-root');

  const removeTimer = useCallback((notificationId: string) => {
    const prevTimerId = timersRef.current.get(notificationId);

    if (isUndefined(prevTimerId)) {
      return;
    }

    clearTimeout(prevTimerId as number);

    timersRef.current.delete(notificationId);
  }, []);

  const removeNotification = useCallback((notificationId: string) => {
    removeTimer(notificationId);

    setNotifications((notifications) => notifications.filter((notification) => notification.id !== notificationId));
  }, []);

  const addTimer = useCallback((notificationId: string) => {
    removeTimer(notificationId);

    const newTimerId = setTimeout(() => {
      removeNotification(notificationId);
    }, 5000);

    timersRef.current.set(notificationId, newTimerId);
  }, []);

  const addNotification = useCallback<IAddNotificationFunction>((firstArg, secondArg = EAppearanceType.success) => {
    const notificationId = `notification-${getRandomNumber(1, 10000000)}`;

    const notification: INotificationData = {
      id: notificationId,
      message: '',
      appearance: EAppearanceType.success,
    };

    if (isString(firstArg)) {
      notification.message = firstArg;
      notification.appearance = secondArg as EAppearanceType;
    }

    if (isPlainObject(firstArg)) {
      const options = firstArg as IAddNotificationOptions;

      notification.message = options.message;
      notification.content = options.content;
      notification.appearance = options.appearance || EAppearanceType.success;
    }

    addTimer(notificationId);

    setNotifications((prevState) => {
      return prevState.concat(notification);
    });
  }, []);

  const values = useMemo(
    () => ({
      addNotification,
      removeNotification,
    }),
    []
  );

  return (
    <NotificationContext.Provider value={values}>
      {children}

      {createPortal(
        <Container data-testid="notifications-toaster">
          <AnimatePresence>
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                title={notification.message}
                content={notification.content}
                appearance={notification.appearance}
                onDismiss={() => removeNotification(notification.id)}
              />
            ))}
          </AnimatePresence>
        </Container>,
        container
      )}
    </NotificationContext.Provider>
  );
};
