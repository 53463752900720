import { User } from 'core/graphql/fragments/impl/user';
import { ChatMessage } from 'core/graphql/fragments/impl/chat-message';
import { TaskFollower } from 'core/graphql/fragments/impl/task';
import { ScheduledEventFollower } from 'core/graphql/fragments/impl/scheduled-event';

export enum MessageAction {
  EDIT,
  REPLY,
}

export type FollowerType = TaskFollower | ScheduledEventFollower;

export interface IHeaderProps {
  children?: React.ReactNode;
}

export interface IGroupedMessagesByUser {
  user: User;
  items: ChatMessage[];
}

export type GroupedByDateItemType = ChatMessage | IGroupedMessagesByUser;

export interface IGroupedMessagesByDate {
  date: string;
  items: GroupedByDateItemType[];
}
