import packageJSON from '../../../package.json';

import { IConfig } from './interfaces/config.interface';

const config: IConfig = {
  events: {
    local: true,
    server: true,
  },
  graphql: {
    url: process?.env.REACT_APP_GRAPHQL_URL || 'http://localhost:8080',
    wsurl: process?.env.REACT_APP_GRAPHQL_WS_URL || 'ws://localhost:8080',
  },
  constants: {
    isVerified: 'BORDIO_VERIFIED',
    authTokenStorageKey: 'AUTH_TOKEN',

    ignoredTimezone: 'IGNORED_TIMEZONE',
    broadcastMessage: 'BROADCAST_MESSAGE',

    pushNotificationsId: 'PUSH_NOTIFICATIONS_ID',
    pushNotificationsMessage: 'PUSH_NOTIFICATIONS_MESSAGE',

    toolsExpanded: 'TOOLS_EXPANDED',

    navigationExpanded: 'NAVIGATION_EXPANDED',

    waitinglistExpanded: 'WAITINGLIST_EXPANDED',

    chatInDetailsExpanded: 'CHAT_IN_DETAILS_EXPANDED',

    folderCollapse: 'FOLDER_COLLAPSE',

    tasksView: 'TASKS_VIEW',

    tableViewBoardUserFilter: 'TABLE_VIEW_BOARD_USER_FILTER',
    tableViewBoardGroupByFilter: 'TABLE_VIEW_BOARD_GROUP_BY_FILTER',

    kanbanBoardCollapse: 'KANBAN_BOARD_COLLAPSE',
    kanbanBoardUserFilter: 'KANBAN_BOARD_USER_FILTER',
    kanbanBoardGroupByFilter: 'KANBAN_BOARD_GROUP_BY_FILTER',

    scheduleBoardCollapse: 'SCHEDULE_BOARD_COLLAPSE',
    scheduleBoardUserFilter: 'SCHEDULE_BOARD_USER_FILTER',
  },
  appVersion: packageJSON.version,
};

export default config;
