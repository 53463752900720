import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getBlue700Color } from 'core/theme/helpers';
import { Bordio } from 'shared/components/icons';
import { ProcessBar } from 'shared/components/process-bar';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getBlue700Color};
`;

const CenterGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledProgressBar = styled(ProcessBar)`
  width: 263px;
  margin-top: 35px;
`;

interface IProps {
  withProgress?: boolean;
}

export const Loader: FC<IProps> = (props) => {
  const { withProgress = false } = props;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!withProgress) {
      return;
    }

    const interval = setInterval(() => {
      setProgress((prevState) => {
        if (prevState < 99) {
          return prevState + 5;
        }

        clearInterval(interval);

        return prevState;
      });
    }, 30);

    return () => clearInterval(interval);
  }, [withProgress]);

  return (
    <Overlay>
      <CenterGroup>
        <Bordio height={60} width={243} />

        {withProgress && <StyledProgressBar progress={progress} />}
      </CenterGroup>
    </Overlay>
  );
};
