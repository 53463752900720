import { $getSelection, $isNodeSelection, LexicalEditor } from 'lexical';
import { calculateProportionalHeight } from 'shared/helpers/aspect';

import { $isImageNode, ImageNode, IImagePayload } from '../../nodes/image-node';

export const $getImageSizes = (element: HTMLImageElement, editor: LexicalEditor) => {
  const editorRootElement = editor.getRootElement();

  if (!editorRootElement) {
    return {
      width: 'inherit' as const,
      height: 'inherit' as const,
    };
  }

  const maxWidthContainer = editorRootElement.getBoundingClientRect().width;

  const height = calculateProportionalHeight({
    newWidth: maxWidthContainer,
    originalWidth: element.width,
    originalHeight: element.height,
  });

  return {
    width: Math.min(element.width, maxWidthContainer),
    height: Math.min(element.height, height),
  };
};

export const $getImageNodeInSelection = (): ImageNode | null => {
  const selection = $getSelection();

  if (!$isNodeSelection(selection)) {
    return null;
  }

  const [node] = selection.getNodes();

  return $isImageNode(node) ? node : null;
};

export const $getImageNodeByAttachmentId = (id: string, editor: LexicalEditor): ImageNode | null => {
  let result: ImageNode | null = null;

  const editorState = editor.getEditorState();

  editorState._nodeMap.forEach((node) => {
    if (!($isImageNode(node) && node.getAttachmentId() === id)) {
      return;
    }

    result = node;
  });

  return result;
};

export const canDropImage = (event: DragEvent): boolean => {
  const target = event.target;

  return !!(target && target instanceof HTMLElement && target.parentElement);
};

export const getDragImageData = (event: DragEvent): IImagePayload | null => {
  const dragData = event.dataTransfer?.getData('application/x-lexical-drag');

  if (!dragData) {
    return null;
  }

  const { type, data } = JSON.parse(dragData);

  if (type !== 'image') {
    return null;
  }

  return data;
};

export const getDragSelection = (event: DragEvent): Range | null | undefined => {
  let range;
  const target = event.target as null | Element | Document;
  const targetWindow =
    target == null
      ? null
      : target.nodeType === 9
      ? (target as Document).defaultView
      : (target as Element).ownerDocument.defaultView;

  const domSelection = (targetWindow || window).getSelection();

  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY);
    // @ts-ignore
  } else if (event.rangeParent && domSelection !== null) {
    // @ts-ignore
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
    range = domSelection.getRangeAt(0);
  } else {
    throw Error(`Cannot get the selection when dragging`);
  }

  return range;
};
