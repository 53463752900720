import * as React from 'react';
function SvgUsersSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M7.993 12.674c3.074 0 5.701.466 5.701 2.327 0 1.86-2.61 2.34-5.701 2.34-3.075 0-5.702-.462-5.702-2.324 0-1.861 2.61-2.343 5.702-2.343zM7.993 10.017a3.655 3.655 0 113.654-3.654 3.641 3.641 0 01-3.628 3.654h-.026z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.736 9.068A2.751 2.751 0 0016.1 6.35a2.75 2.75 0 00-2.302-2.713M15.496 12.277c1.293.193 2.195.646 2.195 1.58 0 .642-.425 1.059-1.112 1.32"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgUsersSm;
