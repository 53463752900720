import * as React from 'react';
function SvgMessageSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.33 15C13.012 15 16 12.09 16 8.5S13.013 2 9.33 2C5.644 2 2.657 4.91 2.657 8.5c0 1.004.234 1.954.651 2.803.143.292.194.623.108.935L2.182 14c-.477.5 0 1 1.235.658l2.076-.397c.32-.084.66-.035.96.105A6.79 6.79 0 009.328 15z"
        stroke="#8C939F"
        strokeWidth={1.2}
      />
    </svg>
  );
}
export default SvgMessageSm;
