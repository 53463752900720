import * as React from 'react';
function SvgCompleteSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 18.333c4.583 0 8.333-3.75 8.333-8.334 0-4.583-3.75-8.333-8.333-8.333s-8.333 3.75-8.333 8.333c0 4.584 3.75 8.334 8.333 8.334z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.556 10l2.96 3.333 5.929-6.667"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgCompleteSm;
