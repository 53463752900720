import { isString } from 'lodash';

interface IOptions {
  lastName: string;
  firstName: string;
}

export const getUserInitials = (nameOrOptions: string | IOptions): string => {
  let lastName = '';
  let firstName = '';

  if (isString(nameOrOptions)) {
    const splittedName = nameOrOptions.split(' ');

    firstName = splittedName?.[0] ?? '';
    lastName = splittedName?.[1] ?? '';
  } else {
    firstName = nameOrOptions.firstName;
    lastName = nameOrOptions.lastName;
  }

  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

  return initials.toUpperCase();
};
