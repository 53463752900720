import styled, { css } from 'styled-components';
import { getGray100Color, getGray400Color, getLGBorderRadius, getSMFontSize } from 'core/theme/helpers';

interface IStyledLabelProps {
  $isDisabled?: boolean;
}

export const Label = styled.label<IStyledLabelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  gap: 6px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  transition: all 300ms ease-out;
  border-radius: ${getLGBorderRadius};

  &:not(.active):hover {
    background: ${getGray400Color};
  }

  &.active {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 1px 2px 0px rgba(140, 147, 159, 0.2);
  }

  ${({ theme, $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${theme.colors.gray900};
      cursor: default;
      pointer-events: none;
    `}
`;

export const ElementsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 3px;
  background: ${getGray100Color};
  border-radius: 50px;

  font-size: ${getSMFontSize};
`;

export const HiddenInput = styled.input`
  display: none;
`;
