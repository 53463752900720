import React, { memo } from 'react';
import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';
import { Typography } from 'shared/components/typography';
import { FIRST_DAY_OF_WEEK_MONDAY } from 'shared/constants/settings';
import { mondayStartDays, sundayStartDays } from 'shared/constants/calendar';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 20px 21px 18px 21px;
  box-sizing: border-box;
  border-bottom: 1px solid ${getGray400Color};
`;

const DayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-height: 20px;
`;

interface IProps {
  firstDayOfWeek: number;
}

export const Header = memo<IProps>((props) => {
  const { firstDayOfWeek } = props;

  const days = firstDayOfWeek === FIRST_DAY_OF_WEEK_MONDAY ? mondayStartDays : sundayStartDays;

  return (
    <HeaderContainer>
      {days.map((day, index) => (
        <DayContainer key={index}>
          <Typography size="xxs" color="secondary">
            {day}
          </Typography>
        </DayContainer>
      ))}
    </HeaderContainer>
  );
});

Header.displayName = 'Header';
