import * as React from 'react';
function SvgStarFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.466 6.468a.459.459 0 00-.133.404l.37 2.05a.45.45 0 01-.187.45.459.459 0 01-.488.033l-1.845-.962a.471.471 0 00-.208-.055h-.113a.338.338 0 00-.113.038l-1.846.967c-.09.046-.194.062-.295.046a.463.463 0 01-.371-.53l.37-2.05a.466.466 0 00-.132-.408L.97 4.993a.45.45 0 01-.112-.47.468.468 0 01.37-.313l2.071-.3a.463.463 0 00.367-.255l.912-1.87a.434.434 0 01.084-.113l.037-.029a.28.28 0 01.067-.054l.046-.017.07-.029h.176c.157.016.295.11.367.25l.924 1.862c.067.137.197.231.346.254l2.071.3a.472.472 0 01.38.313.453.453 0 01-.122.471L7.466 6.468z"
        fill="#0094FF"
      />
    </svg>
  );
}
export default SvgStarFilled;
