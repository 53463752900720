import * as React from 'react';
function SvgCalendarWithDateLg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.668 2.164v3.25M17.332 2.164v3.25M3.793 9.844H22.21M22.75 9.206v9.208c0 3.25-1.625 5.417-5.417 5.417H8.667c-3.792 0-5.417-2.167-5.417-5.417V9.206c0-3.25 1.625-5.417 5.417-5.417h8.666c3.792 0 5.417 2.167 5.417 5.417z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.546 20.097c-.512 0-.966-.088-1.364-.263a2.28 2.28 0 01-.937-.732 1.896 1.896 0 01-.366-1.083h1.335a.884.884 0 00.195.515c.119.145.276.257.473.337.196.08.416.121.66.121.26 0 .492-.045.693-.135.201-.092.358-.22.472-.383a.944.944 0 00.167-.565.98.98 0 00-.17-.582 1.14 1.14 0 00-.505-.395 1.971 1.971 0 00-.788-.141h-.643v-1.016h.643c.253 0 .475-.044.664-.132.192-.087.342-.21.45-.369a.951.951 0 00.16-.557.969.969 0 00-.138-.537.935.935 0 00-.394-.358 1.296 1.296 0 00-.593-.128c-.223 0-.428.04-.618.12-.19.081-.342.196-.458.345a.873.873 0 00-.185.526H8.032c.01-.408.126-.765.351-1.073.227-.31.53-.551.91-.724a3.002 3.002 0 011.274-.263c.485 0 .907.091 1.264.273.36.18.638.423.835.728.196.306.295.643.295 1.013.002.41-.119.752-.363 1.03a1.7 1.7 0 01-.951.543v.056c.511.072.903.26 1.175.569.275.305.41.685.409 1.14 0 .407-.117.771-.348 1.093-.23.32-.547.57-.952.753-.403.182-.864.274-1.385.274zM17.508 12.724v7.273H16.19v-5.99h-.043l-1.7 1.086v-1.207l1.807-1.162h1.253z"
        fill="#8C939F"
      />
    </svg>
  );
}
export default SvgCalendarWithDateLg;
