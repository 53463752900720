import * as React from 'react';
function SvgSettingsMd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 13.75a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.832 11.81v-1.613c0-.953.78-1.741 1.742-1.741 1.659 0 2.337-1.174 1.503-2.613a1.74 1.74 0 01.642-2.374l1.586-.908c.724-.43 1.659-.174 2.09.55l.1.175c.825 1.439 2.182 1.439 3.016 0l.101-.175c.43-.724 1.366-.98 2.09-.55l1.586.908a1.74 1.74 0 01.642 2.374c-.835 1.44-.156 2.613 1.503 2.613.953 0 1.741.779 1.741 1.741v1.614c0 .953-.779 1.741-1.741 1.741-1.66 0-2.338 1.174-1.503 2.613a1.739 1.739 0 01-.642 2.374l-1.586.907c-.724.431-1.66.175-2.09-.55l-.1-.174c-.826-1.439-2.183-1.439-3.017 0l-.1.174c-.431.725-1.366.981-2.09.55l-1.586-.907a1.74 1.74 0 01-.642-2.374c.834-1.44.156-2.613-1.503-2.613a1.747 1.747 0 01-1.742-1.741z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgSettingsMd;
