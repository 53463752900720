import { gql } from '@apollo/client';

export const GET_IP_INFO_QUERY = gql`
  query GetIpInfo {
    ip {
      ip
      country
    }
  }
`;
