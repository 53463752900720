import React, { forwardRef } from 'react';

import { ITextareaAutoSizeProps } from '../model';

import { StyledTextarea } from './styled';

export const TextareaAutoSize = forwardRef<HTMLTextAreaElement, ITextareaAutoSizeProps>((props, ref) => {
  const { variant = 'filled', ...otherProps } = props;

  return <StyledTextarea ref={ref} $variant={variant} {...otherProps} />;
});

TextareaAutoSize.displayName = 'TextareaAutoSize';
