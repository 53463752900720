import React, { forwardRef } from 'react';

import { IRowProps } from '../model';

import { RowContainer } from './styled';

export const Row = forwardRef<HTMLDivElement, IRowProps>((props, ref) => {
  const {
    active = false,
    hovered = false,
    children,
    hoverBackground,
    defaultBackground,
    withExitAnimation = false,
    ...otherProps
  } = props;

  return (
    // @ts-ignore
    <RowContainer
      ref={ref}
      $active={active}
      $hovered={hovered}
      $hoverBackground={hoverBackground}
      $defaultBackground={defaultBackground}
      $withExitAnimation={withExitAnimation}
      {...otherProps}
    >
      {children}
    </RowContainer>
  );
});

Row.displayName = 'Row';
