import React from 'react';
import { DateTime } from 'luxon';
import { ListChildComponentProps } from 'react-window';
import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';
import { Typography } from 'shared/components/typography';

import { DayItem } from '../day-item';
import { IDayItem } from '../../../model';

const MonthItemContainer = styled.div`
  padding: 18px 21px;
  box-sizing: border-box;
  border-bottom: 1px solid ${getGray400Color};
`;

const GridContainer = styled.div`
  display: grid;
  grid-gap: 6px 8px;
  grid-auto-rows: 30px;
  grid-template-columns: repeat(7, 30px);
  margin-top: 14px;
`;

interface IProps {
  items: Array<{ date: DateTime; height: number; days: Array<IDayItem>; placeholdersLength: number }>;
  selectedDate?: DateTime | null;
  firstDayOfWeek: number;
  disabledAfterDate?: DateTime;
  disabledBeforeDate?: DateTime;
  onChange?: (date: DateTime) => void;
}

export const MonthItem = (props: ListChildComponentProps<IProps>) => {
  const { data, style, index } = props;
  const { items, firstDayOfWeek, onChange } = data;

  const days = items[index].days;
  const itemDate = items[index].date;

  return (
    <MonthItemContainer style={style}>
      <Typography fontWeight="medium">{itemDate.toFormat('MMMM yyyy')}</Typography>

      <GridContainer>
        {days.map((day) => (
          <DayItem
            key={day.value.toFormat('ddMMyyyy')}
            date={day.value}
            isToday={day.isToday}
            isActive={day.isActive}
            isWeekend={day.isWeekend}
            isDisabled={day.isDisabled}
            firstDayOfWeek={firstDayOfWeek}
            markedAsRecurring={day.markAsRecurring}
            onClick={onChange}
          />
        ))}
      </GridContainer>
    </MonthItemContainer>
  );
};
