import * as React from 'react';
function SvgReplySm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5 6.5c4.5 0 7.5 4 7.5 9.5-1.6-2.4-4-2.5-7.5-2.5V18L2 10l8.5-8v4.5z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgReplySm;
