interface IParams {
  newHeight: number;
  originalWidth: number;
  originalHeight: number;
}

export const calculateProportionalWidth = (params: IParams) => {
  const { newHeight, originalWidth, originalHeight } = params;

  return originalHeight ? (originalWidth * newHeight) / originalHeight : 0;
};
