import * as React from 'react';
function SvgIndicatorAsk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={6} cy={6.5} r={6} fill="#E0E6EB" />
      <path
        d="M3.692 4.7c0-.38.1-.711.302-.994.201-.286.469-.506.801-.66a2.564 2.564 0 011.103-.237c.563 0 1.05.124 1.46.372.411.249.693.574.845.977.07.18.105.376.105.587 0 .346-.078.65-.235.909-.152.256-.336.48-.554.672-.218.192-.423.367-.616.525-.169.14-.32.28-.456.423-.132.14-.197.333-.197.582 0 .053.006.105.018.158.012.049.018.1.018.152a.34.34 0 01-.117.26.405.405 0 01-.283.107c-.177 0-.294-.071-.351-.214a1.273 1.273 0 01-.087-.469c0-.316.07-.585.21-.807a2.67 2.67 0 01.524-.593c.205-.173.408-.339.61-.497.205-.158.37-.333.493-.525a1.32 1.32 0 00.16-.31c.041-.11.062-.234.062-.373a.962.962 0 00-.228-.65 1.387 1.387 0 00-.586-.411 2.144 2.144 0 00-.782-.141 1.65 1.65 0 00-.999.304c-.279.204-.419.488-.419.853v.068a.34.34 0 01-.11.327.405.405 0 01-.284.107c-.148 0-.253-.049-.314-.146a.654.654 0 01-.093-.356zm2.305 4.41c.164 0 .304.052.419.157a.502.502 0 01.172.384c0 .15-.057.279-.172.384a.598.598 0 01-.42.158.598.598 0 01-.418-.158.501.501 0 01-.173-.384c0-.15.058-.278.173-.384a.598.598 0 01.419-.158z"
        fill="#8C939F"
      />
    </svg>
  );
}
export default SvgIndicatorAsk;
