import { useMemo, useState, useCallback } from 'react';
import { debounce } from 'lodash';

export const useSearchState = (debounceValue = 200) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const debouncedSearch = useMemo(() => debounce(setDebouncedValue, debounceValue), [debounceValue]);

  const search = useCallback(
    (newValue: string) => {
      setSearchValue(newValue);
      debouncedSearch(newValue);
    },
    [debouncedSearch]
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent) => {
      //@ts-ignore
      const newSearchValue = event.target.value;

      search(newSearchValue);
    },
    [search]
  );

  const resetSearch = useCallback(() => {
    setSearchValue('');
    setDebouncedValue('');
  }, []);

  return { searchValue, debouncedValue, search, handleSearch, resetSearch };
};
