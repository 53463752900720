import * as React from 'react';
function SvgClockSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.333 10c0 4.6-3.733 8.333-8.333 8.333a8.336 8.336 0 01-8.333-8.334C1.667 5.4 5.4 1.666 10 1.666s8.333 3.733 8.333 8.333z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.092 12.65l-2.583-1.542c-.45-.267-.817-.909-.817-1.434V6.258"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgClockSm;
