import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { isNumber } from 'lodash';
import { MinusSm, PlusSm } from 'shared/components/icons';

import { INumberInputProps } from '../model';

import { NumberInputContainer, NumberInputField, ActionButton } from './styled';

export const NumberInput = forwardRef<HTMLInputElement, INumberInputProps>((props, ref) => {
  const {
    min,
    max,
    mode = 'natural',
    step = 1,
    value = 0,
    disabled,
    pattern,
    negative = false,
    className,
    onChange,
    onKeyDown,
    ...otherProps
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const preparedValue = Number.isNaN(+value) ? 0 : +value;

  const isLeftButtonDisabled = (isNumber(min) && preparedValue <= min) || disabled;
  const isRightButtonDisabled = (isNumber(max) && preparedValue >= max) || disabled;

  const dispatchChangeEvent = (newValue: number) => {
    if (!inputRef.current) {
      return;
    }
    //@ts-ignore
    const setValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value').set;

    const changeEvent = new Event('change', {
      bubbles: true,
      cancelable: true,
    });

    setValue?.call(inputRef.current, newValue.toString());

    inputRef.current.dispatchEvent(changeEvent);
  };

  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (pattern) {
      const newValue = event.target.value;
      const regexp = new RegExp(pattern);

      if (!regexp.test(newValue)) {
        return;
      }
    }

    onChange?.(event);
  };

  const _handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(event);

    if (mode === 'natural') {
      const key = event.key;

      if (key === '.' || key === ',' || (!negative && key === '-')) {
        event.preventDefault();
      }
    }
  };

  const _handleBlockClick = () => {
    if (disabled) {
      return;
    }

    inputRef.current?.focus();
  };

  const _handleLeftButtonClick = () => {
    if (isLeftButtonDisabled) {
      return;
    }

    dispatchChangeEvent(preparedValue - step);
  };

  const _handleRightButtonClick = () => {
    if (isRightButtonDisabled) {
      return;
    }

    dispatchChangeEvent(preparedValue + step);
  };

  useImperativeHandle(ref, () => inputRef.current!, []);

  return (
    <NumberInputContainer className={className} onClick={_handleBlockClick}>
      <ActionButton type="button" disabled={isLeftButtonDisabled} onClick={_handleLeftButtonClick}>
        <MinusSm width={18} height={18} />
      </ActionButton>

      <NumberInputField
        {...otherProps}
        ref={inputRef}
        min={min}
        max={max}
        step={step}
        type="number"
        value={preparedValue}
        disabled={disabled}
        onChange={_handleChange}
        onKeyDown={_handleKeyDown}
      />

      <ActionButton type="button" disabled={disabled} onClick={_handleRightButtonClick}>
        <PlusSm width={18} height={18} />
      </ActionButton>
    </NumberInputContainer>
  );
});

NumberInput.displayName = 'NumberInput';
