import styled, { ThemedStyledProps, css } from 'styled-components';
import { ITheme } from 'core/theme';

import { IStyledArrowButtonProps } from '../../model';

interface IIconContainerProps {
  $transformDuration: number;
}

const getVariantStyle = (props: ThemedStyledProps<IStyledArrowButtonProps, ITheme>) => {
  const { theme, $variant } = props;

  switch ($variant) {
    case 'ghost':
      return css`
        color: ${theme.colors.gray800};
        padding: 3px;
        background: none;
        border-radius: 0;

        &:hover,
        &:focus-visible {
          color: ${theme.colors.blue300};
        }
      `;
    case 'primary':
      return css`
        color: ${theme.colors.white};
        min-width: 26px;
        min-height: 26px;
        background: ${theme.colors.blue700};
        border-radius: 50%;

        &:hover,
        &:focus-visible {
          background: ${theme.colors.blue300};
        }
      `;
    default:
      return css`
        color: ${theme.colors.gray800};
        min-width: 26px;
        min-height: 26px;
        background: ${theme.colors.gray400};
        border-radius: 50%;

        &:hover,
        &:focus-visible {
          background: ${theme.colors.gray500};
        }
      `;
  }
};

export const StyledButton = styled.button`
  ${getVariantStyle}

  display: flex;
  transition: color, background 300ms ease-out;
  align-items: center;
  justify-content: center;

  &:disabled {
    pointer-events: none;
  }
`;

export const IconContainer = styled.i<IIconContainerProps>`
  display: inline-flex;
  flex: 0 0;

  color: currentColor;
  transition: color 300ms ease-out, transform ${({ $transformDuration }) => $transformDuration}ms linear;

  & svg {
    & *[fill]:not(*[fill='none']) {
      fill: currentColor;
    }

    & *[stroke]:not(*[stroke='none']) {
      stroke: currentColor;
    }
  }
`;
