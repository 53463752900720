import { Logger } from 'core/logger/logger';

import { fetchServerHealth } from '../graphql/queries/network';
import { setServerData } from '../store/app';

export const checkServerStatus = async () => {
  try {
    const response = await fetchServerHealth();

    if (response.status !== 503) {
      return;
    }

    setServerData({ online: false, status: response.status });

    const intervalId = setInterval(() => {
      fetchServerHealth()
        .then((res) => {
          const online = res.status !== 503;

          if (online) {
            clearInterval(intervalId);
          }

          setServerData({ online, status: response.status });
        })
        .catch((error) => {
          const message = (error as Error).message;

          clearInterval(intervalId);

          Logger.subject('CHECK_SERVER_STATUS').error(message);
        });
    }, 60000);
  } catch (error) {
    const message = (error as Error).message;

    Logger.subject('CHECK_SERVER_STATUS').error(message);
  }
};
