import { createClient } from 'graphql-ws';
import { Logger } from 'core/logger/logger';
import config from 'shared/config';

import { setServerData } from '../store/app';

let timedOut: number | undefined;
let activeSocket: unknown;

const CLOSE_TIMEOUT = 10000;

export const subscriptionClient = createClient({
  url: config.graphql.wsurl,
  lazy: true,
  keepAlive: CLOSE_TIMEOUT,
  retryAttempts: Infinity,
  on: {
    connected: (socket) => {
      activeSocket = socket;

      setServerData({ online: true, status: 200 });

      Logger.subject('GQL_CLIENT').info('Connected to WS');
    },
    ping: (received) => {
      if (received) {
        return;
      }

      // wait 5 seconds for the pong and then close the connection
      // @ts-ignore
      timedOut = setTimeout(() => {
        // @ts-ignore
        if (activeSocket?.readyState === WebSocket.OPEN) {
          // @ts-ignore
          activeSocket?.close(4408, 'Request Timeout');
        }
      }, CLOSE_TIMEOUT);
    },
    pong: (received) => {
      // pong is received, clear connection close timeout
      if (received) {
        clearTimeout(timedOut);
      }
    },
    closed: () => {
      setServerData({ online: false });

      Logger.subject('GQL_CLIENT').info('Disconnected from WS');
    },
  },
  shouldRetry: () => true,
  connectionParams: () => {
    const authToken = localStorage.getItem(config.constants.authTokenStorageKey);

    return {
      'x-bordio-platform': 'web',
      'x-bordio-build-version': config.appVersion,
      authorization: authToken ? `Bearer ${authToken}` : '',
    };
  },
});
