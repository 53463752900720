import React from 'react';

import { ISwitcherProps } from '../model';
import { SwitcherProvider } from '../providers';

import { SwitcherItem } from './SwitcherItem';
import { ElementsContainer } from './styled';

export const Switcher = <T extends string | number>(props: ISwitcherProps<T>) => {
  const { name, value, options, children, className, onChange } = props;

  return (
    <SwitcherProvider name={name} value={value} onChange={onChange}>
      <ElementsContainer className={className}>
        {options ? options?.map((option) => <SwitcherItem key={option.value} {...option} />) : children}
      </ElementsContainer>
    </SwitcherProvider>
  );
};
