import { DateTime } from 'luxon';

/**
 * Сhecks whether the date is a weekend
 *
 * @param date - date in string ot DateTime
 */
export const isWeekendsDate = (date: DateTime | string) => {
  const weekDay = DateTime.isDateTime(date) ? date.weekday : DateTime.fromISO(date).get('weekday');

  return weekDay === 6 || weekDay === 7;
};
