import * as React from 'react';
function SvgNotesLg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.025 17.573H9.203M17.025 13.034H9.203M12.188 8.51H9.203"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M17.234 2.977l-8.33.004c-2.99.018-4.841 1.986-4.841 4.986v9.963c0 3.016 1.865 4.99 4.881 4.99l8.33-.003c2.99-.018 4.842-1.986 4.842-4.987V7.967c0-3.016-1.866-4.99-4.882-4.99z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgNotesLg;
