import * as React from 'react';
function SvgFileXlsx(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_xlsx_svg__clip0_4578_26761)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#41901B"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.328 2.328 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#22540B"
        />
        <path
          d="M5.688 24.525l1.667 2.785 1.687-2.785h1.526L8.078 28.3l2.567 3.874H9.141l-1.758-2.87-1.758 2.87H4.113l2.56-3.874-2.482-3.775h1.497zM13.078 21.315v10.86h-1.307v-10.86h1.307zM19.308 30.145c0-.188-.042-.363-.126-.523-.08-.165-.246-.313-.5-.445-.248-.137-.623-.255-1.124-.354a8.872 8.872 0 01-1.146-.318 3.472 3.472 0 01-.865-.445 1.867 1.867 0 01-.542-.615 1.724 1.724 0 01-.19-.828c0-.301.066-.586.197-.855.136-.268.326-.506.57-.714.248-.207.546-.37.893-.488a3.592 3.592 0 011.16-.176c.61 0 1.13.108 1.56.325.432.217.763.506.992.87.23.357.345.756.345 1.194H19.23c0-.212-.063-.417-.19-.615a1.405 1.405 0 00-.541-.502c-.235-.132-.523-.198-.865-.198-.361 0-.654.057-.879.17a1.13 1.13 0 00-.485.417 1.055 1.055 0 00-.078.919.84.84 0 00.268.304c.126.09.304.174.534.254.23.08.523.16.879.24.623.142 1.137.312 1.54.51s.703.44.9.728c.197.287.295.636.295 1.046a2.034 2.034 0 01-.809 1.64 2.963 2.963 0 01-.928.467 4.125 4.125 0 01-1.202.163c-.67 0-1.237-.12-1.702-.361-.464-.24-.815-.551-1.054-.933a2.242 2.242 0 01-.359-1.21h1.308c.019.36.122.644.31.856.187.208.417.356.689.446.271.084.54.127.808.127.356 0 .654-.047.893-.141.244-.095.429-.224.556-.39a.904.904 0 00.19-.565zM22.909 24.525l1.666 2.785 1.688-2.785h1.525L25.3 28.3l2.567 3.874H26.36l-1.758-2.87-1.758 2.87h-1.511l2.559-3.874-2.482-3.775h1.498z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_xlsx_svg__clip0_4578_26761">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileXlsx;
