import { useCallback } from 'react';
import ObjectID from 'bson-objectid';
import { useNotification, EAppearanceType } from 'providers/notification';
import { MAX_IMAGE_SIZE } from 'shared/constants/files';
import { getFileSize } from 'shared/helpers/files';

import { IUploadAttachmentInput } from '../interfaces';
import {
  UploadStatus,
  SinglepartUploader,
  MultipartUploader,
  IUploaderMutationData,
  IProgressCallbackParams,
} from '../../../uploader';
import { addFileData, updateFileData, removeFileData } from '../../../store/files';

interface IUseUploadFileAttachment {
  input: IUploadAttachmentInput;
  transaction?: string;
  removeAfterComplete?: boolean;
}

export const useUploadFileAttachmentCommand = () => {
  const { addNotification } = useNotification();

  const uploadFileAttachment = useCallback((params: IUseUploadFileAttachment) => {
    const { input, transaction, removeAfterComplete } = params;

    const newAttachmentId = input.id || new ObjectID().toHexString();

    const isSmallSize = getFileSize(input.source) <= MAX_IMAGE_SIZE;

    const mutationData: IUploaderMutationData = {
      id: newAttachmentId,
      refId: input.refId,
      refType: input.refType,
      filename: input.filename,
      transaction,
      contentType: input.contentType || 'application/octet-stream',
      attachmentKind: input.attachmentKind,
    };

    const uploader = isSmallSize
      ? new SinglepartUploader(input.source, mutationData)
      : new MultipartUploader(input.source, mutationData);

    addFileData({
      fileId: newAttachmentId,
      data: {
        uploader: uploader,
        mutationData,
      },
    });

    uploader.onError = (error: Error) => {
      if (uploader.status !== UploadStatus.STOPPED) {
        return;
      }

      const message = (error as Error).message;

      addNotification(message, EAppearanceType.error);
    };

    uploader.onProgress = (data: IProgressCallbackParams) => {
      updateFileData({
        fileId: newAttachmentId,
        data: {
          sent: data.sent,
          status: data.sent,
          progress: data.progress,
        },
      });
    };

    uploader.onStatusChange = (status: UploadStatus) => {
      updateFileData({
        fileId: newAttachmentId,
        data: {
          status,
        },
      });
    };

    if (removeAfterComplete) {
      uploader.onComplete = () => {
        removeFileData(newAttachmentId);
      };
    }

    uploader.start();

    return uploader;
  }, []);

  return uploadFileAttachment;
};
