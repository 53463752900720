import { useCallback } from 'react';
import { useEventEmitter, EventType } from 'providers/event-subscription';
import config from 'shared/config';

import { createTransaction } from '../../../graphql/transaction/TransactionManager';
import { Attachment } from '../../../graphql/fragments/impl/attachment';
import { ICreateAttachmentsInput } from '../interfaces';
import { transformCreateAttachmentInput } from '../transformers';

import { useUploadFileAttachmentCommand } from './useUploadFileAttachment.command';

interface IUseCreateAttachmentCommand {
  input: ICreateAttachmentsInput;
  optimistic?: boolean;
  removeAfterComplete?: boolean;
}

export const useCreateAttachmentCommand = () => {
  const emitEvent = useEventEmitter();

  const uploadFileAttachment = useUploadFileAttachmentCommand();

  const _createAttachment = useCallback(
    (params: IUseCreateAttachmentCommand) => {
      const { input, optimistic = true, removeAfterComplete = true } = params;

      const transaction = optimistic ? createTransaction(0) : undefined;

      const { creatingData, mutationInput } = transformCreateAttachmentInput({
        input,
      });

      if (config.events.local) {
        emitEvent<Attachment>(creatingData, {
          type: EventType.ATTACHMENT_CREATED,
          optimisticTransaction: transaction,
        });
      }

      if (config.events.server) {
        uploadFileAttachment({
          input: mutationInput,
          transaction,
          removeAfterComplete,
        });
      }

      return creatingData;
    },
    [uploadFileAttachment]
  );

  return _createAttachment;
};
