import React, { useState, forwardRef, useEffect } from 'react';
import { DateTime } from 'luxon';
import { RRule } from 'rrule';
import { Popover } from 'evergreen-ui';
import { dateToZeroUTC } from 'shared/helpers/time/to-zero-utc';
import { FIRST_DATE_FORMAT, FIRST_DAY_OF_WEEK_MONDAY } from 'shared/constants/settings';

import { IDatePickerProps } from '../model';
import { DatePickerProvider } from '../providers';

import { DatePickerTrigger } from './DatePickerTrigger';
import { Header } from './header';
import { InfiniteCalendar } from './infinite-calendar';

export const DatePicker = forwardRef<HTMLDivElement, IDatePickerProps>((props, ref) => {
  const {
    value,
    rrule,
    footer,
    isShown = false,
    minWidth = 300,
    disabled,
    triggerProps,
    firstDayOfWeek = FIRST_DAY_OF_WEEK_MONDAY,
    triggerComponent = DatePickerTrigger,
    dateDisplayFormat = FIRST_DATE_FORMAT,
    disabledAfterDate,
    disabledBeforeDate,
    stopClickEventPropagation,
    onOpen,
    onClose,
    onChange,
    onRRuleChange,
    ...otherProps
  } = props;

  const [isOpen, setIsOpen] = useState(isShown);

  const TriggerComponent = triggerComponent;

  const _handleOpen = () => {
    if (disabled) {
      return;
    }

    onOpen?.();
    setIsOpen(true);
  };

  const _handleClose = () => {
    if (disabled) {
      return;
    }

    onClose?.();
    setIsOpen(false);
  };

  const _handleToggle = () => {
    (isOpen ? _handleClose : _handleOpen)();
  };

  const _handleReset = () => {
    _handleClose();

    onChange?.();
  };

  const _handleChange = (newValue: DateTime) => {
    if (rrule) {
      const newRRule = new RRule({
        ...rrule.origOptions,
        dtstart: dateToZeroUTC(newValue).toJSDate(),
      });

      onRRuleChange?.(newRRule);
    }

    _handleClose();

    onChange?.(newValue);
  };

  const _handleDropdownClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (stopClickEventPropagation) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    setIsOpen(isShown);
  }, [isShown]);

  return (
    // @ts-ignore
    <Popover
      {...otherProps}
      content={
        <DatePickerProvider
          value={value}
          rrule={rrule}
          firstDayOfWeek={firstDayOfWeek}
          dateDisplayFormat={dateDisplayFormat}
          disabledAfterDate={disabledAfterDate}
          disabledBeforeDate={disabledBeforeDate}
          onReset={_handleReset}
          onChange={_handleChange}
          onRRuleChange={onRRuleChange}
        >
          <div ref={ref} onClick={_handleDropdownClick}>
            <Header firstDayOfWeek={firstDayOfWeek} />

            <InfiniteCalendar />

            {footer}
          </div>
        </DatePickerProvider>
      }
      isShown={isOpen}
      minWidth={minWidth}
      onOpen={_handleOpen}
      onClose={_handleClose}
    >
      {({ getRef }) => (
        <TriggerComponent
          {...triggerProps}
          value={value}
          isOpen={isOpen}
          disabled={disabled}
          dateFormat={dateDisplayFormat}
          getRef={getRef}
          onToggle={_handleToggle}
        />
      )}
    </Popover>
  );
});

DatePicker.displayName = 'DatePicker';
