import * as React from 'react';
function SvgBellLg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.021 3.156a6.505 6.505 0 00-6.5 6.5v3.131c0 .66-.282 1.668-.617 2.232l-1.246 2.069c-.77 1.278-.239 2.697 1.17 3.174a22.665 22.665 0 0014.376 0 2.17 2.17 0 001.17-3.174l-1.246-2.07c-.325-.563-.607-1.57-.607-2.23V9.655c0-3.575-2.925-6.5-6.5-6.5z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M15.024 3.467a7.317 7.317 0 00-4.008 0 2.15 2.15 0 012.004-1.365c.91 0 1.69.563 2.004 1.365z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.273 20.648a3.26 3.26 0 01-3.25 3.25 3.261 3.261 0 01-2.296-.953 3.261 3.261 0 01-.954-2.297"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgBellLg;
