import React from 'react';
import { AngleDownSm } from 'shared/components/icons';
import { DropdownItem } from 'shared/components';
import { ITriggerProps } from 'shared/components/dropdown';

interface IProps extends ITriggerProps {
  icon?: React.ReactNode;
}

export const DropdownItemTrigger = (props: IProps) => {
  const { icon, label, isOpen, getRef, onToggle } = props;

  return (
    <DropdownItem
      ref={getRef}
      icon={icon}
      label={label}
      active={isOpen}
      rightIcon={<AngleDownSm width={18} height={18} style={{ rotate: '-90deg' }} />}
      onClick={onToggle}
    />
  );
};
