import React from 'react';
import styled from 'styled-components';
import { getBlackColor, getGray800Color } from 'core/theme/helpers';
import { ButtonTrigger } from 'shared/components/triggers';

import { IBasicSelectorTriggerProps, ValueType } from '../../model';

const StyledButtonTrigger = styled(ButtonTrigger)`
  &.filled {
    color: ${getGray800Color};

    &.selected {
      color: ${getBlackColor};
    }
  }
`;

export const BasicSelectorTrigger = <T extends ValueType>(props: IBasicSelectorTriggerProps<T>) => {
  const { icon, label, placeholder = 'None', className, selectedOption, ...otherProps } = props;

  const isSelected = !!selectedOption;
  const showingIcon = selectedOption?.icon ?? icon;

  const classes = isSelected ? `${className} selected` : className;

  const _getText = () => {
    if (isSelected) {
      return label ?? selectedOption.displayedLabel ?? selectedOption.label;
    }

    return placeholder;
  };

  return <StyledButtonTrigger {...otherProps} icon={showingIcon} label={_getText()} className={classes} />;
};
