import styled from 'styled-components';

import { IStyledLinkButtonProps } from '../../model';
import { commonButtonStyles, getButtonVariantStyles, getButtonBorderRadiusStyles } from '../styled';

export const StyledLinkButton = styled.a<IStyledLinkButtonProps>`
  ${commonButtonStyles}
  ${getButtonBorderRadiusStyles}
  ${getButtonVariantStyles}
  
  cursor: pointer;
  text-decoration: none;
`;
