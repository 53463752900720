import * as React from 'react';
function SvgFileUnknown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_unknown_svg__clip0_4578_26766)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#D1D1D1"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.328 2.328 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#646464"
        />
      </g>
      <defs>
        <clipPath id="file_unknown_svg__clip0_4578_26766">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileUnknown;
