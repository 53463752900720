import styled, { ThemedStyledProps, css } from 'styled-components';
import TextAreaAutosize from 'react-autosize-textarea';
import { ITheme } from 'core/theme/theme.interface';
import { getGray800Color, getSMFontSize, getBlackColor } from 'core/theme/helpers';

import { IStyledTextareaAutosizeProps } from '../model';

export const getVariantStyles = (props: ThemedStyledProps<IStyledTextareaAutosizeProps, ITheme>) => {
  const { theme, $variant } = props;

  switch ($variant) {
    case 'ghost':
      return css`
        padding: 0;
        background: none;
      `;
    case 'highlighted': {
      return css`
        padding: 10px;
        background: none;
        border-radius: 8px;

        &:hover,
        &:focus-visible {
          background: ${theme.colors.gray100};
        }
      `;
    }
    default:
      return css`
        padding: 10px;
        background: ${theme.colors.gray100};
        border-radius: 8px;
      `;
  }
};

export const StyledTextarea = styled(TextAreaAutosize)<IStyledTextareaAutosizeProps>`
  ${getVariantStyles}

  color: ${getBlackColor};
  font-size: ${getSMFontSize};
  border: none;
  resize: none;
  outline: none;
  box-sizing: border-box;
  transition: background 300ms ease-out;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &::placeholder {
    color: ${getGray800Color};
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 1.5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a2a2a2;
    border-radius: 1.5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a2a2a2;
  }
`;
