import * as React from 'react';
function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 38.536 33.398" {...props}>
      <path
        d="M19.268 13.488a5.789 5.789 0 015.78 5.78 5.789 5.789 0 01-5.78 5.78 5.789 5.789 0 01-5.78-5.78 5.789 5.789 0 015.78-5.78zM33.4 5.138a4.953 4.953 0 013.633 1.505 4.945 4.945 0 011.505 3.633V28.26A5.139 5.139 0 0133.4 33.4H5.138a4.953 4.953 0 01-3.633-1.505A4.945 4.945 0 010 28.26V10.276a4.953 4.953 0 011.505-3.633 4.945 4.945 0 013.633-1.505h4.5l1.023-2.73a3.721 3.721 0 011.395-1.7A3.584 3.584 0 0114.13 0h10.276a3.574 3.574 0 012.078.713 3.728 3.728 0 011.395 1.7L28.9 5.138zM19.268 28.26a8.657 8.657 0 006.352-2.64 8.665 8.665 0 002.64-6.352 8.654 8.654 0 00-2.64-6.352 8.662 8.662 0 00-6.352-2.64 8.662 8.662 0 00-6.352 2.64 8.654 8.654 0 00-2.64 6.352 8.665 8.665 0 002.64 6.352 8.657 8.657 0 006.352 2.64z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgCamera;
