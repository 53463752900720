import React from 'react';
import styled from 'styled-components';
import { getGray300Color, getGray500Color, getMDBorderRadius } from 'core/theme/helpers';
import { IFileData } from 'core/store/files';
import { FileLoader, Typography } from 'shared/components';
import { getFileProgress } from 'shared/helpers/files';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 30px 40px;
  min-width: 190px;
  min-height: 120px;
  border: 1px solid ${getGray500Color};
  box-sizing: border-box;
  background: ${getGray300Color};
  border-radius: ${getMDBorderRadius};
`;

interface IProps {
  fileData: IFileData | null;
  onCancel?: () => void;
}

export const ImagePlaceholder = (props: IProps) => {
  const { fileData, onCancel } = props;

  const { sent = 0, total = 0, progress = 0 } = fileData || {};

  return (
    <Container>
      <FileLoader progress={progress} onCancel={onCancel} />

      <Typography size="xxs" color="gray800">
        {getFileProgress(total, sent)}
      </Typography>
    </Container>
  );
};
