import styled, { css, ThemedStyledProps } from 'styled-components';
import { ITheme } from 'core/theme/theme.interface';
import { getBlackColor, getSMFontSize } from 'core/theme/helpers';
import { CheckWithoutBorder } from 'shared/components/icons';

import { IStyledIconProps, IStyledFakeCheckboxProps, IStyledLabelTextProps } from '../model';

const getFakeCheckboxSizeStyles = ({ $size }: ThemedStyledProps<IStyledFakeCheckboxProps, ITheme>) => {
  let size = 18;

  switch ($size) {
    case 'md': {
      size = 20;

      break;
    }
    case 'lg': {
      size = 22;

      break;
    }
  }

  return css`
    width: ${size}px;
    height: ${size}px;
  `;
};

const getFakeCheckboxStateStyles = (props: ThemedStyledProps<IStyledFakeCheckboxProps, ITheme>) => {
  const { theme, $checked, $disabled } = props;

  const mainColor = $disabled ? theme.colors.gray600 : theme.colors.blue300;
  const backgroundColor = $checked ? mainColor : theme.colors.white;

  return css`
    cursor: ${$disabled ? 'default' : 'pointer'};
    border: 1.5px solid ${mainColor};
    background: ${backgroundColor};

    & * {
      visibility: ${$checked ? 'visible' : 'hidden'};
    }
  `;
};

const getFakeCheckboxVariantStyles = (props: ThemedStyledProps<IStyledFakeCheckboxProps, ITheme>) => {
  const { theme, $variant } = props;

  return css`
    border-radius: ${$variant === 'circle' ? '50%' : theme.border.radius.xs};
  `;
};

const getLabelPositionStyles = (props: ThemedStyledProps<IStyledLabelTextProps, ITheme>) => {
  const { $position } = props;

  if ($position === 'left') {
    return css`
      order: -1;
      margin-right: 8px;
    `;
  }

  return css`
    order: 1;
    margin-left: 8px;
  `;
};

export const ClassicCheckboxContainer = styled.label`
  display: inline-flex;
  align-items: center;
`;

export const ClassicCheckboxLabelText = styled.span<IStyledLabelTextProps>`
  ${getLabelPositionStyles};

  color: ${getBlackColor};
  font-size: ${getSMFontSize};
  user-select: none;
`;

export const StyledIcon = styled(CheckWithoutBorder)<IStyledIconProps>`
  width: 65%;
  height: 45%;

  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const FakeCheckbox = styled.div<IStyledFakeCheckboxProps>`
  ${getFakeCheckboxSizeStyles}
  ${getFakeCheckboxStateStyles}
  ${getFakeCheckboxVariantStyles}

  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  transition: all 300ms ease-out;
  box-sizing: border-box;
`;

export const HiddenCheckbox = styled.input`
  display: none;
`;
