import * as React from 'react';
function SvgSupportSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M15.893 15.89a8.338 8.338 0 01-9.404 1.671c-.456-.183-.83-.332-1.185-.332-.989.006-2.22.965-2.86.326-.64-.64.32-1.872.32-2.867 0-.355-.142-.722-.326-1.178a8.338 8.338 0 011.67-9.405c3.252-3.252 8.534-3.252 11.785 0a8.335 8.335 0 010 11.786z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.995 11.678v-.072c.005-.474.055-.85.15-1.13a1.71 1.71 0 01.414-.68c.178-.173.393-.331.644-.474a2.63 2.63 0 00.438-.324c.13-.12.233-.258.308-.413a1.16 1.16 0 00.114-.515c0-.227-.057-.423-.17-.588a1.116 1.116 0 00-.455-.382 1.397 1.397 0 00-.624-.138c-.208 0-.407.041-.596.122-.19.082-.346.21-.47.382-.125.17-.197.391-.215.661H7.3c.019-.458.142-.845.369-1.16.227-.32.527-.56.9-.723C8.945 6.081 9.36 6 9.814 6c.497 0 .933.088 1.306.263.373.174.662.417.868.73.208.31.312.673.312 1.088 0 .285-.048.542-.142.772-.095.226-.23.428-.406.607a2.746 2.746 0 01-.624.473c-.23.135-.417.275-.56.42-.14.145-.243.317-.308.516-.065.198-.1.444-.105.737v.072h-1.16zM9.607 14a.812.812 0 01-.572-.222.718.718 0 01-.239-.542c0-.209.08-.387.24-.534.159-.15.35-.226.571-.226a.8.8 0 01.568.226.697.697 0 01.243.534c0 .14-.038.269-.113.386a.816.816 0 01-.698.378z"
        fill="#8C939F"
      />
    </svg>
  );
}
export default SvgSupportSm;
