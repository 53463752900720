import React from 'react';
import { Skeleton } from 'shared/components/skeleton';

import { SearchBarContainer, DropdownContentContainer } from '../styled';

interface IProps {
  withSearchBar?: boolean;
}

export const DropdownPlaceholder = (props: IProps) => {
  const { withSearchBar } = props;

  return (
    <>
      {withSearchBar && (
        <SearchBarContainer>
          <Skeleton width="35%" height={22} loading />
        </SearchBarContainer>
      )}

      <DropdownContentContainer>
        <Skeleton width="60%" height={22} margin="0 0 4px 0" loading />
        <Skeleton width="86%" height={22} margin="0 0 4px 0" loading />
        <Skeleton width="53%" height={22} margin="0 0 4px 0" loading />
        <Skeleton width="82%" height={22} loading />
      </DropdownContentContainer>
    </>
  );
};
