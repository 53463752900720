import { IAppStore } from '../interfaces';

export const selectServerData = (state: IAppStore) => state.serverData;

export const selectAuthMethod = (state: IAppStore) => state.analyticsData.authMethod;

export const selectCurrentPage = (state: IAppStore) => state.currentPage;

export const selectAnalyticsData = (state: IAppStore) => state.analyticsData;

export const selectIsToolbarOpen = (state: IAppStore) => state.isToolbarOpen;

export const selectIsNavigationOpen = (state: IAppStore) => state.isNavigationOpen;
