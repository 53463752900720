import * as React from 'react';
function SvgDndSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 4h.004M6 9h.004M6 14h.004M12.004 4h.004M12.004 9h.004M12.004 14h.004"
        stroke="#8C939F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgDndSm;
