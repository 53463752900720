import { useState, useEffect } from 'react';
import { BLUR_COMMAND, FOCUS_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';

export const useEditorFocus = () => {
  const [editor] = useLexicalComposerContext();

  const [hasFocus, setFocus] = useState(() => {
    return editor.getRootElement() === document.activeElement;
  });

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setFocus(false);

          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setFocus(true);

          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );

    return unregister;
  }, []);

  return hasFocus;
};
