export enum EAppearanceType {
  error = 'error',
  success = 'success',
}

export interface INotificationData {
  id: string;
  message: string;
  content?: React.ReactNode;
  appearance: EAppearanceType;
}

export interface IAddNotificationOptions {
  message: string;
  content?: React.ReactNode;
  appearance: EAppearanceType;
}

export interface IAddNotificationFunction {
  (message: string, appearance: EAppearanceType): void;
  (options: IAddNotificationOptions): void;
}

export interface INotificationContextValues {
  addNotification: IAddNotificationFunction;
  removeNotification: (notificationId: string) => void;
}
