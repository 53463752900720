import { ThemedStyledProps } from 'styled-components';

import { ITheme } from '../theme.interface';

export const getXSBorderRadius = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.border.radius.xs;

export const getSMBorderRadius = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.border.radius.sm;

export const getMDBorderRadius = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.border.radius.md;

export const getLGBorderRadius = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.border.radius.lg;

export const getXLBorderRadius = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.border.radius.xl;

export const getXXLBorderRadius = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.border.radius.xxl;
