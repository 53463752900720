import React, { useEffect, ReactNode, useState } from 'react';
import { DropzoneProps, DropzoneState, useDropzone } from 'react-dropzone';
import { Dropzone, IDropzoneProps } from 'shared/components/dropzone';
import { DropFileLg } from 'shared/components/icons';

interface IProps extends Omit<DropzoneProps, 'children'> {
  children: ReactNode | ((state: DropzoneState) => JSX.Element);
  className?: string;
}

export const FilesDropzone = (props: IProps) => {
  const { children, className, ...otherProps } = props;

  const state = useDropzone(otherProps);

  const [isWindowEnter, setIsWindowEnter] = useState(false);

  const { isDragActive, isDragReject, isDragAccept, getRootProps, getInputProps } = state;

  const isFunction = typeof children === 'function';
  const isSectionShowing = isWindowEnter || isDragActive;

  const _getDropzoneVariant = (): IDropzoneProps['variant'] => {
    if (isDragReject) {
      return 'error';
    }

    return isDragAccept ? 'accepted' : 'default';
  };

  useEffect(() => {
    if (otherProps.disabled) {
      return;
    }

    const _handleDragEnter = (event: DragEvent) => {
      const isFilesIncluded = event.dataTransfer?.types.includes('Files');

      if (isFilesIncluded) {
        setIsWindowEnter(true);
      }
    };

    const _handleDragLeave = (event: DragEvent) => {
      const isWindowLeave = event.relatedTarget === null;

      if (isWindowLeave) {
        setIsWindowEnter(false);
      }
    };

    const _handleDrop = () => {
      setIsWindowEnter(false);
    };

    window.addEventListener('drop', _handleDrop);
    window.addEventListener('dragleave', _handleDragLeave);
    window.addEventListener('dragenter', _handleDragEnter);

    return () => {
      window.removeEventListener('drop', _handleDrop);
      window.removeEventListener('dragenter', _handleDragEnter);
      window.removeEventListener('dragleave', _handleDragLeave);
    };
  }, [otherProps.disabled]);

  return (
    <div {...getRootProps()} className={isSectionShowing ? `showing ${className}` : className}>
      {isSectionShowing && (
        <Dropzone
          icon={<DropFileLg width={30} height={30} />}
          content={isDragReject ? 'Only one file is allowed' : 'Drop file here'}
          variant={_getDropzoneVariant()}
          className="dropper"
          dataTestId="dropper"
        />
      )}

      <input {...getInputProps()} />

      {isFunction ? children?.(state) : children}
    </div>
  );
};
