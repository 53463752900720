import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAppStore, selectServerData } from 'core/store/app';
import { OfflineModule } from 'widgets/offline';
import { Loader } from 'shared/components/loader';
import { PrivateRoute } from 'shared/components/private-router';

const AuthRouting = React.lazy(() => import('pages/auth'));
const PrivateManager = React.lazy(() => import('../private-manager'));

export const AppRouting = () => {
  const { status, online } = useAppStore(selectServerData);

  const isOffline = !online && status === 503;

  if (isOffline) {
    return <OfflineModule />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/auth" component={AuthRouting} />

        <PrivateRoute path="/" component={PrivateManager} />
      </Switch>
    </Suspense>
  );
};
