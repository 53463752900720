import * as React from 'react';
function SvgUserLg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.999 12.997a5.417 5.417 0 100-10.833 5.417 5.417 0 000 10.833zM22.307 23.833c0-4.192-4.17-7.583-9.306-7.583-5.135 0-9.306 3.39-9.306 7.583"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgUserLg;
