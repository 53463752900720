import * as React from 'react';
function SvgEditorTextColor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.301 14.443l1.956-4.928m9.155 4.928l-2.15-4.928m0 0l-2.71-6.205c-.355-.815-1.517-.795-1.845.03l-2.45 6.175m7.005 0H7.256"
        stroke="#8C939F"
        strokeWidth={1.2}
      />
      <path d="M4.746 16.664H16.968" stroke="#8C939F" strokeWidth={1.2} strokeLinecap="round" />
    </svg>
  );
}
export default SvgEditorTextColor;
