import { TextMatchTransformer } from '@lexical/markdown';

import { MENTION_MARKDOWN_REGEX } from '../../model/constants';
import { MentionNode, $isMentionNode, $createMentionNode, MentionNodeDataType } from '../../nodes/mention-node';

export const MENTION_MARKDOWN_TRANSFORMER: TextMatchTransformer = {
  type: 'text-match',
  regExp: new RegExp(`${MENTION_MARKDOWN_REGEX.source}$`),
  trigger: '@',
  importRegExp: MENTION_MARKDOWN_REGEX,
  dependencies: [MentionNode],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export: (node, exportChildren, exportFormat) => {
    if (!$isMentionNode(node)) {
      return null;
    }

    const nodeData = node.getData();

    let mentionContent = `${node.getTrigger()}[${node.getValue()}]`;

    if (nodeData) {
      try {
        mentionContent += `(${JSON.stringify(nodeData)})`;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Mention markdown transformer export method error', error);
      }
    }

    return mentionContent;
  },
  replace: (textNode, match) => {
    const [, trigger, value, data] = match;

    let parsedData: MentionNodeDataType | undefined;

    if (data) {
      try {
        parsedData = JSON.parse(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Mention markdown transformer replace method error', error);
      }
    }

    const mentionNode = $createMentionNode(trigger, value, parsedData);

    textNode.replace(mentionNode);
  },
};
