import { gql } from '@apollo/client';

export const CREATE_ATTACHMENT_UPLOAD_URL_MUTATION = gql`
  mutation CreateAttachmentUploadURL($input: CreateAttachmentUploadUrl!, $transaction: String) {
    createAttachmentUploadUrl(transaction: $transaction, input: $input) {
      url
      filePath
      expiresAt
      attachmentUrl
    }
  }
`;
