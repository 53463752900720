import * as React from 'react';
function SvgDotsMd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.001 7.33a1.833 1.833 0 100-3.666 1.833 1.833 0 000 3.667zM11.001 12.83a1.833 1.833 0 100-3.666 1.833 1.833 0 000 3.667zM11.001 18.33a1.833 1.833 0 100-3.666 1.833 1.833 0 000 3.667z"
        fill="#8C939F"
      />
    </svg>
  );
}
export default SvgDotsMd;
