import React, { useCallback, useEffect, useRef } from 'react';
import { isNumber } from 'lodash';
import { useTheme } from 'styled-components';
import { Popover, Position } from 'evergreen-ui';
import { useVisibilityState } from 'hooks/useVisibilityState';
import { ButtonTrigger } from 'shared/components/triggers/button-trigger';

import { IDropdownProps } from '../model';

import { DropdownContent } from './dropdown-content';
import { DropdownPlaceholder } from './dropdown-placeholder';

export const Dropdown = (props: IDropdownProps) => {
  const {
    label,
    trigger = ButtonTrigger,
    loading,
    disabled,
    children,
    minWidth = 'sm',
    maxWidth,
    minHeight,
    maxHeight,
    className,
    autoClose = true,
    defaultOpen = false,
    searchValue,
    triggerProps,
    triggerAction,
    statelessProps,
    dropDownPosition = Position.BOTTOM_LEFT,
    searchPlaceholder,
    moveScrollToActiveItem = false,
    stopClickEventPropagation = false,
    onOpen,
    onClose,
    onSearch,
    regRef,
    passKeyCode,
  } = props;

  const theme = useTheme();

  const scrollableNodeRef = useRef<HTMLDivElement | null>(null);

  const { isVisible, showComponent, hideComponent, toggleVisible } = useVisibilityState(defaultOpen, disabled);

  const popoverMinWidth = isNumber(minWidth) ? minWidth : theme.dropdown.minWidth[minWidth];

  const TriggerComponent = trigger;

  const _handleOpen = () => {
    if (disabled) {
      return;
    }

    showComponent();
    onOpen?.();
  };

  const _handleClose = () => {
    if (!isVisible || disabled) {
      return;
    }

    hideComponent();
    onClose?.();
  };

  const _handleToggle = () => {
    if (disabled) {
      return;
    }

    toggleVisible();
    isVisible ? onClose?.() : onOpen?.();
  };

  const _handleDropdownClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (stopClickEventPropagation) {
      event.stopPropagation();
    }
  };

  const _getByCharacter = useCallback(
    (e: KeyboardEvent) => {
      passKeyCode?.(e, isVisible);
    },
    [passKeyCode]
  );

  useEffect(() => {
    if (defaultOpen === isVisible) {
      return;
    }

    defaultOpen ? showComponent() : hideComponent();
  }, [defaultOpen]);

  useEffect(() => {
    document.body.addEventListener('keydown', _getByCharacter, true);

    return () => {
      document.body.removeEventListener('keydown', _getByCharacter, true);
    };
  }, [_getByCharacter]);

  useEffect(() => {
    if (loading || !(isVisible && moveScrollToActiveItem)) {
      return;
    }

    const activeItem = scrollableNodeRef.current?.querySelector('[data-active="true"]');

    activeItem?.scrollIntoView({ block: 'center' });
  }, [loading, isVisible, moveScrollToActiveItem]);

  return (
    /*@ts-ignore */
    <Popover
      isShown={isVisible}
      content={({ close }) => (
        <div ref={regRef} style={{ maxWidth }} className={className} onClick={_handleDropdownClick}>
          {loading ? (
            <DropdownPlaceholder withSearchBar={!!onSearch} />
          ) : (
            <DropdownContent
              maxHeight={maxHeight}
              searchValue={searchValue}
              searchPlaceholder={searchPlaceholder}
              scrollableNodeRef={scrollableNodeRef}
              onClose={close}
              onSearch={onSearch}
            >
              {children}
            </DropdownContent>
          )}
        </div>
      )}
      trigger={triggerAction}
      position={dropDownPosition}
      minWidth={popoverMinWidth}
      minHeight={minHeight}
      statelessProps={{
        boxShadow: theme.shadow.primary,
        borderRadius: theme.border.radius.xs,
        ...statelessProps,
      }}
      shouldCloseOnExternalClick={autoClose}
      onOpen={_handleOpen}
      onClose={_handleClose}
    >
      {({ getRef }) => (
        <TriggerComponent
          {...triggerProps}
          label={label}
          isOpen={isVisible}
          loading={loading}
          disabled={disabled}
          getRef={getRef}
          onToggle={_handleToggle}
        />
      )}
    </Popover>
  );
};
