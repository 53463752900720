import ObjectID from 'bson-objectid';
import { AttachmentReferenceType } from 'core/graphql/interfaces/graphql.interfaces';
import { VIDEO_MP4_FORMAT, VIDEO_QUICKTIME_FORMAT } from 'shared/constants/files';
import { getFileSize } from 'shared/helpers/files';

import { ICreateAttachmentsInput, IUploadAttachmentInput } from '../interfaces';
import { Attachment } from '../../../graphql/fragments/impl/attachment';

interface IParams {
  input: ICreateAttachmentsInput;
}

export const transformCreateAttachmentInput = (params: IParams) => {
  const { input } = params;

  const newAttachmentId = new ObjectID().toHexString();

  let task: Attachment['task'] = null;
  let scheduledEvent: Attachment['scheduledEvent'] = null;

  const contentType =
    input.contentType === VIDEO_QUICKTIME_FORMAT ? VIDEO_MP4_FORMAT : input.contentType || 'application/octet-stream';

  if (input.refId && input.refType) {
    switch (input.refType) {
      case AttachmentReferenceType.Task: {
        task = {
          __typename: 'TaskType',
          id: input.refId,
        };

        break;
      }
      case AttachmentReferenceType.ScheduledEvent: {
        scheduledEvent = {
          __typename: 'ScheduledEventType',
          id: input.refId,
        };

        break;
      }
      default:
        break;
    }
  }

  const creatingData: Attachment = {
    __typename: 'AttachmentType',
    id: newAttachmentId,
    url: '',
    name: input.filename,
    task,
    meta: {
      __typename: 'AttachmentMetaType',
      size: getFileSize(input.source),
      width: null,
      height: null,
      durationSec: null,
    },
    previews: [],
    contentType,
    scheduledEvent,
  };

  const mutationInput: IUploadAttachmentInput = {
    ...input,
    id: newAttachmentId,
    contentType,
  };

  return { creatingData, mutationInput };
};
