import React, { forwardRef } from 'react';

import { ITypographyProps } from './interfaces';
import { StyledTypography } from './styled';

export const Typography = forwardRef<HTMLElement, ITypographyProps>((props, ref) => {
  const {
    size = 'sm',
    color = 'text',
    noWrap = false,
    children,
    maxLines,
    textAlign,
    component,
    fontStyle = 'normal',
    fontWeight = 'normal',
    overflowWrap,
    ...otherProps
  } = props;

  return (
    <StyledTypography
      as={component as any}
      ref={ref}
      $size={size}
      $color={color}
      $noWrap={noWrap}
      $maxLines={maxLines}
      $fontStyle={fontStyle}
      $textAlign={textAlign}
      $fontWeight={fontWeight}
      $overflowWrap={overflowWrap}
      {...otherProps}
    >
      {children}
    </StyledTypography>
  );
});

Typography.displayName = 'Typography';
