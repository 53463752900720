import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';

export const DropdownSeparator = styled.hr`
  margin: 6px -8px;
  border: none;
  border-bottom: 1px solid ${getGray400Color};
`;

export const SearchBarContainer = styled.div`
  padding: 6px 8px;
  border-bottom: 1px solid ${getGray400Color};
`;

export const DropdownContentContainer = styled.div`
  padding: 6px;
  box-sizing: border-box;
`;
