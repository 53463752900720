import { DateTime } from 'luxon';
import ObjectID from 'bson-objectid';
import { Logger } from 'core/logger/logger';
import config from 'shared/config';

import { apolloClient } from '../../apollo';

interface ITransaction {
  id: string;
  expiresAt: DateTime | null;
}

const logger = Logger.subject('TRANSACTION_MANAGER');

export class TransactionManager {
  transactions: ITransaction[] = [];

  constructor() {
    this.run();
  }

  get transactionList() {
    return this.transactions;
  }

  private run() {
    setInterval(() => {
      this.cleanup();
    }, 1000);
  }

  private cleanup() {
    //If server events turned off, app works in offline mode
    if (!config.events.server) {
      return;
    }

    this.transactions = this.transactions.reduce((result, current) => {
      const { id, expiresAt } = current;

      if (expiresAt === null || expiresAt > DateTime.now()) {
        result.push(current);

        return result;
      }

      // Roll-back transaction
      apolloClient.cache.removeOptimistic(id);

      logger.info(`Roll-back expired transaction ${id}`);

      return result;
    }, [] as ITransaction[]);
  }

  public release(id: string) {
    apolloClient.cache.removeOptimistic(id);

    this.transactions = this.transactions.filter((transaction) => transaction.id !== id);

    logger.info('Released transaction with id: ', id);
  }

  public removeFromQueue(id: string) {
    this.transactions = this.transactions.filter((transaction) => transaction.id !== id);
  }

  public create(timeout: number) {
    const transaction: ITransaction = {
      id: new ObjectID().toHexString(),
      expiresAt: timeout === 0 ? null : DateTime.now().plus({ millisecond: timeout }),
    };

    logger.info('Created a new transaction', transaction.id);

    this.transactions.push(transaction);

    return transaction.id;
  }
}

const manager = new TransactionManager();

export const createTransaction = (timeout = 10000) => {
  return manager.create(timeout);
};

export const releaseTransaction = (id: string) => {
  return manager.release(id);
};

export const removeFromQueue = (id: string) => {
  return manager.removeFromQueue(id);
};
