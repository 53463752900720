import * as React from 'react';
function SvgFileMov(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_mov_svg__clip0_4578_26746)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#FDCD3D"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.328 2.328 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#B28D22"
        />
        <path
          d="M4.493 24.206v6.216H3.2v-7.757h1.224l.07 1.541zM4.23 26.25l-.598-.021c.005-.53.072-1.02.202-1.47a3.53 3.53 0 01.577-1.183c.255-.334.572-.592.952-.774.38-.186.82-.28 1.321-.28.353 0 .677.053.974.158.296.1.553.26.771.48.218.22.387.502.508.846.12.345.18.76.18 1.248v5.169H7.83v-5.105c0-.406-.067-.73-.201-.975a1.166 1.166 0 00-.556-.53 1.955 1.955 0 00-.849-.172c-.38 0-.697.07-.952.208-.255.138-.459.33-.612.573a2.63 2.63 0 00-.334.839 4.83 4.83 0 00-.097.99zm4.874-.73l-.862.272c.004-.426.071-.835.201-1.226.135-.392.327-.741.577-1.047a2.74 2.74 0 01.939-.724c.37-.182.795-.273 1.272-.273a3.2 3.2 0 011.07.165c.316.11.58.28.793.51.218.224.383.513.494.867.111.354.167.774.167 1.262v5.097h-1.293V25.31c0-.435-.067-.772-.202-1.01a1.062 1.062 0 00-.556-.51c-.236-.1-.52-.15-.848-.15-.283 0-.533.05-.751.15-.218.1-.401.24-.55.416-.148.172-.261.37-.34.595a2.272 2.272 0 00-.111.717zM15.075 26.63v-.166c0-.559.08-1.077.237-1.555.157-.483.384-.901.681-1.255a3.05 3.05 0 011.078-.831c.421-.201.894-.302 1.418-.302.528 0 1.003.1 1.425.302.427.195.788.473 1.085.831.301.354.53.772.688 1.255.158.478.237.996.237 1.555v.165a4.94 4.94 0 01-.237 1.556A3.753 3.753 0 0121 29.439c-.297.354-.656.631-1.078.832-.417.196-.89.294-1.418.294a3.342 3.342 0 01-1.425-.294 3.234 3.234 0 01-1.085-.832 3.823 3.823 0 01-.681-1.254 4.94 4.94 0 01-.237-1.556zm1.287-.166v.165c0 .387.044.753.132 1.097.088.34.22.64.396.903.18.263.405.471.674.624.27.148.582.222.939.222.352 0 .66-.074.925-.222.268-.153.49-.36.667-.624.176-.263.308-.564.396-.903a4.2 4.2 0 00.14-1.097v-.165c0-.382-.047-.743-.14-1.082a2.83 2.83 0 00-.403-.91 1.94 1.94 0 00-.667-.631 1.827 1.827 0 00-.932-.23 1.85 1.85 0 00-.932.23 2.025 2.025 0 00-.667.63 2.925 2.925 0 00-.396.91c-.088.34-.133.701-.133 1.083zM25.428 29.224l2.058-6.56H28.8l-2.705 7.758h-.862l.195-1.198zm-1.717-6.56l2.12 6.596.146 1.162h-.862l-2.725-7.757h1.32z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_mov_svg__clip0_4578_26746">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileMov;
