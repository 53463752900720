import styled from 'styled-components';
import {
  getMDFontSize,
  getGray800Color,
  getBlackColor,
  getMDBorderRadius,
  getWhiteColor,
  getGray500Color,
  getBlue300Color,
} from 'core/theme/helpers';

export const NumberInputContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${getGray500Color};
  width: 110px;
  padding: 9px 15px;
  min-height: 40px;
  box-sizing: border-box;
  background: ${getWhiteColor};
  transition: all 300ms ease-out;
  border-radius: ${getMDBorderRadius};

  color: ${getBlackColor};
  font-size: ${getMDFontSize};

  &:focus-within {
    border-color: ${getBlue300Color};
  }
`;

export const NumberInputField = styled.input`
  width: 100%;
  border: none;
  outline: none;
  padding: 0 2px;
  background: none;
  color: inherit;
  font-size: inherit;
  text-align: center;
  box-sizing: border-box;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
  }
`;

export const ActionButton = styled.button`
  display: inline-flex;
  color: ${getGray800Color};
  transition: inherit;

  & svg {
    & *[fill]:not(*[fill='none']) {
      fill: currentColor;
    }

    & *[stroke]:not(*[stroke='none']) {
      stroke: currentColor;
    }
  }

  &:hover {
    color: ${getBlue300Color};
  }

  &:disabled {
    pointer-events: none;
  }
`;
