import { isNumber } from 'lodash';
import styled, { ThemedStyledProps, css } from 'styled-components';
import { ITheme } from 'core/theme';

import { IIconContainerProps } from '../model';

const getIconColor = (props: ThemedStyledProps<IIconContainerProps, ITheme>) => {
  const { theme, $iconColor } = props;

  if ($iconColor === 'inherit') {
    return $iconColor;
  }

  return theme.colors[$iconColor as keyof ITheme['colors']] ?? theme.colors.gray800;
};

const getIconSizeStyles = (props: ThemedStyledProps<IIconContainerProps, ITheme>) => {
  const { $iconSize } = props;

  let width;
  let height;

  if (isNumber($iconSize)) {
    width = $iconSize;
    height = $iconSize;
  } else {
    switch ($iconSize) {
      case 'xxs': {
        width = 12;
        height = 12;

        break;
      }
      case 'xs': {
        width = 16;
        height = 16;

        break;
      }
      case 'sm': {
        width = 18;
        height = 18;

        break;
      }
      case 'md': {
        width = 20;
        height = 20;

        break;
      }
      case 'lg': {
        width = 22;
        height = 22;

        break;
      }
      case 'xl': {
        width = 26;
        height = 26;

        break;
      }
    }
  }

  return css`
    width: ${width}px;
    height: ${height}px;
  `;
};

export const IconContainer = styled.i<IIconContainerProps>`
  color: ${getIconColor};
  font-style: normal;
  line-height: 0;

  & svg {
    ${getIconSizeStyles}

    & *[fill]:not(*[fill='none']) {
      fill: currentColor;
    }

    & *[stroke]:not(*[stroke='none']) {
      stroke: currentColor;
    }
  }
`;
