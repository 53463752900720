import { RRule } from 'rrule';
import { DateTime } from 'luxon';

interface IParams {
  rrule: RRule;
  startDate: DateTime;
  endDate: DateTime;
}

export const getRecurringDates = (params: IParams) => {
  const { rrule, startDate, endDate } = params;

  const rruleDates = rrule.between(startDate.toJSDate(), endDate.toJSDate());

  return rruleDates.reduce((result, date) => {
    const preparedDate = DateTime.fromJSDate(date).toUTC().setZone('local', { keepLocalTime: true });
    const dateKey = preparedDate.toFormat('ddMMyyyy');

    result.add(dateKey);

    return result;
  }, new Set<string>());
};
