import React, { forwardRef } from 'react';

import { IRouterLinkButtonProps } from '../../model';
import { IconContainer } from '../styled';

import { StyledLinkButton } from './styled';

export const RouterLinkButton = forwardRef<HTMLAnchorElement, IRouterLinkButtonProps>((props, ref) => {
  const { icon, iconColor = 'inherit', variant = 'default', children, borderRadius = 'sm', ...otherProps } = props;

  return (
    <StyledLinkButton ref={ref} $variant={variant} $borderRadius={borderRadius} {...otherProps}>
      {icon && (
        <IconContainer $iconColor={iconColor} className="left-icon-container">
          {icon}
        </IconContainer>
      )}

      {children}
    </StyledLinkButton>
  );
});

RouterLinkButton.displayName = 'RouterLinkButton';
