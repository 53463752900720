import * as React from 'react';
function SvgCrossInscribedSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13 7l-6 6M13 13L7 7" stroke="#8C939F" strokeWidth={1.2} strokeLinecap="round" strokeLinejoin="round" />
      <path
        clipRule="evenodd"
        d="M13.612 2.291H6.388c-2.518 0-4.096 1.783-4.096 4.305v6.807c0 2.522 1.57 4.305 4.096 4.305h7.223c2.526 0 4.098-1.783 4.098-4.305V6.596c0-2.522-1.572-4.305-4.097-4.305z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgCrossInscribedSm;
