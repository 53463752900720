import { motion, MotionProps } from 'framer-motion';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { ITheme } from 'core/theme/theme.interface';
import { getMDBorderRadius } from 'core/theme/helpers';

interface IStyledRowProps {
  $active: boolean;
  $hovered: boolean;
  $hoverBackground?: string;
  $defaultBackground?: string;
  $withExitAnimation: boolean;
}

const getRowMotionProps = (props: ThemedStyledProps<IStyledRowProps, ITheme>): MotionProps => {
  const { theme, $active, $hovered, $withExitAnimation, $hoverBackground, $defaultBackground } = props;

  const variant = $active ? 'hover' : 'default';

  return {
    exit: $withExitAnimation ? 'exit' : undefined,
    initial: variant,
    animate: variant,
    variants: {
      exit: {
        height: 0,
        opacity: 0,
        padding: 0,
        transition: {
          height: {
            duration: 0.4,
          },
          padding: {
            duration: 0.4,
          },
          opacity: {
            duration: 0.2,
          },
        },
      },
      hover: {
        background: $hoverBackground ?? theme.colors.gray200,
        transition: {
          ease: 'easeOut',
          duration: 0.3,
        },
      },
      default: {
        background: $defaultBackground ?? theme.colors.white,
        transition: {
          ease: 'easeOut',
          duration: 0.3,
        },
      },
    },
    whileHover: $hovered ? 'hover' : 'default',
  };
};

const commonStyles = css`
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-radius: ${getMDBorderRadius};
`;

export const RowContainer = styled(motion.div).attrs(getRowMotionProps)<IStyledRowProps>`
  ${commonStyles}
`;

export const ListRowContainer = styled(motion.li).attrs(getRowMotionProps)<IStyledRowProps>`
  ${commonStyles}
`;
