import * as React from 'react';
function SvgEditorTextUnderline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.172 16.665h10.37M5.172 3.332v5.185a5.181 5.181 0 005.185 5.185 5.181 5.181 0 005.185-5.185V3.332"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgEditorTextUnderline;
