import { GA4_ID } from './constants';

interface IEventParams {
  step?: string;
  action?: string;
  source?: string;
  method?: string;
  userType?: string;
  eventName?: string;
}

export const trackAnalyticEvent = (params: IEventParams) => {
  const { step, action, source, method, userType, eventName = 'signup' } = params;

  window.gtag('event', eventName, {
    step,
    action,
    method,
    send_to: GA4_ID,
    user_type: userType,
    source_page: source,
  });
};
