import React from 'react';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { getBlackColor, getGray800Color } from 'core/theme/helpers';
import { ButtonTrigger } from 'shared/components/triggers';

import { IDatePickerTriggerIProps } from '../model';

const StyledButtonTrigger = styled(ButtonTrigger)`
  color: ${getGray800Color};

  &.selected {
    color: ${getBlackColor};
  }
`;

export const DatePickerTrigger = (props: IDatePickerTriggerIProps) => {
  const { label, value, variant = 'ghost', className, dateFormat, placeholder, ...otherProps } = props;

  const isSelected = DateTime.isDateTime(value);

  const classes = isSelected ? `${className} selected` : className;

  const _getLabel = () => {
    if (isSelected) {
      return label || value.toFormat(dateFormat);
    }

    return placeholder;
  };

  return <StyledButtonTrigger {...otherProps} label={_getLabel()} variant={variant} className={classes} />;
};
