import React, { FC } from 'react';
import styled, { ThemedStyledProps } from 'styled-components';
import { ITheme } from 'core/theme/theme.interface';
import { getSMFontSize, getBlackColor } from 'core/theme/helpers';

import { IndicatorStatus, IStatusIndicatorProps } from './interfaces';

const getIndicatorColor = ({ $status, theme }: ThemedStyledProps<IStyledWrapperProps, ITheme>) => {
  switch ($status) {
    case IndicatorStatus.Error:
      return theme.colors.red400;
    case IndicatorStatus.Success:
      return theme.colors.primary_green;
    default:
      return theme.colors.blue300;
  }
};

const Wrapper = styled.div<IStyledWrapperProps>`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${getIndicatorColor};

    min-width: 8px;
  }
`;

const Text = styled.span`
  color: ${getBlackColor};
  font-size: ${getSMFontSize};
  margin-left: 8px;
`;

interface IStyledWrapperProps {
  $status?: IndicatorStatus;
}

export const StatusIndicator: FC<IStatusIndicatorProps> = (props) => {
  const { status, children } = props;

  return (
    <Wrapper $status={status}>
      <Text>{children || status}</Text>
    </Wrapper>
  );
};
