import * as React from 'react';
function SvgEditorNumberList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.476 7.305v-5.39h-1.34v.673h-1.34v1.347h1.34v3.37h-1.34v1.347h4.02V7.305h-1.34zm1.34 10.78h-4.02V15.39c0-.357.142-.7.393-.953.251-.252.592-.394.948-.394h1.34v-1.348h-2.68v-1.348h2.68c.354 0 .695.142.946.395.252.253.393.596.393.953v1.348c0 .357-.141.7-.393.953a1.336 1.336 0 01-.947.394h-1.34v1.348h2.68v1.347zM9.266 14.716c0-.372.301-.673.673-.673h8.03a.674.674 0 010 1.347H9.94a.674.674 0 01-.673-.674zm0-9.433c0-.372.301-.674.673-.674h8.03a.674.674 0 010 1.348H9.94a.674.674 0 01-.673-.674z"
        fill="#8C939F"
      />
    </svg>
  );
}
export default SvgEditorNumberList;
