import { ThemedStyledProps } from 'styled-components';

import { ITheme } from '../theme.interface';

export const getXXSFontSize = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.font.size.xxs;

export const getXSFontSize = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.font.size.xs;

export const getSMFontSize = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.font.size.sm;

export const getMDFontSize = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.font.size.md;

export const getLGFontSize = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.font.size.lg;

export const getXLFontSize = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.font.size.xl;

export const getXXLFontSize = <P>({ theme }: ThemedStyledProps<P, ITheme>) => theme.font.size.xxl;
