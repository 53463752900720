import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { getBlue300Color, getGray500Color, getWhiteColor } from 'core/theme/helpers';
import { CrossLg } from 'shared/components/icons';

import { IFileLoaderProps } from '../model';

const FileLoaderContainer = styled.div`
  position: relative;

  & .progress-icon {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);

    & circle {
      fill: transparent;
      stroke: ${getWhiteColor};

      &:last-child {
        stroke: ${getBlue300Color};
      }
    }
  }
`;

const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background: ${getGray500Color};
  border-radius: 50%;
`;

export const FileLoader = forwardRef<HTMLDivElement, IFileLoaderProps>((props, ref) => {
  const { size = 34, progress = 0, className, strokeWidth = 2, onCancel } = props;

  const radius = size > 0 ? size / 2 : 0;
  const viewportSize = size + strokeWidth;

  const coordOffset = viewportSize / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference * ((100 - progress) / 100);

  return (
    <FileLoaderContainer ref={ref} style={{ width: size, height: size }} className={className}>
      <svg viewBox={`0 0 ${viewportSize} ${viewportSize}`} className="progress-icon">
        <circle r={radius} cx={coordOffset} cy={coordOffset} strokeWidth={strokeWidth} />

        <circle
          r={radius}
          cx={coordOffset}
          cy={coordOffset}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={isNaN(progressOffset) ? 0 : progressOffset}
        />
      </svg>

      <CancelButton onClick={onCancel}>
        <CrossLg width="75%" height="75%" />
      </CancelButton>
    </FileLoaderContainer>
  );
});

FileLoader.displayName = 'FileLoader';
