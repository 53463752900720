import React from 'react';
import styled from 'styled-components';
import { getXXLBorderRadius } from 'core/theme/helpers';
import { Button } from 'shared/components/button';

import { useDatePicker } from '../../providers';

import { FooterContainer } from './styled';

const ResetButton = styled(Button)`
  width: 100%;
  padding: 10px 12px;
  min-height: auto;
  border-radius: ${getXXLBorderRadius};
  justify-content: center;
`;

interface IProps {
  buttonText: string;
}

export const ResetActionFooter = (props: IProps) => {
  const { buttonText } = props;

  const { value, onReset } = useDatePicker();

  const isEmpty = !value;

  return (
    <FooterContainer>
      <ResetButton variant={isEmpty ? 'primary' : 'basic'} onClick={onReset}>
        {buttonText}
      </ResetButton>
    </FooterContainer>
  );
};
