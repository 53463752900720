import React from 'react';
import { PlusSm } from 'shared/components/icons';
import { Tooltip } from 'shared/components/tooltip';
import { ITriggerProps } from 'shared/components/dropdown';
import { IconButton } from 'shared/components/button';

interface IProps extends ITriggerProps {
  tooltip?: string;
  onClick: (event: React.MouseEvent) => void;
}

export const PlusTrigger = (props: IProps) => {
  const { tooltip, disabled, className, getRef, isOpen, onToggle, onClick } = props;

  const classes = isOpen ? `${className} active` : className;

  const _handleClick = (event: React.MouseEvent) => {
    onClick?.(event);
    onToggle();
  };

  return (
    <Tooltip content={tooltip} disabled={disabled || !tooltip} hideOnClick={!disabled}>
      <IconButton ref={getRef} variant="ghost" disabled={disabled} className={classes} onClick={_handleClick}>
        <PlusSm width={18} height={18} />
      </IconButton>
    </Tooltip>
  );
};
