import React, { FC } from 'react';
import styled from 'styled-components';
import { getBlue300Color } from 'core/theme/helpers';

const Wrapper = styled.div`
  height: 8px;
  border-radius: 20px;
  background-color: #1d3268;
`;

const ProgressLine = styled.hr`
  border: none;
  height: 100%;
  margin: 0;
  border-radius: inherit;
  background-color: ${getBlue300Color};
`;

interface IProps {
  progress?: number;
  className?: string;
}

export const ProcessBar: FC<IProps> = (props) => {
  const { progress = 0, className } = props;

  return (
    <Wrapper className={className}>
      <ProgressLine style={{ width: `${progress}%` }} />
    </Wrapper>
  );
};
