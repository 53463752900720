import React, { forwardRef } from 'react';

import { IButtonProps } from '../../model';
import { IconContainer } from '../styled';

import { StyledButton } from './styled';

export const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const {
    variant = 'default',
    children,
    leftIcon,
    rightIcon,
    iconColor = 'self',
    borderRadius = 'sm',
    ...otherProps
  } = props;

  return (
    <StyledButton ref={ref} type="button" $variant={variant} $borderRadius={borderRadius} {...otherProps}>
      {leftIcon && (
        <IconContainer $iconColor={iconColor} className="left-icon-container">
          {leftIcon}
        </IconContainer>
      )}

      {children}

      {rightIcon && (
        <IconContainer $iconColor={iconColor} className="right-icon-container">
          {rightIcon}
        </IconContainer>
      )}
    </StyledButton>
  );
});

Button.displayName = 'Button';
