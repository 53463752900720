import { apolloClient } from '../../../../apollo';
import { Attachment, ATTACHMENT_FRAGMENT } from '../../impl/attachment';

export const getAttachmentFragment = (id: string, optimistic?: boolean) => {
  const __typename: Attachment['__typename'] = 'AttachmentType';

  return apolloClient.readFragment<Attachment>(
    {
      id: apolloClient.cache.identify({ __typename, id }),
      fragment: ATTACHMENT_FRAGMENT,
      fragmentName: 'Attachment',
    },
    optimistic
  );
};
