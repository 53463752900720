import * as React from 'react';
function SvgDotsLg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 13.3a2 2 0 10-4 0 2 2 0 004 0zM15 13.3a2 2 0 10-4 0 2 2 0 004 0zM22 13.3a2 2 0 10-4 0 2 2 0 004 0z"
        fill="#8C939F"
      />
    </svg>
  );
}
export default SvgDotsLg;
