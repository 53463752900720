import * as React from 'react';
function SvgFileMp4(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_mp4_svg__clip0_4576_26631)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#9580FF"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.329 2.329 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#4F38C3"
        />
        <path
          d="M5.231 25.117v5.448H4.07v-6.799h1.1l.062 1.351zm-.237 1.791l-.538-.019c.004-.465.065-.894.181-1.288.117-.398.29-.743.52-1.037.228-.293.514-.52.856-.678A2.723 2.723 0 017.2 23.64c.317 0 .608.046.875.138.267.088.498.228.694.42.196.193.348.44.456.742.108.302.162.666.162 1.094v4.53H8.231v-4.474c0-.356-.06-.64-.181-.854a1.038 1.038 0 00-.5-.465c-.217-.1-.47-.151-.763-.151-.341 0-.627.06-.856.182-.229.122-.412.29-.55.503a2.27 2.27 0 00-.3.735 4.134 4.134 0 00-.087.867zm4.381-.64l-.775.238a3.44 3.44 0 01.181-1.075c.121-.343.294-.649.519-.917.23-.268.51-.48.844-.635.333-.159.714-.239 1.143-.239.363 0 .684.049.963.145.283.096.52.245.713.446.195.197.343.45.443.76.1.31.15.68.15 1.107v4.468h-1.162v-4.481c0-.381-.06-.677-.181-.886a.946.946 0 00-.5-.446 1.989 1.989 0 00-.763-.132c-.254 0-.48.044-.675.132-.196.088-.36.21-.494.364-.133.151-.235.325-.306.522-.067.197-.1.406-.1.628zM16.078 25.073v8.107h-1.162v-9.414h1.063l.1 1.307zm4.557 2.036v.132c0 .495-.059.953-.175 1.376a3.393 3.393 0 01-.513 1.094c-.22.31-.494.55-.819.723a2.358 2.358 0 01-1.118.257c-.43 0-.809-.071-1.138-.213a2.243 2.243 0 01-.837-.623c-.23-.272-.413-.599-.55-.98a5.696 5.696 0 01-.275-1.288v-.704a5.94 5.94 0 01.281-1.351c.138-.398.319-.737.544-1.018.229-.285.506-.5.831-.647.325-.151.7-.227 1.125-.227.425 0 .802.084 1.131.252.33.163.607.398.831.704.226.305.394.672.507 1.1.116.422.175.894.175 1.413zm-1.163.132v-.132c0-.339-.035-.657-.106-.955a2.42 2.42 0 00-.331-.792 1.583 1.583 0 00-.563-.54 1.594 1.594 0 00-.819-.201c-.291 0-.545.05-.762.15a1.65 1.65 0 00-.544.41 2.17 2.17 0 00-.369.577c-.091.214-.16.436-.206.666v1.628c.084.293.2.57.35.83.15.255.35.462.6.621.25.155.565.233.944.233.313 0 .581-.065.806-.195.23-.134.417-.316.563-.547.15-.23.26-.494.331-.791.07-.302.106-.622.106-.962zM27.75 27.486v.956h-6.574v-.686l4.075-6.34h.944l-1.013 1.835-2.694 4.235h5.263zm-1.268-6.07v9.15h-1.156v-9.15h1.156z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_mp4_svg__clip0_4576_26631">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileMp4;
