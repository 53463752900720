import styled, { ThemedStyledProps, css } from 'styled-components';
import { ITheme } from 'core/theme';
import { getGray200Color, getGray800Color } from 'core/theme/helpers';

import { AvatarSizeType } from './interfaces';

interface IAvatarContainerProps {
  $size: AvatarSizeType;
}

const getAvatarComponentSizeProps = (props: ThemedStyledProps<IAvatarContainerProps, ITheme>) => {
  const { theme, $size } = props;

  switch ($size) {
    case 'md':
      return css`
        width: 30px;
        height: 30px;
        font-size: ${theme.font.size.xxs};
      `;
    case 'lg':
      return css`
        width: 34px;
        height: 34px;
        font-size: ${theme.font.size.sm};
      `;
    case 'xl':
      return css`
        width: 40px;
        height: 40px;
        font-size: ${theme.font.size.md};
      `;
    default:
      return css`
        width: 26px;
        height: 26px;
        font-size: ${theme.font.size.xxs};
      `;
  }
};

export const AvatarContainer = styled.div<IAvatarContainerProps>`
  ${getAvatarComponentSizeProps}

  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  color: ${getGray800Color};
  overflow: hidden;
  background: ${getGray200Color};
  box-sizing: border-box;
  border-radius: 50%;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
