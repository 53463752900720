import React, { FC } from 'react';
import styled from 'styled-components';
import { getBlue300Color, getLGFontSize, getBlue700Color } from 'core/theme/helpers';
import offlinePNG from 'shared/assets/images/offline.png';
import circleBigBackgroundSVG from 'shared/assets/svg/circle-big-background.svg';
import circleSmallBackgroundSVG from 'shared/assets/svg/circle-small-background.svg';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${getBlue700Color};
`;

const BackgroundImage = styled.img`
  position: absolute;
  mix-blend-mode: overlay;
`;

const BigBackgroundCircleImage = styled(BackgroundImage)`
  top: 0;
  right: 0;
`;

const SmallBackgroundCircleImage = styled(BackgroundImage)`
  left: 0;
  bottom: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  z-index: 2;
`;

const OffileImage = styled.img`
  width: 466px;
  height: 312px;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  line-height: 32px;
  text-align: center;

  margin: 0 0 23px 0;
  max-width: 400px;
`;

const Message = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${getLGFontSize};
  line-height: 32px;

  margin: 0;
  padding: 4px 24px;
  border-radius: 8px;
  background-color: ${getBlue300Color};
`;

export const OfflineModule: FC = () => {
  return (
    <Layout>
      <BigBackgroundCircleImage src={circleBigBackgroundSVG} alt="background" />
      <SmallBackgroundCircleImage src={circleSmallBackgroundSVG} alt="background" />

      <Content>
        <OffileImage src={offlinePNG} alt="offline" />
        <Description>
          Bordio is taking a quick nap and will get back to you more powerful and robust shortly.
        </Description>
        <Message>Please check back again soon!</Message>
      </Content>
    </Layout>
  );
};
