import * as React from 'react';
function SvgEditSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.456 17.035H17.5" stroke="#8C939F" strokeWidth={1.2} strokeLinecap="round" strokeLinejoin="round" />
      <path
        clipRule="evenodd"
        d="M10.65 3.162a1.871 1.871 0 012.597-.252l1.444 1.122a1.785 1.785 0 01.6 2.487c-.028.045-7.948 9.951-7.948 9.951a1.416 1.416 0 01-1.09.528l-3.033.038-.684-2.892c-.095-.407 0-.834.264-1.163l7.85-9.819z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.184 5.002l4.544 3.49"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgEditSm;
