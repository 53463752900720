import * as React from 'react';
function SvgEditorTextItalic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.438 3.332h7.475-7.475zM4.802 16.665h7.475-7.475zM12.18 3.332L8.543 16.665 12.18 3.332z"
        fill="#D9D9D9"
      />
      <path
        d="M8.438 3.332h7.475M4.802 16.665h7.475M12.18 3.332L8.543 16.665"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgEditorTextItalic;
