import React from 'react';
import {
  NodeKey,
  LexicalNode,
  EditorConfig,
  DOMExportOutput,
  DOMConversionMap,
  DOMConversionOutput,
  $applyNodeReplacement,
  DecoratorNode,
  LexicalEditor,
} from 'lexical';

import { MENTION_BLOCK_TYPE } from '../../model';

import { SerializedMentionNode, MentionNodeDataType } from './interfaces';

function $convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
  const data = domNode.getAttribute('data-lexical-mention-data');
  const value = domNode.getAttribute('data-lexical-mention-value');
  const trigger = domNode.getAttribute('data-lexical-mention-trigger');

  let parsedData: MentionNodeDataType | undefined;

  if (data) {
    try {
      parsedData = JSON.parse(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Failed to parse data attribute of mention node', error);
    }
  }

  if (value !== null && trigger !== null) {
    const node = $createMentionNode(trigger, value, parsedData);

    return { node };
  }

  return null;
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode;
}

export function $createMentionNode(trigger: string, value: string, data?: MentionNodeDataType): MentionNode {
  const mentionNode = new MentionNode(trigger, value, data);

  return $applyNodeReplacement(mentionNode);
}

export class MentionNode extends DecoratorNode<JSX.Element> {
  __data?: MentionNodeDataType;

  __value: string;

  __trigger: string;

  constructor(trigger: string, value: string, data?: MentionNodeDataType, key?: NodeKey) {
    super(key);

    if (data && Object.keys(data).length > 0) {
      this.__data = data;
    }

    this.__value = value;
    this.__trigger = trigger;
  }

  static getType(): string {
    return MENTION_BLOCK_TYPE;
  }

  public getData(): MentionNodeDataType | undefined {
    const self = this.getLatest();

    return self.__data;
  }

  public getValue(): string {
    const self = this.getLatest();

    return self.__value;
  }

  public getTrigger(): string {
    const self = this.getLatest();

    return self.__trigger;
  }

  public getTextContent(): string {
    const self = this.getLatest();

    return self.__trigger + self.__value;
  }

  public setData(data?: MentionNodeDataType) {
    const self = this.getWritable();

    self.__data = data;
  }

  public setValue(value: string) {
    const self = this.getWritable();

    self.__value = value;
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__trigger, node.__value, node.__data, node.__key);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    return <>{this.getTextContent()}</>;
  }

  public isTextEntity(): true {
    return true;
  }

  public canInsertTextAfter(): boolean {
    return false;
  }

  public canInsertTextBefore(): boolean {
    return false;
  }

  public exportJSON(): SerializedMentionNode {
    const data = this.__data;

    return {
      ...data,
      type: MENTION_BLOCK_TYPE,
      value: this.__value,
      trigger: this.__trigger,
      version: 1,
    };
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(serializedNode.trigger, serializedNode.value, serializedNode.data);

    return node;
  }

  public createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('span');

    if (config.theme.mention) {
      element.className = config.theme.mention;
    }

    return element;
  }

  public updateDOM(): boolean {
    return false;
  }

  public exportDOM(): DOMExportOutput {
    const element = document.createElement('span');

    element.setAttribute('data-lexical-mention', 'true');
    element.setAttribute('data-lexical-mention-value', this.__value);
    element.setAttribute('data-lexical-mention-trigger', this.__trigger);

    if (this.__data) {
      try {
        element.setAttribute('data-lexical-mention-data', JSON.stringify(this.__data));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Failed to set data attribute of mention node', error);
      }
    }

    element.textContent = this.getTextContent();

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null;
        }

        return {
          priority: 1,
          conversion: $convertMentionElement,
        };
      },
    };
  }
}
