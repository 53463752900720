import * as React from 'react';
function SvgCompleteXl(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={0.6} y={0.6} width={28.8} height={28.8} rx={14.4} stroke="#8C939F" strokeWidth={1.2} />
      <path
        d="M9 15.095l4.097 4.095 8.19-8.19"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgCompleteXl;
