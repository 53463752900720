import * as React from 'react';
function SvgFileExe(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_exe_svg__clip0_4576_26685)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#35B348"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.329 2.329 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#2B7A39"
        />
        <path
          d="M9.443 30.706c-.53 0-1.01-.09-1.441-.268a3.268 3.268 0 01-1.104-.77 3.43 3.43 0 01-.704-1.174 4.325 4.325 0 01-.246-1.485v-.297c0-.622.092-1.176.275-1.661a3.72 3.72 0 01.745-1.244c.314-.34.67-.597 1.069-.771.398-.174.81-.262 1.237-.262.544 0 1.013.095 1.406.283.399.189.725.453.978.792.253.335.44.73.562 1.188.122.452.183.947.183 1.484v.587H6.722v-1.067h4.38v-.1a3.275 3.275 0 00-.21-.99 1.78 1.78 0 00-.563-.791c-.258-.207-.61-.311-1.055-.311a1.763 1.763 0 00-1.455.742 2.782 2.782 0 00-.415.898 4.574 4.574 0 00-.148 1.223v.297c0 .363.05.705.148 1.025.103.316.25.594.443.834.197.24.433.43.71.566.281.136.6.205.956.205.46 0 .849-.094 1.167-.283.32-.188.598-.44.837-.756l.788.629c-.164.25-.373.488-.626.714-.253.226-.565.41-.935.551-.366.142-.8.212-1.301.212zM14.369 22.916l1.666 2.785 1.688-2.785h1.525L16.76 26.69l2.567 3.874H17.82l-1.758-2.87-1.757 2.87h-1.512l2.56-3.874-2.483-3.775h1.498zM23.218 30.706c-.53 0-1.01-.09-1.441-.268a3.268 3.268 0 01-1.104-.77 3.43 3.43 0 01-.703-1.174 4.324 4.324 0 01-.246-1.485v-.297c0-.622.09-1.176.274-1.661.182-.49.43-.905.745-1.244.314-.34.67-.597 1.069-.771a3.056 3.056 0 011.237-.262c.544 0 1.013.095 1.407.283.398.189.724.453.977.792.253.335.44.73.562 1.188.122.452.183.947.183 1.484v.587h-5.681v-1.067h4.38v-.1c-.018-.339-.089-.669-.21-.99a1.78 1.78 0 00-.563-.791c-.258-.207-.61-.311-1.055-.311a1.763 1.763 0 00-1.455.742 2.784 2.784 0 00-.415.898 4.576 4.576 0 00-.148 1.223v.297c0 .363.05.705.148 1.025.103.316.25.594.443.834.197.24.433.43.71.566.281.136.6.205.956.205.46 0 .849-.094 1.168-.283.318-.188.597-.44.836-.756l.788.629c-.164.25-.373.488-.626.714-.253.226-.565.41-.935.551-.366.142-.8.212-1.301.212z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_exe_svg__clip0_4576_26685">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileExe;
