import styled, { ThemedStyledProps, css } from 'styled-components';
import { ITheme } from 'core/theme';
import { getBlue300Color, getGray800Color, getSMFontSize, getBlackColor } from 'core/theme/helpers';

import { IStyledClassicRadioProps } from '../model';

const getSizeStyles = (props: ThemedStyledProps<IStyledClassicRadioProps, ITheme>) => {
  const { $size } = props;

  let size = 18;

  switch ($size) {
    case 'md': {
      size = 20;

      break;
    }
    case 'lg': {
      size = 22;

      break;
    }
  }

  return css`
    width: ${size}px;
    height: ${size}px;
  `;
};

export const ClassicRadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ClassicRadioInput = styled.input<IStyledClassicRadioProps>`
  ${getSizeStyles}

  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 1px solid ${getBlue300Color};
  appearance: none;
  box-sizing: border-box;
  flex-shrink: 0;
  accent-color: ${getBlue300Color};
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 60%;
    transform: translate(-50%, -50%);
    transition: background 300ms ease-out;
    border-radius: 50%;
  }

  &:checked {
    &::before {
      background: ${getBlue300Color};
    }
  }

  &:disabled {
    cursor: default;
    border: 1px solid ${getGray800Color};

    &:checked {
      &::before {
        background: ${getGray800Color};
      }
    }
  }
`;

export const ClassicRadioLabel = styled.label`
  color: ${getBlackColor};
  font-size: ${getSMFontSize};
`;
