import React, { FC } from 'react';
import styled from 'styled-components';
import { getGray400Color } from 'core/theme/helpers';

import { SimpleBar } from '../simplebar/SimpleBar';

import { ITabsProps } from './interfaces';
import { TABS_HEIGHT } from './constants';
import { TabItem } from './TabItem';

const TabsGroup = styled.div`
  display: flex;
  height: ${TABS_HEIGHT}px;
  padding: 0 44px;
  min-width: max-content;
  box-sizing: border-box;
  border-bottom: 1px solid ${getGray400Color};

  & > *:not(:last-child) {
    margin-right: 40px;
  }
`;

export const Tabs: FC<ITabsProps> = (props) => {
  const { tabs, active, children, onChange } = props;

  return (
    <>
      <SimpleBar style={{ width: '100%' }}>
        <TabsGroup>
          {tabs.map((item) => (
            <TabItem key={item.tabName} item={item} isActive={active.tabName === item.tabName} onChange={onChange} />
          ))}
        </TabsGroup>
      </SimpleBar>

      {children}
    </>
  );
};
