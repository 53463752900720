import { createCommand, LexicalCommand } from 'lexical';

import { IImagePayload } from '../../nodes/image-node';

export interface IUploadImagePayload {
  file: File;
}

export interface IRemoveImagePayload {
  nodeKey: string;
}

export const INSERT_IMAGE_COMMAND: LexicalCommand<IImagePayload> = createCommand('INSERT_IMAGE_COMMAND');

export const UPLOAD_IMAGE_COMMAND: LexicalCommand<IUploadImagePayload> = createCommand('UPLOAD_IMAGE_COMMAND');

export const REMOVE_IMAGE_COMMAND: LexicalCommand<IRemoveImagePayload> = createCommand('REMOVE_IMAGE_COMMAND');
