import React, { forwardRef } from 'react';

import { IClassicCheckboxProps } from '../model';

import { ClassicCheckboxContainer, ClassicCheckboxLabelText, HiddenCheckbox, FakeCheckbox, StyledIcon } from './styled';

export const ClassicCheckbox = forwardRef<HTMLInputElement, IClassicCheckboxProps>((props, ref) => {
  const {
    size = 'md',
    label,
    checked,
    variant = 'square',
    disabled,
    className,
    labelPosition = 'left',
    ...otherProps
  } = props;

  return (
    <ClassicCheckboxContainer className={className}>
      <HiddenCheckbox ref={ref} type="checkbox" checked={checked} disabled={disabled} {...otherProps} />

      {label && <ClassicCheckboxLabelText $position={labelPosition}>{label}</ClassicCheckboxLabelText>}

      <FakeCheckbox
        tabIndex={0}
        $size={size}
        $variant={variant}
        $checked={checked}
        $disabled={disabled}
        data-testid="terms-checkbox"
      >
        <StyledIcon $size={size} />
      </FakeCheckbox>
    </ClassicCheckboxContainer>
  );
});

ClassicCheckbox.displayName = 'ClassicCheckbox';
