export interface FileInfo {
  dataURL: string;
  name: string;
  size: number;
  type: string;
}

export const processFile = (file: File): Promise<FileInfo> => {
  const { name, size, type } = file;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = (event: ProgressEvent<FileReader>) => {
      resolve({
        name,
        size,
        type,
        dataURL: event.target?.result as string,
      });
    };

    reader.readAsDataURL(file);
  });
};

export const processFiles = async (files: File[] | FileList): Promise<FileInfo[]> => {
  const preparedArray = Array.isArray(files) ? files : Array.from(files);

  return Promise.all(preparedArray.map(processFile));
};
