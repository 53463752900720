import * as React from 'react';
function SvgDeleteSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.104 7.89s-.453 5.613-.715 7.977c-.125 1.13-.823 1.791-1.965 1.812-2.174.04-4.35.042-6.524-.004-1.1-.023-1.785-.693-1.908-1.802-.264-2.385-.714-7.982-.714-7.982M17.257 5.202H3.125M14.534 5.2a1.373 1.373 0 01-1.346-1.103l-.203-1.013a1.066 1.066 0 00-1.03-.791H8.427c-.482 0-.906.323-1.03.79l-.203 1.014A1.373 1.373 0 015.848 5.2M9.071 14.316l-.355-5.288M11.404 14.315l.325-5.29"
        stroke="#F94747"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgDeleteSm;
