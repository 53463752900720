import { getFileSize } from 'shared/helpers/files';

import {
  UploadStage,
  UploadStatus,
  UploaderEventMap,
  UploaderEventType,
  UploaderOnErrorFunction,
  UploaderOnCompleteFunction,
  UploaderOnProgressFunction,
  UploaderOnStageChangeFunction,
  UploaderOnStatusChangeFunction,
  UploaderSourceType,
  IUploaderMutationData,
} from './interfaces';

export class Uploader {
  private events: Map<UploaderEventType, Set<UploaderEventMap<this>[UploaderEventType]>> = new Map();

  public stage: UploadStage;

  public status: UploadStatus;

  public fileURL: string | null;

  public totalSize: number;

  public uploadedSize: number;

  public readonly source: UploaderSourceType;

  public readonly mutationData: IUploaderMutationData;

  constructor(source: UploaderSourceType, mutationData: IUploaderMutationData) {
    this.stage = UploadStage.INITIAL;
    this.status = UploadStatus.PENDING;
    this.source = source;
    this.fileURL = null;
    this.totalSize = getFileSize(source);
    this.uploadedSize = 0;
    this.mutationData = mutationData;
  }

  public onError?: UploaderOnErrorFunction<this>;

  public onProgress?: UploaderOnProgressFunction<this>;

  public onComplete?: UploaderOnCompleteFunction<this>;

  public onStageChange?: UploaderOnStageChangeFunction<this>;

  public onStatusChange?: UploaderOnStatusChangeFunction<this>;

  protected callListeners = <T extends UploaderEventType>(type: T, ...args: Parameters<UploaderEventMap<this>[T]>) => {
    const listeners = this.events.get(type) as Set<UploaderOnErrorFunction<this>> | undefined;

    if (listeners) {
      listeners.forEach((listener) => {
        // @ts-ignore
        listener(...args);
      });
    }
  };

  protected callErrorListeners: UploaderOnErrorFunction<this> = (error, uploader) => {
    this.onError?.(error, uploader);
    this.callListeners('error', error, uploader);
  };

  protected callProgressListeners: UploaderOnProgressFunction<this> = (progress, uploader) => {
    this.onProgress?.(progress, uploader);
    this.callListeners('progress', progress, uploader);
  };

  protected callCompleteListeners: UploaderOnCompleteFunction<this> = () => {
    this.onComplete?.(this);
    this.callListeners('complete', this);
  };

  protected callStageChangeListeners: UploaderOnStageChangeFunction<this> = (stage, uploader) => {
    this.onStageChange?.(stage, uploader);
    this.callListeners('stageChange', stage, uploader);
  };

  protected callStatusChangeListeners: UploaderOnStatusChangeFunction<this> = (status, uploader) => {
    this.onStatusChange?.(status, uploader);
    this.callListeners('statusChange', status, uploader);
  };

  public readonly addEventListener = <T extends UploaderEventType>(type: T, listener: UploaderEventMap<this>[T]) => {
    if (this.events.has(type)) {
      const listeners = this.events.get(type);

      if (listeners) {
        listeners.add(listener);
      }

      return;
    }

    this.events.set(type, new Set([listener]));
  };

  public readonly removeEventListener = <T extends UploaderEventType>(type: T, listener: UploaderEventMap<this>[T]) => {
    if (this.events.has(type)) {
      const listeners = this.events.get(type);

      if (listeners) {
        listeners.delete(listener);
      }
    }
  };
}
