import React, { useMemo } from 'react';
import { RRule } from 'rrule';
import { DateTime } from 'luxon';

import { DatePickerContext } from './context';

interface IProps {
  value?: DateTime | null;
  rrule?: RRule;
  children: React.ReactNode;
  firstDayOfWeek: number;
  dateDisplayFormat: string;
  disabledAfterDate?: DateTime;
  disabledBeforeDate?: DateTime;
  onReset: () => void;
  onChange: (value: DateTime) => void;
  onRRuleChange?: (value: RRule) => void;
}

export const DatePickerProvider = (props: IProps) => {
  const {
    value,
    children,
    rrule,
    firstDayOfWeek,
    dateDisplayFormat,
    disabledAfterDate,
    disabledBeforeDate,
    onReset,
    onChange,
    onRRuleChange,
  } = props;

  const values = useMemo(
    () => ({
      value,
      rrule,
      firstDayOfWeek,
      dateDisplayFormat,
      disabledAfterDate,
      disabledBeforeDate,
      onReset,
      onChange,
      onRRuleChange,
    }),
    [
      value,
      rrule,
      firstDayOfWeek,
      dateDisplayFormat,
      disabledAfterDate,
      disabledBeforeDate,
      onReset,
      onChange,
      onRRuleChange,
    ]
  );

  return <DatePickerContext.Provider value={values}>{children}</DatePickerContext.Provider>;
};
