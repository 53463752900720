import * as React from 'react';
function SvgCategorySm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.75 7.5h1.5c1.5 0 2.25-.75 2.25-2.25v-1.5c0-1.5-.75-2.25-2.25-2.25h-1.5c-1.5 0-2.25.75-2.25 2.25v1.5c0 1.5.75 2.25 2.25 2.25zM12.75 7.5h1.5c1.5 0 2.25-.75 2.25-2.25v-1.5c0-1.5-.75-2.25-2.25-2.25h-1.5c-1.5 0-2.25.75-2.25 2.25v1.5c0 1.5.75 2.25 2.25 2.25zM12.75 16.5h1.5c1.5 0 2.25-.75 2.25-2.25v-1.5c0-1.5-.75-2.25-2.25-2.25h-1.5c-1.5 0-2.25.75-2.25 2.25v1.5c0 1.5.75 2.25 2.25 2.25zM3.75 16.5h1.5c1.5 0 2.25-.75 2.25-2.25v-1.5c0-1.5-.75-2.25-2.25-2.25h-1.5c-1.5 0-2.25.75-2.25 2.25v1.5c0 1.5.75 2.25 2.25 2.25z"
        stroke="#8C939F"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgCategorySm;
