import * as React from 'react';
function SvgClipSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.7 10.3l-3.847 3.847C7.049 17.951.685 11.587 4.489 7.783l4.225-4.225c2.82-2.82 7.246 1.608 4.427 4.428l-3.956 3.956c-1.41 1.41-3.762-.943-2.352-2.352l3.579-3.579"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
      />
    </svg>
  );
}
export default SvgClipSm;
