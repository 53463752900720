import * as React from 'react';
function SvgFileAi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_ai_svg__clip0_4576_26696)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#F24523"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.329 2.329 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#AC3A22"
        />
        <path
          d="M15.226 29.258V25.32c0-.302-.06-.563-.183-.785a1.199 1.199 0 00-.534-.523c-.24-.122-.534-.184-.886-.184-.328 0-.616.057-.865.17a1.507 1.507 0 00-.576.445.98.98 0 00-.204.594h-1.301c0-.273.07-.544.21-.813.141-.269.343-.511.606-.728.267-.222.585-.396.956-.523a3.75 3.75 0 011.251-.198c.554 0 1.041.094 1.463.283.426.188.76.473.998.855.244.377.366.85.366 1.421v3.563c0 .255.021.526.063.813.047.287.115.535.204.742v.113h-1.357a2.454 2.454 0 01-.155-.6 4.872 4.872 0 01-.056-.707zm.225-3.33l.014.919H14.15c-.37 0-.7.03-.991.092-.29.056-.534.143-.731.261a1.24 1.24 0 00-.45.446c-.103.174-.155.38-.155.615 0 .24.054.46.162.657.108.198.27.356.485.474.22.113.49.17.808.17.399 0 .75-.085 1.055-.255.305-.17.546-.377.724-.622.183-.245.282-.483.296-.714l.555.63a1.905 1.905 0 01-.267.657 3.137 3.137 0 01-1.441 1.237 2.78 2.78 0 01-1.111.212c-.516 0-.968-.102-1.357-.304a2.326 2.326 0 01-.9-.813 2.161 2.161 0 01-.317-1.152c0-.41.08-.771.24-1.082.159-.316.388-.578.688-.785.3-.212.661-.372 1.083-.48a5.682 5.682 0 011.413-.163h1.512zM19.554 22.916v7.65h-1.307v-7.65h1.307zm-1.406-2.029c0-.212.063-.39.19-.537.131-.146.323-.22.576-.22.249 0 .439.074.57.22a.76.76 0 01.204.537.742.742 0 01-.204.523c-.131.142-.321.212-.57.212-.253 0-.445-.07-.576-.212a.772.772 0 01-.19-.523z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_ai_svg__clip0_4576_26696">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileAi;
