import React from 'react';
import { Helmet } from 'react-helmet';

interface IProps {
  title: string;
  description?: string;
}

export const PageHelmet = (props: IProps) => {
  const { title, description } = props;

  return (
    <Helmet>
      <title>{title}</title>

      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};
