import { DateTime, DateTimeOptions } from 'luxon';

export const toDateTime = (date: DateTime | string, options?: DateTimeOptions) => {
  return DateTime.isDateTime(date) ? DateTime.fromObject(date.toObject(), options) : DateTime.fromISO(date, options);
};

export const toZeroUTCWithSameDate = (d: DateTime) => {
  return DateTime.fromObject(
    {
      year: d.year,
      month: d.month,
      day: d.day,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    },
    { zone: 'utc' }
  );
};

export const toUTCDayEndWithSameDate = (d: DateTime) => {
  return DateTime.fromObject(
    {
      year: d.year,
      month: d.month,
      day: d.day,
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    },
    { zone: 'utc' }
  );
};

export const differenceInSeconds = (d1: DateTime, d2: DateTime, absolute = true) => {
  if (!absolute) return Math.floor(d1.diff(d2, 'second').toObject().seconds || 0);

  return Math.floor(Math.abs(d1.diff(d2, 'second').toObject().seconds || 0));
};

export const differenceInDays = (d1: DateTime, d2: DateTime, absolute = true) => {
  if (!absolute) return Math.floor(d1.diff(d2, 'day').toObject().days || 0);

  return Math.floor(Math.abs(d1.diff(d2, 'day').toObject().days || 0));
};

export const differenceInMonths = (d1: DateTime, d2: DateTime, absolute = false) => {
  if (!absolute) return Math.floor(d1.diff(d2, 'month').toObject().months || 0);

  return Math.floor(Math.abs(d1.diff(d2, 'month').toObject().months || 0));
};
