import { useCallback } from 'react';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { useEventEmitter, EventType } from 'providers/event-subscription';
import { useNotification, EAppearanceType } from 'providers/notification';
import config from 'shared/config/config';

import { createTransaction, releaseTransaction } from '../../../graphql/transaction';
import {
  DeleteAttachment,
  DeleteAttachmentVariables,
  DELETE_ATTACHMENT_MUTATION,
} from '../../../graphql/mutations/attachment';
import { IDeletedAttachment } from '../../../graphql/subscriptions/attachment';
import { IDeleteAttachmentInput } from '../interfaces';
import { transformDeleteAttachmentInput } from '../transformers';

interface IUseDeleteAttachment {
  input: IDeleteAttachmentInput;
}

export const useDeleteAttachmentCommand = (
  options?: MutationHookOptions<DeleteAttachment, DeleteAttachmentVariables>
) => {
  const emitEvent = useEventEmitter();
  const { addNotification } = useNotification();

  const [deleteAttachmentMutation, mutationResult] = useMutation<DeleteAttachment, DeleteAttachmentVariables>(
    DELETE_ATTACHMENT_MUTATION,
    options
  );

  const _deleteAttachment = useCallback(
    async (params: IUseDeleteAttachment) => {
      const { input } = params;

      const transaction = createTransaction();

      const { deletingData, mutationInput } = transformDeleteAttachmentInput(input);

      if (config.events.local) {
        emitEvent<IDeletedAttachment>(deletingData, {
          type: EventType.ATTACHMENT_DELETED,
          optimisticTransaction: transaction,
        });
      }

      try {
        await deleteAttachmentMutation({
          variables: {
            input: mutationInput,
            transaction,
          },
        });
      } catch (error) {
        const message = (error as Error).message;

        addNotification(message, EAppearanceType.error);

        releaseTransaction(transaction);
      }
    },
    [deleteAttachmentMutation]
  );

  return [_deleteAttachment, mutationResult] as const;
};
