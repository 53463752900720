import * as React from 'react';
function SvgDoubleCheckMarkSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 9l4 4.375L13.571 4m-4 8.125L11.286 14 17 7.75"
        stroke="#8C939F"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgDoubleCheckMarkSm;
