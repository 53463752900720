import React, { useMemo } from 'react';
import { getRandomNumber } from 'shared/helpers/number';

import { IClassicRadioProps } from '../model';

import { ClassicRadioContainer, ClassicRadioInput, ClassicRadioLabel } from './styled';

export const ClassicRadio = (props: IClassicRadioProps) => {
  const { size = 'md', label, disabled, className, ...otherProps } = props;

  const radioBtnId = useMemo(() => {
    return `radio-${getRandomNumber(1, 10000000)}`;
  }, []);

  return (
    <ClassicRadioContainer className={className}>
      <ClassicRadioInput id={radioBtnId} type="radio" disabled={disabled} $size={size} {...otherProps} />

      {label && <ClassicRadioLabel htmlFor={radioBtnId}>{label}</ClassicRadioLabel>}
    </ClassicRadioContainer>
  );
};
