import * as React from 'react';
function SvgFileHtml(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_html_svg__clip0_4576_26665)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#FB9A89"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.329 2.329 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#AC5A4F"
        />
        <path
          d="M5.237 20.913v9.652H4.082v-9.652h1.156zm-.274 5.995l-.482-.019a4.11 4.11 0 01.207-1.288 3.22 3.22 0 01.562-1.037 2.508 2.508 0 011.981-.924c.334 0 .634.046.9.139.267.088.494.23.681.427.192.197.338.452.438.767.1.31.15.689.15 1.137v4.455H8.238v-4.468c0-.356-.053-.64-.157-.854a.964.964 0 00-.456-.472c-.2-.1-.446-.15-.737-.15-.288 0-.55.06-.788.182a1.885 1.885 0 00-.606.503 2.532 2.532 0 00-.394.735 2.7 2.7 0 00-.137.867zM13.963 23.766v.892h-3.657v-.892h3.657zm-2.42-1.653H12.7v6.768c0 .23.035.404.106.522.071.117.163.195.275.232.113.038.234.057.363.057.096 0 .196-.009.3-.025a7.69 7.69 0 00.244-.05l.006.948c-.092.03-.213.057-.363.082-.146.03-.323.044-.531.044-.283 0-.544-.057-.781-.17a1.26 1.26 0 01-.569-.565c-.137-.268-.206-.629-.206-1.081v-6.762zM16.469 25.117v5.448h-1.163v-6.799h1.1l.063 1.351zm-.238 1.791l-.537-.019c.004-.465.064-.894.181-1.288.117-.398.29-.744.519-1.037.229-.293.514-.52.856-.679a2.723 2.723 0 011.188-.245c.316 0 .608.046.875.139.266.088.497.228.693.42.196.193.348.44.456.742.109.302.163.666.163 1.094v4.53h-1.156v-4.474c0-.356-.06-.64-.181-.855a1.038 1.038 0 00-.5-.465c-.217-.1-.471-.15-.763-.15-.342 0-.627.06-.856.182-.23.121-.413.289-.55.503a2.27 2.27 0 00-.3.735 4.136 4.136 0 00-.088.867zm4.381-.641l-.774.239c.004-.373.064-.731.18-1.075.122-.343.294-.65.52-.917.229-.268.51-.48.843-.635.334-.16.715-.239 1.144-.239.363 0 .683.049.963.145.283.096.52.245.712.446.196.197.344.45.444.76.1.31.15.679.15 1.106v4.468H23.63v-4.48c0-.381-.06-.677-.181-.886a.946.946 0 00-.5-.446 1.989 1.989 0 00-.762-.132c-.255 0-.48.044-.675.132-.196.088-.36.21-.494.364-.134.15-.236.325-.306.522-.067.197-.1.406-.1.628zM27.8 20.913v9.652h-1.163v-9.652H27.8z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_html_svg__clip0_4576_26665">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileHtml;
