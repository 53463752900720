import * as React from 'react';
function SvgLocationSm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.209 7.086a1.46 1.46 0 101.458 1.46 1.46 1.46 0 00-1.458-1.46zm0 4.167A2.71 2.71 0 017.5 8.545a2.712 2.712 0 012.709-2.71 2.712 2.712 0 012.708 2.71 2.71 2.71 0 01-2.708 2.708z"
        fill="#8C939F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.208 2.918c-3.102 0-5.625 2.547-5.625 5.677 0 3.983 4.687 7.863 5.625 8.07.938-.208 5.625-4.088 5.625-8.07 0-3.13-2.523-5.677-5.625-5.677zm0 15c-1.495 0-6.875-4.627-6.875-9.323 0-3.82 3.084-6.927 6.875-6.927 3.79 0 6.875 3.107 6.875 6.927 0 4.696-5.38 9.323-6.875 9.323z"
        fill="#8C939F"
      />
    </svg>
  );
}
export default SvgLocationSm;
