import * as React from 'react';
function SvgCalendarInscribed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={0.6} y={0.6} width={28.8} height={28.8} rx={14.4} stroke="#8C939F" strokeWidth={1.2} />
      <path
        d="M12 7.5v2.25M18 7.5v2.25M8.625 12.818h12.75M21.75 12.375v6.375c0 2.25-1.125 3.75-3.75 3.75h-6c-2.625 0-3.75-1.5-3.75-3.75v-6.375c0-2.25 1.125-3.75 3.75-3.75h6c2.625 0 3.75 1.5 3.75 3.75z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.771 16.275h.007M17.771 18.525h.007M14.997 16.275h.007M14.997 18.525h.007M12.22 16.275h.007M12.22 18.525h.007"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgCalendarInscribed;
