import { gql } from '@apollo/client';

export const ATTACHMENT_FRAGMENT = gql`
  fragment Attachment on AttachmentType {
    id
    url
    name
    task {
      id
    }
    meta {
      size
      width
      height
      durationSec
    }
    previews {
      url
      resolution
    }
    contentType
    scheduledEvent {
      id
    }
  }
`;
