import * as React from 'react';
function SvgFileTxt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#file_txt_svg__clip0_4576_26680)">
        <path
          d="M2.28 0h19.424L32 10.373v24.315c0 .613-.24 1.201-.67 1.635a2.273 2.273 0 01-1.616.677H2.286a2.273 2.273 0 01-1.617-.677A2.326 2.326 0 010 34.688V2.318A2.335 2.335 0 01.665.681 2.285 2.285 0 012.281 0z"
          fill="#5DB3CE"
        />
        <path
          d="M31.968 10.405h-7.973a2.275 2.275 0 01-1.616-.678 2.329 2.329 0 01-.67-1.635V.022l10.259 10.383z"
          fill="#1E7C95"
        />
        <path
          d="M12.177 22.916v1.004H8.063v-1.004h4.114zm-2.722-1.86h1.301v7.614c0 .26.04.455.12.587.08.132.183.22.31.262.126.042.262.063.407.063.108 0 .22-.009.338-.028.121-.023.213-.042.274-.056l.007 1.067a3.026 3.026 0 01-.408.092c-.164.033-.363.05-.598.05-.319 0-.611-.064-.879-.191a1.418 1.418 0 01-.64-.637c-.154-.301-.232-.707-.232-1.216v-7.606zM14.15 22.916l1.666 2.785 1.687-2.785h1.526l-2.49 3.775 2.567 3.874h-1.504l-1.758-2.87-1.758 2.87h-1.512l2.56-3.874-2.482-3.775h1.497zM23.167 22.916v1.004h-4.113v-1.004h4.113zm-2.72-1.86h1.3v7.614c0 .26.04.455.12.587.08.132.182.22.309.262.126.042.262.063.408.063.108 0 .22-.009.337-.028.122-.023.213-.042.274-.056l.007 1.067a3.03 3.03 0 01-.407.092c-.165.033-.364.05-.598.05-.319 0-.612-.064-.879-.191a1.418 1.418 0 01-.64-.637c-.155-.301-.232-.707-.232-1.216v-7.606z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="file_txt_svg__clip0_4576_26680">
          <path fill="#fff" d="M0 0h32v37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFileTxt;
