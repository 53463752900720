import { omit } from 'lodash';

import { apolloClient } from '../apollo';
import {
  CreateAttachmentUploadURL,
  CreateAttachmentUploadURLVariables,
  CREATE_ATTACHMENT_UPLOAD_URL_MUTATION,
} from '../graphql/mutations/attachment';

import { Uploader } from './Uploader';
import { UploadStage, UploadStatus, UploaderSourceType, IUploaderMutationData } from './interfaces';

export class SinglepartUploader extends Uploader {
  private awsFileUrl: string | null;

  private activeConnection: XMLHttpRequest | null;

  constructor(source: UploaderSourceType, mutationData: IUploaderMutationData) {
    super(source, mutationData);

    this.awsFileUrl = null;
    this.activeConnection = null;
  }

  // starting the multipart upload request
  start() {
    this.stage = UploadStage.STARTED;

    this.callStageChangeListeners?.(this.stage, this);
    this.initialize();
  }

  abort() {
    if (!this.activeConnection || this.status === UploadStatus.ABORTED) {
      return;
    }

    this.status = UploadStatus.ABORTED;

    this.activeConnection?.abort();

    this.callStatusChangeListeners?.(this.status, this);
  }

  retry() {
    if (this.status !== UploadStatus.STOPPED) {
      return;
    }

    this.status = UploadStatus.PENDING;

    this.callStatusChangeListeners?.(this.status, this);
    this.initialize();
  }

  private async initialize() {
    try {
      if (this.stage === UploadStage.STARTED) {
        await this.createUploadUrl();
      }

      if (this.stage === UploadStage.UPLOAD_CREATED) {
        await this.uploadFile();
      }

      this.stage = UploadStage.FINISHED;
      this.status = UploadStatus.COMPLETED;

      this.callStageChangeListeners?.(this.stage, this);
      this.callStatusChangeListeners?.(this.status, this);
      this.callCompleteListeners?.(this);
    } catch (error) {
      if (this.status === UploadStatus.ABORTED) {
        return;
      }

      this.status = UploadStatus.STOPPED;

      this.callStatusChangeListeners?.(this.status, this);
      this.callErrorListeners?.(error as Error, this);
    }
  }

  private async uploadFile() {
    await new Promise((resolve, reject) => {
      if (!this.awsFileUrl) {
        reject(new Error('Couldn’t get the link'));

        return;
      }

      const xhr = new XMLHttpRequest();

      this.activeConnection = xhr;

      xhr.open('PUT', this.awsFileUrl);

      xhr.setRequestHeader('Content-Type', this.mutationData.contentType);

      xhr.onabort = () => {
        this.activeConnection = null;

        reject(new Error('Upload canceled by user'));
      };

      xhr.onerror = () => {
        this.activeConnection = null;

        reject(new Error('Error uploading file'));
      };

      xhr.onloadend = () => {
        if (xhr.status !== 200) {
          reject(new Error(xhr.statusText));

          return;
        }

        this.activeConnection = null;

        resolve(xhr.responseURL);
      };

      xhr.upload.addEventListener('progress', this.handleProgress.bind(this));

      xhr.send(this.source);
    });
  }

  private async createUploadUrl() {
    const createAttachmentUploadUrlResponse = await apolloClient.mutate<
      CreateAttachmentUploadURL,
      CreateAttachmentUploadURLVariables
    >({
      mutation: CREATE_ATTACHMENT_UPLOAD_URL_MUTATION,
      variables: {
        input: omit(this.mutationData, 'transaction'),
        transaction: this.mutationData.transaction,
      },
    });

    const awsFileData = createAttachmentUploadUrlResponse.data?.createAttachmentUploadUrl;

    if (!awsFileData) {
      throw new Error('Failed to upload file');
    }

    this.fileURL = awsFileData.attachmentUrl;
    this.awsFileUrl = awsFileData.url;

    this.stage = UploadStage.UPLOAD_CREATED;

    this.callStageChangeListeners?.(this.stage, this);
  }

  private handleProgress(event: ProgressEvent<XMLHttpRequestEventTarget>) {
    const sent = event.loaded;
    const progress = Math.round((sent / this.totalSize) * 100);

    this.uploadedSize += progress;

    this.callProgressListeners?.(
      {
        sent,
        total: this.totalSize,
        status: this.status,
        progress,
      },
      this
    );
  }
}
