import * as React from 'react';
function SvgFolderEmptySm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.333 9.166v5c0 3.333-.833 4.167-4.166 4.167H5.833c-3.333 0-4.166-.834-4.166-4.167V5.833c0-3.334.833-4.167 4.166-4.167h1.25c1.25 0 1.525.367 2 1l1.25 1.667c.317.416.5.666 1.334.666h2.5c3.333 0 4.166.834 4.166 4.167z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgFolderEmptySm;
