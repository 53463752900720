import * as React from 'react';
function SvgIndicatorDecline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={6} cy={6.5} r={6} fill="#F94747" />
      <path
        d="M6.572 6.5l2.54-2.541a.404.404 0 10-.571-.571l-2.54 2.538-2.543-2.538a.404.404 0 00-.57.57L5.427 6.5l-2.54 2.541a.404.404 0 00.572.572L6 7.072l2.541 2.54a.404.404 0 10.571-.57L6.572 6.5z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgIndicatorDecline;
