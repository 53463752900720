import { AttachmentKind, AttachmentReferenceType } from 'core/graphql/interfaces/graphql.interfaces';

export enum UploadStage {
  INITIAL,
  STARTED,
  UPLOAD_CREATED,
  UPLOAD_URLS_CREATED,
  FINISHED,
}

export enum UploadStatus {
  ABORTED,
  STOPPED,
  PENDING,
  COMPLETED,
}

export type UploaderSourceType = string | Blob | File;

export interface IUploaderMutationData {
  id: string;
  refId?: string;
  refType?: AttachmentReferenceType;
  filename: string;
  transaction?: string;
  contentType: string;
  attachmentKind: AttachmentKind;
}

export interface IMultipartUploaderOptions {
  chunkSize: number;
  threadsQuantity: number;
}

export interface IAWSPart {
  signedUrl: string;
  partNumber: number;
}

export interface IUploadedPart {
  eTag: string;
  partNumber: number;
}

export interface IAWSFileData {
  awsFileId: string;
  awsFileKey: string;
}

export interface IProgressCallbackParams {
  sent: number;
  total: number;
  status: UploadStatus;
  progress: number;
}

export type UploaderOnErrorFunction<U> = (error: Error, uploader: U) => void;

export type UploaderOnCompleteFunction<U> = (uploader: U) => void;

export type UploaderOnProgressFunction<U> = (params: IProgressCallbackParams, uploader: U) => void;

export type UploaderOnStageChangeFunction<U> = (stage: UploadStage, uploader: U) => void;

export type UploaderOnStatusChangeFunction<U> = (status: UploadStatus, uploader: U) => void;

export type UploaderEventMap<U> = {
  error: UploaderOnErrorFunction<U>;
  progress: UploaderOnProgressFunction<U>;
  complete: UploaderOnCompleteFunction<U>;
  stageChange: UploaderOnStageChangeFunction<U>;
  statusChange: UploaderOnStatusChangeFunction<U>;
};

export type UploaderEventType = keyof UploaderEventMap<unknown>;
