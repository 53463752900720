/**
 * That’s default theme of application
 */

import { ITheme } from '../theme.interface';

export const theme: ITheme = {
  media: {
    up: (breakpoint) => `@media (min-width: ${breakpoint})`,
    down: (breakpoint) => `@media (max-width: ${breakpoint})`,
  },
  colors: {
    primary_green: '#1AC932',
    primary_shadow: '#F0F1F2',

    white: '#FFFFFF',
    // Our main color for texts
    black: '#17191C',

    // Used as the background color together with red-400
    red100: '#FFEDED',
    // Used to background of task cards, when the due date expired
    red200: '#FF9494',
    // Used to labels for task cards, when the due date expired
    red300: '#F06868',
    // Used to highlight important buttons, icons, links, texts (for example: Delete)
    red400: '#F94747',

    // Used to highlight active options in dropdowns & other parts of the platform
    blue100: '#E6F5FF',
    // Background for messages in the chat
    blue200: '#D1ECFF',
    //Used for disabled blue buttons
    blue250: '#6CC0FC',
    // Using fot text on chat bubbles
    blue280: '#81C2EE',
    // Used in buttons, links and other active elements
    blue300: '#0094FF',
    // Used as a hover-color of blue-200
    blue400: '#0077CC',
    // Used to highlight active projects in the left column & for borders and lines
    blue500: '#2D4071',
    // Used for buttons before mouse-over in the left column with projects
    blue600: '#263765',
    // Used as the background color in the left column that lists projects
    blue700: '#0F1D40',

    // Used as the background color for “Tools” column, Waiting list. Hover for rows in the table view
    gray100: '#F5F8FA',
    // Used for dropdown hovers, buttons, tabs
    gray200: '#F2F6F8',
    // Used for completed time blocks & tasks
    gray300: '#F0F4F6',
    // Used for borders, lines & hover for elements with default grey background
    gray400: '#EBF1F5',
    // Used as the background for counters. Can be used with grey-100 as a secondary bg
    gray500: '#E0E6EB',
    // Used for labels in completed tasks & time blocks
    gray600: '#C8CED2',
    // Used for icons in empty fields & inactive elements
    gray700: '#B4BAC4',
    // Main grey: Used for icons & links & texts
    gray800: '#8C939F',
    // Used for texts
    gray900: '#77818B',
  },
  font: {
    size: {
      xxs: '12px',
      xs: '13px',
      sm: '14px',
      md: '16px',
      lg: '18px',
      xl: '22px',
      xxl: '28px',
    },
  },
  shadow: {
    primary: '0px 3px 8px 0px rgba(13, 19, 35, 0.15)',
    secondary: '-4px 0px 50px 0px rgba(140, 147, 159, 0.25)',
    tertiary: '0px 1px 1px 0px rgba(0, 0, 0, 0.06)',
    quaternary: '0px 3px 3px -1px rgba(13, 19, 35, 0.15)',
  },
  border: {
    radius: {
      xs: '4px',
      sm: '6px',
      md: '8px',
      lg: '50px',
      xl: '80px',
      xxl: '100px',
    },
  },
  dropdown: {
    minWidth: {
      xs: '120px',
      sm: '210px',
      md: '250px',
      lg: '300px',
    },
  },
  screenSize: {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1366px',
    xxl: '1600px',
  },
  card: {
    green: { background: '#CEF8C9', text: '#435E52', labels: '#8CDD82' },
    swamp: { background: '#ABE9CE', text: '#435E52', labels: '#78CDA8' },
    sea: { background: '#A4D7DB', text: '#247177', labels: '#79B9BD' },
    blue: { background: '#B7E1FE', text: '#2A73A5', labels: '#81C2EE' },
    sky: { background: '#BFF2FC', text: '#455C61', labels: '#6FD0E3' },
    purple: { background: '#D8DCFF', text: '#404784', labels: '#A4ACF3' },
    rose: { background: '#F2BAE1', text: '#8E4778', labels: '#DE8DC5' },
    orange: { background: '#FEC6B7', text: '#662E1E', labels: '#EE9981' },
    brown: { background: '#FFDFBA', text: '#662E1E', labels: '#EDB87B' },
    grass: { background: '#D9E6A2', text: '#4A4D3A', labels: '#B4C569' },
    // state colors
    expired: { background: '#FF9494', text: '#803A3A', labels: '#F06868' },
    completed: { background: '#F0F4F6', text: '#77818B', labels: '#C8CED2' },
  },
};
