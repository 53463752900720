import * as React from 'react';
function SvgEditorTextBold(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.801 4.736c0-.772.674-1.404 1.498-1.404h3.834c1.962 0 3.557 1.495 3.557 3.333 0 1.839-1.595 3.334-3.557 3.334H4.801V4.736zM4.801 10h7.408c2.043 0 3.703 1.495 3.703 3.333 0 1.839-1.66 3.334-3.703 3.334H6.36c-.858 0-1.56-.632-1.56-1.404V10v0z"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgEditorTextBold;
