import { Logger } from 'core/logger/logger';

export const getFileSize = (source: File | Blob | string) => {
  if (source instanceof File || source instanceof Blob) {
    return source.size;
  }

  if (typeof source === 'string') {
    const [metadata, base64Data] = source.split(',');

    const isBase64 = metadata.indexOf('base64') !== -1;

    if (isBase64) {
      const binaryString = atob(base64Data);

      return binaryString.length;
    } else {
      return decodeURIComponent(base64Data).length;
    }
  }

  Logger.subject('GET_FILE_SIZE').error('Unsupported source type. Input should be a File, Blob, or Data URL string');

  return 0;
};
