import * as React from 'react';
function SvgEditorCheckList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.198 3.11H6.754a3 3 0 00-3 3v7.888a3 3 0 003 3h7.889a3 3 0 003-3V9.22"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
      />
      <path
        d="M7.643 8.663l2.07 2.07a1 1 0 001.415 0l6.515-6.514"
        stroke="#8C939F"
        strokeWidth={1.2}
        strokeLinecap="round"
      />
    </svg>
  );
}
export default SvgEditorCheckList;
