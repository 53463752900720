import * as React from 'react';
function SvgNoAvatar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={13} cy={13} r={13} fill="#F2F6F8" />
      <mask
        id="no_avatar_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={26}
        height={26}
      >
        <circle cx={13} cy={13} r={13} fill="#F2F6F8" />
      </mask>
      <g mask="url(#no_avatar_svg__a)">
        <ellipse cx={13} cy={22.88} rx={9.88} ry={5.72} fill="#B4BAC4" />
      </g>
      <circle cx={13} cy={11.05} r={4.68} fill="#B4BAC4" />
    </svg>
  );
}
export default SvgNoAvatar;
